import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React, { useCallback, useEffect, useState } from 'react'
import EcommerceWidgetSummary from './components/ecommerce-widget-summary'
import OverviewService from 'src/services/overview-service'
import { fDate } from 'src/utils/format-time'

const ProductsSoldSummary = ({ startDate, endDate }) => {
  const [totalProductsSold, setTotalProductsSold] = useState(0);
  const [totalProductsSoldInMonth, setTotalProductsSoldInMonth] = useState([]);
  const [percentChange, setPercentChange] = useState(0);

  const ProductsSoldSummary = useCallback(
    async () => {
      try {
        const res = await OverviewService.getTotalProductsSold({ startDate: fDate(startDate), endDate: fDate(endDate) });
        setTotalProductsSold(res?.data?.total_products_sold || 0);
        setTotalProductsSoldInMonth(res?.data?.total_products_sold_in_month?.map(sale => sale.total_products_sold) || []);
        setPercentChange(res?.data?.percent_change || 0);
      } catch (error) {
        console.error(error);
      }
    },
    [startDate, endDate],
  );

  useEffect(() => {
    ProductsSoldSummary();
  }, [ProductsSoldSummary])

  return (
    <>
      <Grid xs={12} md={4}>
        <EcommerceWidgetSummary
          title="Products Sold"
          percent={percentChange}
          total={totalProductsSold}
          chart={{
            series: totalProductsSoldInMonth,
          }}
        />
      </Grid>
    </>
  )
}

export default ProductsSoldSummary
