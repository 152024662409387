import { Navigate } from 'react-router-dom';
import { ICONS } from 'src/components/icons';
import NotFoundPage from 'src/pages/404';
import Page500 from 'src/pages/500';
import { Login } from 'src/pages/auth';
import Home from 'src/pages/client/home';
import { Shop } from 'src/pages/client/products';
import { BrandCreate, BrandEdit, BrandList } from 'src/pages/dashboard/brands';
import { CategoryCreate, CategoryEdit, CategoryList } from 'src/pages/dashboard/categories';
import { Overview } from 'src/pages/dashboard/overview';
import { ProductCreate, ProductEdit, ProductList } from 'src/pages/dashboard/products';
import paths from './paths';
import { AddStockIn, StockInList } from 'src/pages/dashboard/stock-in';
import { OrderCreate, OrderEdit, OrderList } from 'src/pages/dashboard/orders';
import OrderView from 'src/pages/dashboard/orders/view';
import { UserList } from 'src/pages/dashboard/users';

const dashboard = [
  {
    name: 'Overview',
    action: paths.dashboard.root,
    icon: ICONS.analytics,
    element: <Overview />,
  },
  {
    name: 'Brands',
    action: paths.dashboard.brands.root,
    icon: ICONS.label,
    children: [
      {
        name: 'List',
        action: paths.dashboard.brands.root,
        element: <BrandList />,
      },
      {
        name: 'Create',
        action: paths.dashboard.brands.create,
        element: <BrandCreate />,
      },
      {
        name: 'Edit',
        action: paths.dashboard.brands.edit,
        element: <BrandEdit />,
        isRoute: true,
      },
    ],
  },
  {
    name: 'Categories',
    action: paths.dashboard.categories.root,
    icon: ICONS.menuItem,
    children: [
      {
        name: 'List',
        action: paths.dashboard.categories.root,
        element: <CategoryList />,
      },
      {
        name: 'Create',
        action: paths.dashboard.categories.create,
        element: <CategoryCreate />,
      },
      {
        name: 'Edit',
        action: paths.dashboard.categories.edit,
        element: <CategoryEdit />,
        isRoute: true,
      },
    ],
  },
  {
    name: 'Products',
    action: paths.dashboard.products.root,
    icon: ICONS.ecommerce,
    children: [
      {
        name: 'List',
        action: paths.dashboard.products.root,
        element: <ProductList />,
      },
      {
        name: 'Create',
        action: paths.dashboard.products.create,
        element: <ProductCreate />,
      },
      {
        name: 'Edit',
        action: paths.dashboard.products.edit,
        element: <ProductEdit />,
        isRoute: true,
      },
    ],
  },
  {
    name: 'Stock In',
    action: paths.dashboard.serialNumbers.root,
    icon: ICONS.file,
    children: [
      {
        name: 'List',
        action: paths.dashboard.serialNumbers.root,
        element: <StockInList />,
      },
      {
        name: 'Create',
        action: paths.dashboard.serialNumbers.create,
        element: <AddStockIn />,
      },
    ],
  },
  {
    name: 'Orders',
    action: paths.dashboard.orders.root,
    icon: ICONS.invoice,
    children: [
      {
        name: 'List',
        action: paths.dashboard.orders.root,
        element: <OrderList />,
      },
      {
        name: 'Create',
        action: paths.dashboard.orders.create,
        element: <OrderCreate />,
      },
      {
        name: 'View',
        action: paths.dashboard.orders.view,
        element: <OrderView />,
        isRoute: true,
      },
      {
        name: 'Edit',
        action: paths.dashboard.orders.edit,
        element: <OrderEdit />,
        isRoute: true,
      },
    ],
  },
  {
    name: 'Users',
    action: paths.dashboard.users.root,
    icon: ICONS.user,
    children: [
      {
        name: 'List',
        action: paths.dashboard.users.root,
        element: <UserList />,
      },
      {
        name: 'Create',
        action: paths.dashboard.users.create,
        element: <ProductCreate />,
      },
      {
        name: 'Edit',
        action: paths.dashboard.users.edit,
        element: <ProductEdit />,
        isRoute: true,
      },
    ],
  },
];

const auth = [
  {
    name: 'Login',
    action: '/auth/login',
    element: <Login />,
  },
];

const client = [
  {
    name: 'Home',
    action: paths.client.home,
    element: <Home />,
  },
  {
    name: 'Shop',
    action: paths.client.products.shop,
    element: <Shop />,
  },
  {
    action: '/500',
    element: <Page500 />,
    isRoute: true,
  },
  {
    action: '/404',
    element: <NotFoundPage />,
    isRoute: true,
  },
  {
    action: '*',
    element: <Navigate to="/404" replace />,
    isRoute: true,
  },
];

const views = {
  dashboard,
  auth,
  client,
};

export default views;
