import { Box, Container, IconButton, Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Iconify from 'src/components/iconify/iconify';
import RouterLink from 'src/components/router-link';
import { useSettingsContext } from 'src/components/settings';
import { PROJECT_NAME } from 'src/config-global';
import { useParams } from 'src/hooks/routes';
import { paths } from 'src/paths';
import OrderEditForm from 'src/sections/orders/order-edit-form/order-edit-form';
import OrderService from 'src/services/order-service';
import UpdateOrderStatusButton from './components/update-order-status-button';

const OrderEdit = () => {
  const { id } = useParams();

  const [order, setOrder] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getOrderDetail = useCallback(async () => {
    setIsLoading(true);

    try {
      const res = await OrderService.getDetail(id);
      setOrder(res.data);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    getOrderDetail();
  }, [getOrderDetail]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Edit Order | {PROJECT_NAME}</title>
      </Helmet>

      <Container maxWidth={'xl'} >
        <CustomBreadcrumbs
          heading={order ? (
            <Stack spacing={1} direction="row" alignItems="flex-start">
              <IconButton
                component={RouterLink}
                href={`${paths.dashboard.orders.root}${order && `/${order.id}`}`}
              >
                <Iconify icon="eva:arrow-ios-back-fill" />
              </IconButton>
              {`Order #${order.order_code}`}
            </Stack>) : 'Edit Order'}
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'Orders',
              href: paths.dashboard.orders.root,
            },
            { name: 'Edit Order' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
          action={<>
            <UpdateOrderStatusButton order={order} getOrderDetail={getOrderDetail} />
          </>}
        />
        <OrderEditForm order={order} isLoading={isLoading} />
      </Container>
    </>
  );
};

export default OrderEdit;
