/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'src/hooks/routes';
import { paths } from 'src/paths';
import OrderService from 'src/services/order-service';
import * as Yup from 'yup';
import OrderEditFormDetails from './order-edit-form-details';
import OrderEditFormInfo from './order-edit-form-info';

const OrderEditForm = ({ order, isLoading }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const router = useRouter();

  const formik = useFormik({
    initialValues: {
      customer_name: order?.customer_name || '',
      customer_phone: order?.customer_phone || '',
      customer_email: order?.customer_email || '',
      company_name: order?.company_name || '',
      shipping_method: order?.shipping_method || 'delivery',
      shipping_name: order?.shipping_name || '',
      shipping_tracking_number: order?.shipping_tracking_number || '',
      address_line_1: order?.address_line_1 || '',
      address_line_2: order?.address_line_2 || '',
      city: order?.city || '',
      state: order?.state || '',
      country: order?.country || 'Australia',
      postcode: order?.postcode || '',
      note: order?.note || '',

      order_items: order?.order_items || [],
      subtotal_price: order?.subtotal_price || 0,
      shipping_price: order?.shipping_price || 0,
      discount_price: order?.discount_price || 0,
      total_price: order?.total_price || 0,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      customer_name: Yup.string().required('Customer name is required!'),
      customer_phone: Yup.string().required('Customer phone is required!'),
      subtotal_price: Yup.number().required().min(0),
      shipping_price: Yup.number().required().min(0),
      discount_price: Yup.number().required().min(0),
      total_price: Yup.number().required().min(0),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);

      try {
        let data = {
          ...values,
          billing_address_line_1: values.address_line_1,
          billing_address_line_2: values.address_line_2,
          billing_address_city: values.city,
          billing_address_state: values.state,
          billing_address_country: values.country,
          billing_address_postcode: values.postcode,
          shipping_address_line_1: values.address_line_1,
          shipping_address_line_2: values.address_line_2,
          shipping_address_city: values.city,
          shipping_address_state: values.state,
          shipping_address_country: values.country,
          shipping_address_postcode: values.postcode,
        };
        if (order) {
          await OrderService.update(order.id, data);
          enqueueSnackbar('Update Successfully!', { variant: 'success' });
        } else {
          const res = await OrderService.create(data);
          router.push(`${paths.dashboard.orders.root}/${res.data.id}`);
          enqueueSnackbar('Create Successfully!', { variant: 'success' });
        }
      } catch (error) {
        enqueueSnackbar(`Failed to ${!order ? 'create' : 'update'}!`, { variant: 'error' });
      }
      setIsSubmitting(false);
    },
  });

  const handleCalculateSubTotalPrice = useCallback(() => {
    const orderItems = formik.values.order_items;
    const total = orderItems.reduce((total, currentItem) => (total += currentItem.total_price), 0);

    formik.setFieldValue('subtotal_price', total);
  }, [formik.values.order_items]);

  const handleCalculateTotalPrice = useCallback(() => {
    const subTotal = formik.values.subtotal_price;
    const shipping = formik.values.shipping_price;
    const discount = formik.values.discount_price;
    const total = subTotal - (shipping + discount);

    formik.setFieldValue('total_price', total);
  }, [formik.values.subtotal_price, formik.values.shipping_price, formik.values.discount_price]);

  useEffect(() => {
    handleCalculateSubTotalPrice();
  }, [handleCalculateSubTotalPrice]);

  useEffect(() => {
    handleCalculateTotalPrice();
  }, [handleCalculateTotalPrice]);

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid xs={12} md={8}>
            <Stack spacing={2}>
              <OrderEditFormDetails order={order} formik={formik} isLoading={isLoading} />
            </Stack>
          </Grid>
          <Grid xs={12} md={4}>
            <Stack spacing={3}>
              <OrderEditFormInfo order={order} formik={formik} />
              <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  loading={isSubmitting}
                >
                  {!order ? 'Create Order' : 'Save Changes'}
                </LoadingButton>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default OrderEditForm;
