import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  ListItemText,
  Stack,
  Typography, TextField,
} from '@mui/material';
import React from 'react';
import { formatAddress } from 'src/utils/string';

const OrderDetailInfo = ({ order }) => {
  const renderCustomer = (
    <>
      <CardHeader
        title="Customer Info"
      />
      <Stack direction="row" sx={{ p: 3 }}>
        <Avatar alt={order?.customer_name} src={''} sx={{ width: 48, height: 48, mr: 2 }} />

        <Stack spacing={0.5} alignItems="flex-start" sx={{ typography: 'body2' }}>
          <Typography variant="subtitle2">{order?.customer_name}</Typography>

          <Box sx={{ color: 'text.secondary' }}>{order?.customer_email}</Box>

          <Box>
            Contact:
            <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
              {order?.customer_phone}
            </Box>
          </Box>
        </Stack>
      </Stack>
    </>
  );

  const renderDelivery = (
    <>
      <CardHeader
        title="Delivery"
      />
      <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Ship by
          </Box>
          {order?.shipping_name}
        </Stack>
        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Tracking No.
          </Box>
          <Link underline="always" color="inherit">
            {order?.shipping_tracking_number}
          </Link>
        </Stack>
      </Stack>
    </>
  );

  const renderPostage = (
    <>
      <CardHeader
        title="Postage"
      />
      <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Name:
          </Box>
          {order?.order_shipping_address?.name || order?.customer_name}
        </Stack>
        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Address:
          </Box>
          {(!!order?.order_shipping_address &&
            formatAddress(
              order?.order_shipping_address.addressLine1,
              order?.order_shipping_address.addressLine2,
              order?.order_shipping_address.city,
              order?.order_shipping_address.state,
              order?.order_shipping_address.postcode,
              order?.order_shipping_address.country
            )) ||
            '-'}
        </Stack>
        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Contact:
          </Box>
          <ListItemText
            primary={order?.order_shipping_address?.phone || order?.customer_phone}
            secondary={order?.order_shipping_address?.email || order?.customer_email}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            Company:
          </Box>
          {order?.order_shipping_address?.company || '-'}
        </Stack>
      </Stack>
    </>
  );

  const renderNote = (
    <>
      <CardHeader
        title="Note"
      />
      <CardContent sx={{ py: 1.5 }}>
        <TextField
          multiline
          minRows={3}
          value={order?.note || ''}
          fullWidth
          disabled
          variant="outlined"
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "black",
            },
          }}
        // aria-readonly
        />
      </CardContent>
    </>
  )

  return (
    <Card>
      {renderCustomer}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {renderDelivery}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {renderPostage}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {renderNote}
    </Card>
  );
};

export default OrderDetailInfo;
