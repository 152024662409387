import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'src/paths';
import axios from 'src/utils/axios';
import { urlWithQueryString } from 'src/utils/string';

const getList = async (query) => {
  return await axios.get(urlWithQueryString(api.serialNumbers.list, query));
};

const useGetList = (query) => {
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getList = useCallback(
    async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(urlWithQueryString(api.serialNumbers.list, query))
        setSerialNumbers(res.data);
      } catch (error) {
        setError(error)
      }
      setIsLoading(false);
    },
    [query],
  )

  useEffect(() => {
    getList();
  }, [getList])

  const memoizedValue = useMemo(
    () => ({ serialNumbers, serialNumberLoading: isLoading, serialNumberError: error }),
    [serialNumbers, isLoading, error]
  );

  return memoizedValue
}

const getStatusList = (query) => {
  return axios.get(urlWithQueryString(api.serialNumbers.status, query));
};

const getDetail = (id) => {
  return axios.get(api.serialNumbers.detail(id));
};

const create = (data) => {
  return axios.post(api.serialNumbers.create, data);
};

const createMany = (data) => {
  return axios.post(api.serialNumbers.createMany, data);
};

const update = (id, data) => {
  return axios.put(api.serialNumbers.update(id), data);
};

const deleteOne = (id) => {
  return axios.delete(api.serialNumbers.trashOne(id));
};

const deleteMany = (ids) => {
  return axios.post(api.serialNumbers.deleteMany, { serial_number_ids: ids });
};

const SerialNumberService = {
  getList,
  useGetList,
  getStatusList,
  getDetail,
  create,
  createMany,
  update,
  deleteOne,
  deleteMany,
};

export default SerialNumberService;
