import {
  Autocomplete,
  Box,
  Button,
  Chip,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import Iconify from 'src/components/iconify/iconify';
import { useDebounce } from 'src/hooks/use-debounce';
import SerialNumberService from 'src/services/serial-number-service';
import { slice } from 'src/utils/string';
import * as Yup from 'yup';

const OrderEditFormDetailItem = ({
  index,
  item,
  orderItems,
  products,
  onChangeOrderItems,
  onRemoveOrderItem,
}) => {
  const [productOptions, setProductOptions] = useState([]);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      product: products?.find((p) => p.id === item?.product_id) || null,
      serialNumbers: item?.serial_numbers || [],
      quantity: item?.quantity || 0,
      unitPrice: item?.unit_price || 0,
      totalPrice: item?.total_price || 0,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      product: Yup.object().required(),
      quantity: Yup.number().min(0).required(),
    }),
  });

  const getSerialNumberList = useCallback(
    async () => {
      setIsLoading(true);
      try {
        const res = await SerialNumberService.getList({ productId: formik.values.product?.id, status: ['ok'] });
        setSerialNumbers(res?.data?.map(item => item.serial_number));
      } catch (error) {
      }
      setIsLoading(false);

    },
    [formik.values.product],
  )

  const debouncedValues = useDebounce(formik.values, 1000);

  const handleChangeTotalPrice = useCallback(
    () => {
      const quantity = formik.values.quantity;
      const unitPrice = formik.values.unitPrice;
      const totalPrice = quantity * unitPrice;

      formik.setFieldValue('totalPrice', totalPrice);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.quantity, formik.values.unitPrice]
  );

  const handleChangeOrderItem = useCallback(() => {
    let orderItem = { ...item };
    orderItem.product_id = debouncedValues.product?.id;
    orderItem.product_name = debouncedValues.product?.name;
    orderItem.product_pid = debouncedValues.product?.pid;
    orderItem.product_sku = debouncedValues.product?.sku;
    orderItem.serial_numbers = debouncedValues.serialNumbers;
    orderItem.quantity = debouncedValues.quantity;
    orderItem.unit_price = debouncedValues.unitPrice;
    orderItem.total_price = debouncedValues.totalPrice;

    onChangeOrderItems(index, orderItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValues]);

  const handleChangeSerialNumbers = (event, value) => {
    formik.setFieldValue('serialNumbers', value);
    formik.setFieldValue('quantity', value?.length || 0);
  };

  useEffect(() => {
    formik.setFieldValue(
      'unitPrice',
      (formik.values.product?.sale_price === 0
        ? formik.values.product?.regular_price || 0
        : formik.values.product?.sale_price) || 0
    );

    getSerialNumberList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.product]);

  useEffect(() => {
    handleChangeTotalPrice();
  }, [handleChangeTotalPrice]);

  useEffect(() => {
    handleChangeOrderItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValues]);

  const getProductOptions = useCallback(() => {
    setProductOptions(
      products.filter((product) => {
        const currentIndex = orderItems.findIndex((i) => product.id === i.product_id);
        if (currentIndex === index) {
          return true;
        }

        const existingItem = orderItems.find((i) => product.id === i.product_id);
        return !existingItem;
      })
    );
  }, [products, orderItems, index]);

  useEffect(() => {
    getProductOptions();
  }, [getProductOptions]);

  // return (
  //   <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
  //     <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
  //       <Autocomplete
  //         fullWidth
  //         filterSelectedOptions
  //         options={productOptions}
  //         getOptionLabel={(option) => option.pid || ''}
  //         value={formik.values.product}
  //         isOptionEqualToValue={(option, value) => option.id === value?.id}
  //         onChange={(event, value) => formik.setFieldValue('product', value)}
  //         renderInput={(params) => (
  //           <TextField
  //             {...params}
  //             label="Product"
  //             InputLabelProps={{ shrink: true }}
  //             placeholder="Add Product"
  //             error={formik.touched.product && formik.errors.product}
  //             helperText={formik.touched.product && formik.errors.product}
  //           />
  //         )}
  //         renderOption={(props, option, { selected }) => (
  //           <ListItem {...props} key={option.name}>
  //             {/* <Image
  //               alt={option.name}
  //               src={option.images.find((image) => !!image.is_featured)?.url}
  //               sx={{
  //                 width: 64,
  //                 height: 64,
  //                 borderRadius: 2,
  //                 mr: 2,
  //               }}
  //               variant="rounded"
  //               ratio="1/1"
  //             /> */}

  //             <ListItemText
  //               disableTypography
  //               primary={slice(option.name || '', 30)}
  //               secondary={
  //                 <Box component="div" sx={{ typography: 'body2', color: 'text.disabled' }}>
  //                   {option.pid}
  //                 </Box>
  //               }
  //             />
  //           </ListItem>
  //         )}
  //       />

  //       <Autocomplete
  //         multiple
  //         fullWidth
  //         id="serial-numbers"
  //         onChange={handleChangeSerialNumbers}
  //         options={[]}
  //         value={formik.values.serialNumbers}
  //         freeSolo
  //         renderInput={(params) => (
  //           <TextField
  //             fullWidth
  //             {...params}
  //             label="Serial Numbers"
  //             placeholder="Add Serial Number"
  //             InputLabelProps={{ shrink: true }}
  //             error={formik.touched.serialNumbers && formik.errors.serialNumbers}
  //             helperText={formik.touched.serialNumbers && formik.errors.serialNumbers}
  //           />
  //         )}
  //         renderTags={(selected, getTagProps) =>
  //           selected.map((option, index) => (
  //             <Chip
  //               {...getTagProps({ index })}
  //               key={`${option}-${index}`}
  //               label={option}
  //               size="small"
  //               color="primary"
  //             />
  //           ))
  //         }
  //       />
  //       <TextField
  //         error={formik.touched.quantity && formik.errors.quantity}
  //         helperText={formik.touched.quantity && formik.errors.quantity}
  //         label="Quantity"
  //         name="quantity"
  //         onBlur={formik.handleBlur}
  //         onChange={formik.handleChange}
  //         value={formik.values.quantity}
  //         type="number"
  //         InputLabelProps={{ shrink: true }}
  //         sx={{ width: 350 }}
  //       />
  //       <TextField
  //         error={formik.touched.unitPrice && formik.errors.unitPrice}
  //         helperText={formik.touched.unitPrice && formik.errors.unitPrice}
  //         label="Unit Price"
  //         name="unitPrice"
  //         InputLabelProps={{ shrink: true }}
  //         onBlur={formik.handleBlur}
  //         onChange={formik.handleChange}
  //         value={formik.values.unitPrice}
  //         type="number"
  //         sx={{ width: 350 }}
  //       />
  //       {/* <TextField
  //         error={formik.touched.discountPrice && formik.errors.discountPrice}
  //         helperText={formik.touched.discountPrice && formik.errors.discountPrice}
  //         label="Discount"
  //         name="discountPrice"
  //         onBlur={formik.handleBlur}
  //         onChange={formik.handleChange}
  //         value={formik.values.discountPrice}
  //         type="number"
  //         InputLabelProps={{ shrink: true }}
  //         sx={{ width: 600 }}
  //       /> */}
  //       <TextField
  //         error={formik.touched.totalPrice && formik.errors.totalPrice}
  //         helperText={formik.touched.totalPrice && formik.errors.totalPrice}
  //         label="Total"
  //         name="totalPrice"
  //         onBlur={formik.handleBlur}
  //         onChange={formik.handleChange}
  //         value={formik.values.totalPrice}
  //         type="number"
  //         InputLabelProps={{ shrink: true }}
  //         sx={{ width: 350 }}
  //       />
  //     </Stack>
  //   </Stack>
  // );

  return (
    <Grid key={item.id} container spacing={1.5}>

      <Grid sm={12} md={6}>
        <Autocomplete
          fullWidth
          filterSelectedOptions
          options={productOptions}
          getOptionLabel={(option) => option.pid || ''}
          value={formik.values.product}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          onChange={(event, value) => formik.setFieldValue('product', value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Product"
              InputLabelProps={{ shrink: true }}
              placeholder="Add Product"
              error={formik.touched.product && formik.errors.product}
              helperText={formik.touched.product && formik.errors.product}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <ListItem {...props} key={option.name}>
              {/* <Image
                alt={option.name}
                src={option.images.find((image) => !!image.is_featured)?.url}
                sx={{
                  width: 64,
                  height: 64,
                  borderRadius: 2,
                  mr: 2,
                }}
                variant="rounded"
                ratio="1/1"
              /> */}

              <ListItemText
                disableTypography
                primary={slice(option.name || '', 30)}
                secondary={
                  <Box component="div" sx={{ typography: 'body2', color: 'text.disabled' }}>
                    {option.pid}
                  </Box>
                }
              />
            </ListItem>
          )}
        />
      </Grid>
      <Grid sm={12} md={6}>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            error={formik.touched.quantity && formik.errors.quantity}
            helperText={formik.touched.quantity && formik.errors.quantity}
            label="Quantity"
            name="quantity"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.quantity}
            type="number"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            error={formik.touched.unitPrice && formik.errors.unitPrice}
            helperText={formik.touched.unitPrice && formik.errors.unitPrice}
            label="Unit Price"
            name="unitPrice"
            InputLabelProps={{ shrink: true }}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.unitPrice}
            type="number"
          />
          <TextField
            fullWidth
            error={formik.touched.totalPrice && formik.errors.totalPrice}
            helperText={formik.touched.totalPrice && formik.errors.totalPrice}
            label="Total"
            name="totalPrice"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.totalPrice}
            type="number"
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      </Grid>

      <Grid sm={12} md={12}>
        <Autocomplete
          multiple
          id="serial-numbers"
          onChange={handleChangeSerialNumbers}
          options={serialNumbers}
          value={formik.values.serialNumbers}
          freeSolo
          fullWidth
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              label="Serial Numbers"
              placeholder="Add Serial Number"
              InputLabelProps={{ shrink: true }}
              error={formik.touched.serialNumbers && formik.errors.serialNumbers}
              helperText={formik.touched.serialNumbers && formik.errors.serialNumbers}
            />
          )}
          renderTags={(selected, getTagProps) =>
            selected.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={`${option}-${index}`}
                label={option}
                size="small"
                color="primary"
              />
            ))
          }
        />
      </Grid>

      <Grid sm={12} md={12}>
        <Stack sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            size="small"
            color="error"
            startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
            onClick={() => onRemoveOrderItem(index)}
          >
            Remove
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default OrderEditFormDetailItem;
