import { useCallback, useEffect, useState } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
import axios from 'src/utils/axios';

const { api } = require('src/paths');
const { urlWithQueryString } = require('src/utils/string');

const getList = (queryFilters) => {
  return axios.get(urlWithQueryString(api.products.list, queryFilters));
};

const useGetProductList = () => {
  const [products, setProducts] = useState([]);
  const [productErrors, setProductErrors] = useState(null);
  const [productQueryFilters, setProductQueryFilters] = useState({});

  const productLoading = useBoolean();

  const getProductList = useCallback(async () => {
    productLoading.onTrue();

    try {
      const res = await axios.get(urlWithQueryString(api.products.list, productQueryFilters));
      setProducts(res.data);
    } catch (error) {
      setProductErrors(error);
    }

    productLoading.onFalse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productQueryFilters]);

  useEffect(() => {
    getProductList();
  }, [getProductList]);

  return { products, productLoading: productLoading.value, productErrors, setProductQueryFilters };
};

const getDetail = (id) => {
  return axios.get(api.products.detail(id));
};

const statusCount = () => {
  return axios.get(api.products.statusCount);
};

const create = (data) => {
  return axios.post(api.products.create, data);
};

const update = (id, data) => {
  return axios.put(api.products.update(id), data);
};

const trashOne = (id) => {
  return axios.delete(api.products.trashOne(id));
};

const trashMany = (ids) => {
  return axios.post(api.products.trashMany, { product_ids: ids });
};

const deleteMany = (ids) => {
  return axios.post(api.products.deleteMany, { product_ids: ids });
};

const importProducts = (products) => {
  return axios.post(api.products.importProducts, { products });
};

const ProductService = {
  getList,
  getDetail,
  statusCount,
  create,
  update,
  trashOne,
  trashMany,
  deleteMany,
  useGetProductList,
  importProducts,
};

export default ProductService;
