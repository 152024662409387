import { Box, Container, Stack, Typography } from '@mui/material';
import CartIcon from './cart-icon';
import { useCallback, useEffect, useState } from 'react';
import ProductService from 'src/services/product-service';
import EmptyContent from 'src/components/empty-content/empty-content';
import ProductList from './product-list';
import { useBoolean } from 'src/hooks/use-boolean';
import { useDebounce } from 'src/hooks/use-debounce';
import { isEqual } from 'lodash';
import ProductFilters from './product-filters';
import { useOutletContext } from 'react-router-dom';

const defaultFilters = {
  gender: [],
  colors: [],
  rating: '',
  categories: [],
  priceRange: [0, 200],
};

const ProductShopView = () => {
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const openFilters = useBoolean();

  const [sortBy, setSortBy] = useState('featured');

  const [searchQuery, setSearchQuery] = useState('');

  const debouncedQuery = useDebounce(searchQuery);

  const [filters, setFilters] = useState(defaultFilters);

  const handleFilters = useCallback((name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const canReset = !isEqual(defaultFilters, filters);

  const getProductList = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await ProductService.getList({ page: page, isPublished: true });
      setProducts(response.data);
      setTotal(response.meta.total);
    } catch (error) {}

    setIsLoading(false);
  }, [page]);

  useEffect(() => {
    getProductList();
  }, [getProductList]);

  const renderNotFound = <EmptyContent filled title="No Data" sx={{ py: 10 }} />;

  const handleSearch = useCallback((inputValue) => {
    setSearchQuery(inputValue);
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const renderFilters = (
    <Stack
      spacing={3}
      justifyContent="space-between"
      alignItems={{ xs: 'flex-end', sm: 'center' }}
      direction={{ xs: 'column', sm: 'row' }}
    >
      <Box />
      {/* <ProductSearch
        query={debouncedQuery}
        results={searchResults}
        onSearch={handleSearch}
        loading={searchLoading}
        hrefItem={(id) => paths.product.details(id)}
      /> */}

      <Stack direction="row" spacing={1} flexShrink={0}>
        <ProductFilters
          open={openFilters.value}
          onOpen={openFilters.onTrue}
          onClose={openFilters.onFalse}
          //
          filters={filters}
          onFilters={handleFilters}
          //
          canReset={canReset}
          onResetFilters={handleResetFilters}
          //
          // colorOptions={PRODUCT_COLOR_OPTIONS}
          // ratingOptions={PRODUCT_RATING_OPTIONS}
          // genderOptions={PRODUCT_GENDER_OPTIONS}
        />

        {/* <ProductSort sort={sortBy} onSort={handleSortBy} sortOptions={PRODUCT_SORT_OPTIONS} /> */}
      </Stack>
    </Stack>
  );

  return (
    <>
      <Container
        maxWidth={'lg'}
        sx={{
          mb: 15,
        }}
      >
        <CartIcon totalItems={0} />

        <Typography
          variant="h4"
          sx={{
            my: { xs: 3, md: 5 },
          }}
        >
          Shop
        </Typography>

        <Stack
          spacing={2.5}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          {renderFilters}

          {/* {canReset && renderResults} */}
        </Stack>
        <Stack
          spacing={2.5}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          {products.length > 0 ? (
            <>
              <ProductList
                products={products}
                total={total}
                loading={isLoading}
                onPageChange={(event, page) => setPage(page)}
              />
            </>
          ) : (
            renderNotFound
          )}
        </Stack>
      </Container>
    </>
  );
};

export default ProductShopView;
