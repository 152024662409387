import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import EcommerceMonthlySales from './components/ecommerce-monthly-sales';
import OverviewService from 'src/services/overview-service';
import { fDate } from 'src/utils/format-time';
import { Box, Stack, Typography } from '@mui/material';
import { fNumber, fPercent } from 'src/utils/format-number';
import { DatePicker } from '@mui/x-date-pickers';

const OverviewMonthlySales = () => {
  const [totalSales, setTotalSales] = useState(0);
  const [percentChange, setPercentChange] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // const [totalSalesInMonth, setTotalSalesInMonth] = useState([]);
  const [chartCategories, setChartCategories] = useState([]);
  const [chartData, setChartData] = useState([]);

  const handleFilterStartDate = (newValue) => {
    setStartDate(newValue);
  };

  const handleFilterEndDate = (newValue) => {
    setEndDate(newValue);
  };

  const getTotalSalesSummary = useCallback(async () => {
    try {
      const res = await OverviewService.getMonthlySales({
        startDate: fDate(startDate),
        endDate: fDate(endDate),
      });
      setTotalSales(res?.data?.total_sales || 0);
      setPercentChange(res?.data?.percent_change || 0);

      const chartCategories = res?.data?.chart_data?.categories || [];
      setChartCategories(chartCategories.map(date => fDate(date, 'dd-MMM')));

      const chartData = res?.data?.chart_data?.data.map((data) => ({
        name: data.status,
        data: chartCategories.map(
          (category) => data.data.find((d) => d.date === category)?.total_sales || 0
        ),
      }));

      setChartData(chartData);
    } catch (error) {
      console.error(error);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    getTotalSalesSummary();
  }, [getTotalSalesSummary]);

  return (
    <>
      <Grid xs={12} md={8}>
        <EcommerceMonthlySales
          title="Monthly Sales"
          subheader={
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle1">${fNumber(totalSales)}</Typography>
              <Typography variant="subtitle2" component="div" noWrap>
                ({percentChange > 0 && '+'}
                {fPercent(percentChange)}
                <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
                  {' than last month'}
                </Box>
                )
              </Typography>
            </Stack>
          }
          action={
            <Stack direction="row" spacing={2}>
              <DatePicker
                label="Start date"
                value={startDate}
                onChange={handleFilterStartDate}
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
                sx={{
                  maxWidth: { md: 150 },
                }}
              />
              <DatePicker
                label="End date"
                value={endDate}
                onChange={handleFilterEndDate}
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
                sx={{
                  maxWidth: { md: 150 },
                }}
              />
            </Stack>
          }
          chart={{
            categories: chartCategories,
            series: [
              {
                data: chartData,
              },
            ],
          }}
        />
      </Grid>
    </>
  );
};

export default OverviewMonthlySales;
