import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import Image from 'src/components/image/image';
import Scrollbar from 'src/components/scrollbar/scrollbar';
import { fCurrency } from 'src/utils/format-number';
import Iconify from 'src/components/iconify/iconify';

const OrderDetailItems = ({ order = {}, isLoading }) => {
  const {
    order_items = [],
    shipping_price = 0,
    discount_price = 0,
    subtotal_price = 0,
    total_price = 0,
  } = order;

  const renderTotal = (
    <Stack
      spacing={2}
      alignItems="flex-end"
      sx={{ my: 3, textAlign: 'right', typography: 'body2' }}
    >
      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Subtotal</Box>
        <Box sx={{ width: 160, typography: 'subtitle2' }}>{fCurrency(subtotal_price) || '-'}</Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Shipping</Box>
        <Box
          sx={{
            width: 160,
          }}
        >
          {fCurrency(shipping_price)}
        </Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Discount</Box>
        <Box
          sx={{
            width: 160,
            ...(discount_price && { color: 'error.main' }),
          }}
        >
          {!!discount_price && '-'}
          {fCurrency(discount_price)}
        </Box>
      </Stack>

      <Stack direction="row" sx={{ typography: 'subtitle1' }}>
        <Box>Total</Box>
        <Box sx={{ width: 160 }}>{fCurrency(total_price) || '-'}</Box>
      </Stack>
    </Stack>
  );

  return (
    <>
      <Card>
        <CardHeader
          title="Details"
        />
        <CardContent>
          <Scrollbar>
            {isLoading ? (
              <Stack spacing={3}>
                {[...Array(3)].map((i, index) => (
                  <Stack spacing={3} direction="row" alignItems="center" key={index}>
                    <Skeleton sx={{ borderRadius: 1.5, width: 48, height: 48, flexShrink: 0 }} />
                    <Skeleton sx={{ width: 1, height: 12 }} />
                    <Skeleton sx={{ width: 180, height: 12 }} />
                    <Skeleton sx={{ width: 160, height: 12 }} />
                  </Stack>
                ))}
              </Stack>
            ) : (
              <>
                {order_items.map((item) => (
                  <Stack
                    key={item.id}
                    direction="row"
                    alignItems="center"
                    sx={{
                      py: 3,
                      minWidth: 640,
                      borderBottom: (theme) => `dashed 2px ${theme.palette.background.neutral}`,
                    }}
                  >
                    <Image src={''} variant="rounded" sx={{ width: 48, height: 48, mr: 2 }} />

                    <ListItemText
                      primary={item.product?.pid || item.product_pid}
                      secondary={item.product?.sku || item.product_sku}
                      primaryTypographyProps={{
                        typography: 'body2',
                      }}
                      secondaryTypographyProps={{
                        component: 'span',
                        color: 'text.disabled',
                        mt: 0.5,
                      }}
                    />

                    <Box sx={{ typography: 'body2' }}>x{item.quantity}</Box>
                    <Box sx={{ width: 100, textAlign: 'right', typography: 'body2' }}>
                      {fCurrency(item.unit_price)}
                    </Box>
                    <Box sx={{ width: 110, textAlign: 'right' }}>
                      <ListItemText
                        primary={fCurrency(item.total_price)}
                        secondary={
                          item.discount_price > 0 ? (
                            <Typography
                              variant="body2"
                              color="primary"
                              sx={{ textDecoration: 'line-through' }}
                            >
                              {fCurrency(item.discount_price)}
                            </Typography>
                          ) : (
                            <></>
                          )
                        }
                        primaryTypographyProps={{ typography: 'subtitle2', noWrap: true }}
                        secondaryTypographyProps={{
                          mt: 0.5,
                        }}
                      />
                    </Box>
                  </Stack>
                ))}
              </>
            )}
          </Scrollbar>

          {renderTotal}
        </CardContent>
      </Card>
    </>
  );
};

export default OrderDetailItems;

OrderDetailItems.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
};
