import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
import { api } from 'src/paths';
import axios from 'src/utils/axios';
import { urlWithQueryString } from 'src/utils/string';

const getList = (query) => {
  return axios.get(urlWithQueryString(api.brands.list, query));
};

const useGetBrandList = () => {
  const [brands, setBrands] = useState([]);
  const [brandErrors, setbrandErrors] = useState(null);
  const [brandQueryFilters, setBrandQueryFilters] = useState({ disabledPagination: true });
  const brandLoading = useBoolean(false);
  const refreshBrandList = useBoolean(true);

  const getBrandList = useCallback(async () => {
    if (!refreshBrandList.value) {
      return;
    }
    brandLoading.onTrue();

    try {
      const res = await axios.get(urlWithQueryString(api.brands.list, brandQueryFilters));
      setBrands(res.data);
    } catch (error) {
      setbrandErrors(error);
    }

    refreshBrandList.onFalse();
    brandLoading.onFalse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandQueryFilters, refreshBrandList.value]);

  useEffect(() => {
    getBrandList();
  }, [getBrandList]);

  const memorizedValues = useMemo(
    () => ({
      brands,
      brandLoading: brandLoading.value,
      brandErrors,
      refreshBrandList,
      setBrandQueryFilters,
    }),
    [brands, brandLoading.value, brandErrors, refreshBrandList]
  );

  return memorizedValues;
};

const useGetBrandDetail = (id) => {
  const [brand, setBrand] = useState(null);
  const [brandError, setBrandError] = useState(null);
  const brandLoading = useBoolean();

  const getBrandList = useCallback(async () => {
    brandLoading.onTrue();

    try {
      const res = await axios.get(api.brands.detail(id));
      setBrand(res.data);
    } catch (error) {
      setBrandError(error);
    }

    brandLoading.onFalse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getBrandList();
  }, [getBrandList]);

  return { brand, brandLoading: brandLoading.value, brandError };
};

const getDetail = (id) => {
  return axios.get(api.brands.detail(id));
};

const create = (data) => {
  return axios.post(api.brands.create, data);
};

const update = (id, data) => {
  return axios.put(api.brands.update(id), data);
};

const deleteOne = (id) => {
  return axios.delete(api.brands.deleteOne(id));
};

const deleteMany = (ids) => {
  return axios.post(api.brands.deleteMany, { brand_ids: ids });
};

const BrandService = {
  getList,
  useGetBrandList,
  useGetBrandDetail,
  getDetail,
  create,
  update,
  deleteOne,
  deleteMany,
};

export default BrandService;
