import { Container } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { PROJECT_NAME } from 'src/config-global';
import { paths } from 'src/paths';
import { BrandEditForm } from 'src/sections/brands/brand-edit-form';
import BrandService from 'src/services/brand-service';

const BrandEdit = () => {
  const settings = useSettingsContext();

  const { id } = useParams();

  const [brand, setBrand] = useState();

  const getBrandDetail = useCallback(async () => {
    const res = await BrandService.getDetail(id);
    setBrand(res.data);
  }, [id]);

  useEffect(() => {
    getBrandDetail();
  }, [getBrandDetail]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Edit Brand | {PROJECT_NAME}</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Edit"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            {
              name: 'Brands',
              href: paths.dashboard.brands.root,
            },
            { name: brand?.name },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <BrandEditForm brand={brand} />
      </Container>
    </>
  );
};

export default BrandEdit;
