import { isEqual } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  getComparator,
  useTable,
} from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import SerialNumberService from 'src/services/serial-number-service';
import SerialNumberDataTableToolbar from './serial-number-data-table-toolbar';
import SerialNumberDataTableFiltersResult from './serial-number-data-table-filters-result';
import { Button, IconButton, Table, TableBody, TableContainer, Tooltip } from '@mui/material';
import { DeleteForeverRounded } from '@mui/icons-material';
import Scrollbar from 'src/components/scrollbar/scrollbar';
import SerialNumberDataTableRow from './serial-number-data-table-row';
import ConfirmDialog from 'src/components/confirm-dialog';

const SerialNumberDataTable = ({
  serialNumbers = [],
  getSerialNumberList,
  getProduct,
  total = 0,
  page = 0,
  perPage = 20,
  filters = {},
  defaultFilters = {},
  onChangePage,
  onRowsPerPageChange,
  handleFilters,
  handleResetFilters,
}) => {
  const table = useTable();
  const confirm = useBoolean();
  const [isLoading, setIsLoading] = useState(false);

  const applyFilter = ({ inputData = [], comparator }) => {
    const stabilizedThis = inputData.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);
    return inputData;
  };

  const dataFiltered = applyFilter({
    inputData: serialNumbers,
    comparator: getComparator(table.order, table.orderBy),
  });

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (dataFiltered.length <= 0 && canReset) || dataFiltered.length <= 0;

  const handleDeleteRow = useCallback(
    async (id) => {
      try {
        await SerialNumberService.deleteOne(id);
        enqueueSnackbar('Delete Successfully!', { variant: 'success' });
        table.setSelected([]);
        getSerialNumberList?.();
        getProduct?.();
      } catch (error) {
        enqueueSnackbar('Failed to delete!', { variant: 'error' });
        console.error(error);
      }
    },
    [table, getSerialNumberList, getProduct]
  );

  const handleDeleteRows = useCallback(async () => {
    try {
      await SerialNumberService.deleteMany(table.selected);
      enqueueSnackbar('Delete Successfully!', { variant: 'success' });
      table.setSelected([]);
      getSerialNumberList?.();
      getProduct?.();
    } catch (error) {
      enqueueSnackbar('Failed to delete!', { variant: 'error' });
      console.error(error);
    }
  }, [table, getSerialNumberList, getProduct]);

  const columns = useMemo(
    () => [
      { id: 'serial_number', label: 'Serial Number', width: 220 },
      { id: 'status', label: 'Status', width: 80 },
      { id: 'note', label: 'Note', width: 100 },
      { id: 'modified', label: 'Modified', width: 100 },
      { id: '', width: 88 },
    ],
    []
  );

  return (
    <>
      <SerialNumberDataTableToolbar filters={filters} onFilters={handleFilters} />

      {canReset && (
        <SerialNumberDataTableFiltersResult
          filters={filters}
          onFilters={handleFilters}
          //
          onResetFilters={handleResetFilters}
          //
          results={total}
          sx={{ p: 2.5, pt: 0 }}
        />
      )}

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <TableSelectedAction
          numSelected={table.selected.length}
          rowCount={serialNumbers?.length || 0}
          onSelectAllRows={(checked) =>
            table.onSelectAllRows(
              checked,
              serialNumbers.map((row) => row.id)
            )
          }
          action={
            <Tooltip title="Delete">
              <IconButton color="primary" onClick={confirm.onTrue}>
                <DeleteForeverRounded />
              </IconButton>
            </Tooltip>
          }
        />

        <Scrollbar sx={{ maxHeight: '600px' }}>
          <Table size={table.dense ? 'small' : 'medium'}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={columns}
              rowCount={serialNumbers?.length || 0}
              numSelected={table.selected.length}
              onSort={table.onSort}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  serialNumbers.map((row) => row.id)
                )
              }
            />
            <TableBody>
              {isLoading
                ? [...Array(table.rowsPerPage)].map((i, index) => <TableSkeleton key={index} />)
                : dataFiltered.map((row) => (
                    <SerialNumberDataTableRow
                      key={`${row.slug}-${row.id}`}
                      row={row}
                      selected={table.selected.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      getSerialNumberList={getSerialNumberList}
                      getProduct={getProduct}
                    />
                  ))}

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={total}
        page={page}
        rowsPerPage={perPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onRowsPerPageChange}
      />

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
};

export default SerialNumberDataTable;
