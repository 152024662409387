import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { MotionViewport, varFade } from 'src/components/animate';
import { m } from 'framer-motion';
import Carousel, { CarouselDots, useCarousel } from 'src/components/carousel';
import Image from 'src/components/image';
import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';

const HomeHero2 = () => {
  const { productContext } = useOutletContext();
  const { products, setProductQueryFilters } = productContext;

  const carousel = useCarousel({
    speed: 800,
    autoplay: true,
    ...CarouselDots({
      sx: {
        right: 20,
        bottom: 20,
        position: 'absolute',
        color: 'primary.light',
      },
    }),
  });

  useEffect(() => {
    setProductQueryFilters({ perPage: 5 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container
        component={MotionViewport}
        sx={{
          py: { xs: 10, md: 15 },
        }}
      >
        <Stack alignItems="center" spacing={3}>
          <m.div variants={varFade().inUp}>
            <Card>
              <Carousel {...carousel.carouselSettings}>
                {products.map((product) => (
                  <CarouselItem key={product.id} item={product} />
                ))}
              </Carousel>
            </Card>
          </m.div>
        </Stack>
      </Container>
    </>
  );
};

export default HomeHero2;

function CarouselItem({ item }) {
  const theme = useTheme();

  const { images, name, pid } = item;

  const renderImg = (
    <Image
      alt={name}
      src={images[0]?.url || ''}
      overlay={`linear-gradient(to bottom, ${alpha(theme.palette.grey[900], 0)} 0%, ${
        theme.palette.grey[900]
      } 75%)`}
      sx={{
        width: 1,
        height: { xs: 280, xl: 320 },
      }}
    />
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <CardContent
        sx={{
          left: 0,
          width: 1,
          bottom: 0,
          zIndex: 9,
          textAlign: 'left',
          position: 'absolute',
          color: 'common.white',
        }}
      >
        <Typography variant="overline" sx={{ opacity: 0.48 }}>
          New
        </Typography>

        <Typography noWrap variant="h5" sx={{ mt: 1, mb: 3 }}>
          {name}
        </Typography>

        <Button color="primary" variant="contained">
          Buy Now
        </Button>
      </CardContent>

      {renderImg}
    </Box>
  );
}

CarouselItem.propTypes = {
  item: PropTypes.object,
};
