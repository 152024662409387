import {
  Button,
  Card,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
  Tooltip,
  alpha,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Iconify from 'src/components/iconify/iconify';
import Scrollbar from 'src/components/scrollbar/scrollbar';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  getComparator,
  useTable,
} from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import { useDebounce } from 'src/hooks/use-debounce';
import ProductService from 'src/services/product-service';
import ProductDataTableRow from './product-data-table-row';
import ProductTableToolbar from './product-data-table-toolbar';
import { isEqual } from 'lodash';
import ProductDataTableFiltersResult from './product-data-table-filter-results';
import Label from 'src/components/label/label';
import ConfirmDialog from 'src/components/confirm-dialog';
import ProductDataTableImportExcel from './product-data-table-import-excel';
import CategoryService from 'src/services/category-service';
import { useOutletContext } from 'react-router-dom';

const defaultFilters = {
  searchTerm: '',
  status: 'all',
  stock: [],
  publish: [],
  categories: [],
};

const STATUS_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'trashed', label: 'Trashed' },
];

export const PRODUCT_STOCK_OPTIONS = [
  { value: 'in_stock', label: 'In stock' },
  // { value: 'low stock', label: 'Low stock' },
  { value: 'out_of_stock', label: 'Out of stock' },
];

const PUBLISH_OPTIONS = [
  { value: 'published', label: 'Published' },
  { value: 'draft', label: 'Draft' },
];

const defaultStatusCount = {
  total: 0,
  trashed_count: 0,
};

const ProductDataTable = () => {
  const table = useTable();
  const confirm = useBoolean();
  const importProduct = useBoolean(false);
  const [filters, setFilters] = useState(defaultFilters);
  const { enqueueSnackbar } = useSnackbar();

  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [statusCount, setStatusCount] = useState(defaultStatusCount);

  const { categories } = useOutletContext();

  const debouncedFilters = useDebounce(filters);

  const getProductList = useCallback(async () => {
    setIsLoading(true);
    try {
      let params = {
        page: page + 1,
        perPage,
        search: debouncedFilters.searchTerm,
        withCategories: true,
      };
      params.status = [];

      if (debouncedFilters.publish.length > 0) {
        params.status.push(debouncedFilters.publish);
      }

      if (debouncedFilters.stock.length > 0) {
        params.status.push(debouncedFilters.stock);
      }

      if (debouncedFilters.status === 'trashed') {
        params.isTrashed = true;
      }

      const response = await ProductService.getList(params);
      setProducts(response.data);
      setTotal(response.meta.total);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }, [page, perPage, debouncedFilters]);

  const getStatusCount = useCallback(async () => {
    try {
      const response = await ProductService.statusCount();
      setStatusCount(response.data);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const applyFilter = ({ inputData = [], comparator, filters }) => {
    const stabilizedThis = inputData.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);
    return inputData;
  };

  const dataFiltered = applyFilter({
    inputData: products,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const columns = useMemo(
    () => [
      { id: 'pid', label: 'Product', width: 200 },
      { id: 'sku', label: 'SKU', width: 200 },
      { id: 'quantity', label: 'Stock', width: 120 },
      // { id: 'price', label: 'Price', width: 100 },
      { id: 'condition', label: 'Condition', width: 100 },
      { id: 'updated_at', label: 'Modified', width: 100 },
      { id: '', width: 88 },
    ],
    []
  );

  const handleDeleteRow = useCallback(
    async (id) => {
      try {
        if (debouncedFilters.status === 'trashed') {
          await ProductService.deleteMany([id]);
        } else {
          await ProductService.trashOne(id);
        }
        enqueueSnackbar('Delete Successfully!', { variant: 'success' });
        getProductList();
        table.setSelected([]);
      } catch (error) {
        enqueueSnackbar('Failed to delete!', { variant: 'error' });
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, debouncedFilters.status]
  );

  const handleDeleteRows = useCallback(async () => {
    try {
      if (debouncedFilters.status === 'trashed') {
        await ProductService.deleteMany(table.selected);
      } else {
        await ProductService.trashMany(table.selected);
      }
      enqueueSnackbar('Delete Successfully!', { variant: 'success' });

      getProductList();
      getStatusCount();
      table.setSelected([]);
    } catch (error) {
      enqueueSnackbar('Failed to delete!', { variant: 'error' });
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, debouncedFilters.status]);

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const canReset = !isEqual(defaultFilters, filters);

  useEffect(() => {
    getProductList();
  }, [getProductList]);

  useEffect(() => {
    getStatusCount();
  }, [getStatusCount]);

  return (
    <>
      <Card>
        <Tabs
          value={filters.status}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab
              key={tab.value}
              iconPosition="end"
              value={tab.value}
              label={tab.label}
              icon={
                <Label
                  variant={
                    ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                  }
                  color={
                    (tab.value === 'published' && 'success') ||
                    (tab.value === 'draft' && 'warning') ||
                    (tab.value === 'trashed' && 'error') ||
                    'default'
                  }
                >
                  {tab.value === 'all' && statusCount.total}
                  {tab.value === 'trashed' && statusCount.trashed_count}
                </Label>
              }
            />
          ))}
        </Tabs>
        <ProductTableToolbar
          filters={filters}
          onFilters={handleFilters}
          //
          stockOptions={PRODUCT_STOCK_OPTIONS}
          publishOptions={PUBLISH_OPTIONS}
          categoryOptions={categories}
        />

        {canReset && (
          <ProductDataTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={dataFiltered.length}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={products.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                products.map((row) => row.id)
              )
            }
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />

          <Scrollbar sx={{ maxHeight: { lg: '600px', xl: '800px' } }}>
            <Table size={table.dense ? 'small' : 'medium'} stickyHeader>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={columns}
                rowCount={products.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    products.map((row) => row.id)
                  )
                }
              />
              <TableBody>
                {isLoading ? (
                  [...Array(table.rowsPerPage)].map((i, index) => <TableSkeleton key={index} />)
                ) : (
                  <>
                    {dataFiltered.map((row) => (
                      <ProductDataTableRow
                        key={`${row.slug}-${row.id}`}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        getProductList={getProductList}
                      />
                    ))}
                    {total <= 0 && <TableNoData notFound={total <= 0} />}
                  </>
                )}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={total}
          page={page}
          rowsPerPage={perPage}
          onPageChange={(event, page) => setPage(page)}
          onRowsPerPageChange={(event) => setPerPage(event.target.value)}
        />
      </Card>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
      <ProductDataTableImportExcel
        open={importProduct.value}
        onClose={() => {
          importProduct.onFalse();
        }}
      />
    </>
  );
};

export default ProductDataTable;
