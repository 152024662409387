import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';

import { DeleteForeverRounded, KeyboardReturnRounded } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
  Tooltip,
  alpha,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import ConfirmDialog from 'src/components/confirm-dialog';
import Label from 'src/components/label/label';
import Scrollbar from 'src/components/scrollbar';
import { useDebounce } from 'src/hooks/use-debounce';
import CategoryService from 'src/services/category-service';
import CategoryDataTableFiltersResult from './category-data-table-filters-result';
import CategoryDataTableRow from './category-data-table-row';
import CategoryDataTableToolbar from './category-data-table-toolbar';

const defaultFilters = {
  name: '',
  status: 'all',
};

const STATUS_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'published', label: 'Published' },
  { value: 'draft', label: 'Draft' },
  { value: 'trashed', label: 'Trashed' },
];

const CategoryDataTable = () => {
  const table = useTable();
  const confirm = useBoolean();
  const confirmRestore = useBoolean();

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filters, setFilters] = useState(defaultFilters);
  const [selectAllData, setSelectAllData] = useState([]);

  const debouncedFilters = useDebounce(filters);

  const { categories, categoriesTotal, refreshCategoryList, setCategoryQueryFilters } =
    CategoryService.useGetCategoryList();

  const { categoriesStatusCount, refreshCategoryStatusCount } =
    CategoryService.useGetCategoryStatusCount();

  const canReset = !isEqual(defaultFilters, filters);

  const handleFilters = useCallback((name, value) => {
    setPage(0);
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleDeleteRow = useCallback(
    async (id) => {
      try {
        if (debouncedFilters.status === 'trashed') {
          await CategoryService.deleteMany([id]);
        } else {
          await CategoryService.trashOne(id);
        }
        enqueueSnackbar('Delete Successfully!', { variant: 'success' });
        refreshCategoryList?.onTrue();
        refreshCategoryStatusCount?.onTrue();
        table.setSelected([]);
      } catch (error) {
        enqueueSnackbar('Failed to delete!', { variant: 'error' });
        console.error(error);
      }
    },
    [table, refreshCategoryList, refreshCategoryStatusCount, debouncedFilters.status]
  );

  const handleDeleteRows = useCallback(async () => {
    try {
      if (debouncedFilters.status === 'trashed') {
        await CategoryService.deleteMany(table.selected);
      } else {
        await CategoryService.trashMany(table.selected);
      }
      enqueueSnackbar('Delete Successfully!', { variant: 'success' });
      refreshCategoryList?.onTrue();
      refreshCategoryStatusCount?.onTrue();
      table.setSelected([]);
    } catch (error) {
      enqueueSnackbar('Failed to delete!', { variant: 'error' });
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, refreshCategoryStatusCount, debouncedFilters.status]);

  const handleRestoreRow = useCallback(
    async (id) => {
      try {
        await CategoryService.restoreMany([id]);
        enqueueSnackbar('Restore Successfully!', { variant: 'success' });
        refreshCategoryList?.onTrue();
        refreshCategoryStatusCount?.onTrue();
        table.setSelected([]);
      } catch (error) {
        enqueueSnackbar('Failed to restore!', { variant: 'error' });
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table]
  );

  const handleRestoreRows = useCallback(
    async () => {
      try {
        await CategoryService.restoreMany(table.selected);
        enqueueSnackbar('Restore Successfully!', { variant: 'success' });
        refreshCategoryList?.onTrue();
        refreshCategoryStatusCount?.onTrue();
        table.setSelected([]);
      } catch (error) {
        enqueueSnackbar('Failed to restore!', { variant: 'error' });
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table]
  );

  const columns = useMemo(
    () => [
      { id: 'expand', width: 20 },
      { id: 'name', label: 'Name' },
      { id: 'publish', label: 'Publish' },
      { id: 'modified', label: 'Modified' },
      { id: '', width: 88 },
    ],
    []
  );

  useEffect(() => {
    if (setCategoryQueryFilters) {
      let params = { page: page + 1, perPage, search: debouncedFilters.name };

      switch (debouncedFilters.status) {
        case 'published':
          params.isPublished = true;
          params.onlyParent = true;
          break;

        case 'draft':
          params.isPublished = false;
          params.onlyParent = true;
          break;

        case 'trashed':
          params.isTrashed = true;
          break;

        default:
          params.onlyParent = true;
          break;
      }
      setCategoryQueryFilters(params);
      refreshCategoryList?.onTrue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters, page, perPage]);

  useEffect(() => {
    table.onSelectAllRows(false, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters.status]);

  useEffect(() => {
    let data = [];
    categories.forEach((category) => {
      data.push(category.id);
      category.children?.forEach((subcategory) => {
        data.push(subcategory.id);
      });
    });

    setSelectAllData(data);
  }, [categories]);

  return (
    <>
      {categoriesStatusCount && (
        <Tabs
          value={filters.status}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab
              key={tab.value}
              iconPosition="end"
              value={tab.value}
              label={tab.label}
              icon={
                <Label
                  variant={
                    ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                  }
                  color={
                    (tab.value === 'published' && 'success') ||
                    (tab.value === 'draft' && 'warning') ||
                    (tab.value === 'trashed' && 'error') ||
                    'default'
                  }
                >
                  {tab.value === 'all' && categoriesStatusCount.total}
                  {tab.value === 'published' && categoriesStatusCount.published_count}
                  {tab.value === 'draft' && categoriesStatusCount.draft_count}
                  {tab.value === 'trashed' && categoriesStatusCount.trashed_count}
                </Label>
              }
            />
          ))}
        </Tabs>
      )}

      <CategoryDataTableToolbar filters={filters} onFilters={handleFilters} />

      {canReset && (
        <CategoryDataTableFiltersResult
          filters={filters}
          onFilters={handleFilters}
          //
          onResetFilters={handleResetFilters}
          //
          results={categories?.length || 0}
          sx={{ p: 2.5, pt: 0 }}
        />
      )}

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <TableSelectedAction
          numSelected={table.selected.length}
          rowCount={selectAllData?.length || 0}
          onSelectAllRows={(checked) => table.onSelectAllRows(checked, selectAllData)}
          action={
            <>
              {debouncedFilters.status === 'trashed' && (
                <Tooltip title="Restore">
                  <IconButton color="success" onClick={confirmRestore.onTrue}>
                    <KeyboardReturnRounded />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <DeleteForeverRounded />
                </IconButton>
              </Tooltip>
            </>
          }
        />

        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={columns}
              rowCount={selectAllData?.length || 0}
              numSelected={table.selected.length}
              onSort={table.onSort}
              onSelectAllRows={(checked) => table.onSelectAllRows(checked, selectAllData)}
            />
            <TableBody>
              {categories?.map((row) => (
                <CategoryDataTableRow
                  key={`${row.slug}-${row.id}`}
                  row={row}
                  table={table}
                  selected={table.selected.includes(row.id)}
                  onSelectRow={() => table.onSelectRow(row.id)}
                  onDeleteRow={handleDeleteRow}
                  onRestoreRow={handleRestoreRow}
                  refreshCategoryList={refreshCategoryList}
                />
              ))}
              {categoriesTotal <= 0 && <TableNoData notFound={categoriesTotal <= 0} />}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={categoriesTotal || 0}
        page={page}
        rowsPerPage={perPage}
        onPageChange={(event, page) => setPage(page)}
        onRowsPerPageChange={(event) => setPerPage(event.target.value)}
      />

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />

      <ConfirmDialog
        open={confirmRestore.value}
        onClose={confirmRestore.onFalse}
        title="Restore"
        content={
          <>
            Are you sure want to restore <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handleRestoreRows();
              confirmRestore.onFalse();
            }}
          >
            Restore
          </Button>
        }
      />
    </>
  );
};

CategoryDataTable.propTypes = {
  children: PropTypes.node,
};

export default CategoryDataTable;
