import { useCallback, useContext, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

const ProductFilters = ({
  open,
  onOpen,
  onClose,
  //
  filters,
  onFilters,
  //
  canReset,
  onResetFilters,
}) => {
  const { categories } = useOutletContext();

  const marksLabel = [...Array(21)].map((_, index) => {
    const value = index * 10;

    const firstValue = index === 0 ? `$${value}` : `${value}`;

    return {
      value,
      label: index % 4 ? '' : firstValue,
    };
  });

  const handleFilterCategories = useCallback(
    (newValue) => {
      const checked = filters.categories.includes(newValue)
        ? filters.categories.filter((value) => value !== newValue)
        : [...filters.categories, newValue];
      onFilters('categories', checked);
      console.log(checked);
    },
    [filters.categories, onFilters]
  );

  // useEffect(() => {
  //   console.log(values);
  // }, [values]);

  return <></>;
};

export default ProductFilters;
