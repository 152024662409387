import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
import { api } from 'src/paths';
import axios from 'src/utils/axios';
import { urlWithQueryString } from 'src/utils/string';

const useGetUserList = () => {
  const [users, setUsers] = useState([]);
  const [userTotal, setUserTotal] = useState(0);
  const [userError, setUserError] = useState(null);
  const [userQueryFilters, setUserQueryFilters] = useState({});

  const userLoading = useBoolean(false);
  const refreshUserList = useBoolean(true);

  const getUserList = useCallback(async () => {
    if (!refreshUserList.value) {
      return;
    }

    userLoading.onTrue();

    try {
      const res = await axios.get(urlWithQueryString(api.users.list));
      setUsers(res.data);
      setUserTotal(res.meta?.total || 0);
    } catch (error) {
      setUserError(error);
    }

    userLoading.onFalse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userQueryFilters, refreshUserList.value]);

  useEffect(() => {
    getUserList();
  }, [getUserList]);

  const memorizedValues = useMemo(
    () => ({
      users,
      userTotal,
      userLoading: userLoading.value,
      userError,
      refreshUserList,
      setUserQueryFilters,
    }),
    [users, userTotal, userLoading.value, userError, refreshUserList]
  );

  return memorizedValues;
};

const UserService = {
  useGetUserList,
};

export default UserService;
