import { Box, Stack, Typography, ListItemText } from "@mui/material";
import { alpha, useTheme } from '@mui/material/styles';
import { Outlet } from "react-router-dom";
import { GuestGuard } from "src/auth/guard";
import Logo from "src/components/logo";
import { ASSETS_URL } from "src/config-global";
import { useResponsive } from "src/hooks/use-responsive";
import { bgGradient } from "src/theme/css";

const AuthLayout = () => {
  const theme = useTheme();
  const upMd = useResponsive('up', 'md');

  return <>
    <GuestGuard>
      <Stack
        component="main"
        direction="row"
        sx={{
          minHeight: '100vh',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            m: { xs: 2, md: 5 }
          }}
        >
          <Logo
            sx={{
              width: 30,
              pb: 0
            }}
          />
        </Box>

        {upMd && <Stack
          flexGrow={1}
          alignItems="center"
          justifyContent="center"
          spacing={3}
          sx={{
            ...bgGradient({
              color: alpha(
                theme.palette.background.default,
                theme.palette.mode === 'light' ? 0.6 : 0.94
              ),
              imgUrl: `${ASSETS_URL}/assets/background/background.gif`,
            }),
            // backgroundImage: `${ASSETS_URL}/assets/background/overlay_2.jpg`,
          }}
        >
          <Stack direction="row" spacing={3} alignItems="center">
            <Box
              component="img"
              alt="auth"
              src={`${ASSETS_URL}/logo/logo.png`}
              sx={{ width: 100 }}
            />
            <ListItemText
              primary={
                <Stack direction="row" spacing={2}>
                  <Typography variant="h1" color="secondary">
                    IT
                  </Typography>

                  <Typography variant="h1" color="primary">
                    Hardware Broker
                  </Typography>
                </Stack>
              }
              secondary={
                <Stack direction="row" spacing={2} justifyContent="space-between">
                  <Typography variant="body1">
                    THE TECHNOLOGY SOLUTIONS COMPANY
                  </Typography>
                  <Typography variant="body1">
                    Pty Ltd
                  </Typography>
                </Stack>}
            />
          </Stack>
        </Stack>}

        <Stack
          sx={{
            width: 1,
            mx: 'auto',
            maxWidth: 480,
            px: { xs: 2, md: 8 },
          }}
        >
          <Outlet />
        </Stack>
      </Stack>
    </GuestGuard>
  </>
}

export default AuthLayout
