import { LoadingButton } from '@mui/lab';
import { Button, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import ConfirmDialog from 'src/components/confirm-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { useBoolean } from 'src/hooks/use-boolean';
import OrderService from 'src/services/order-service';

const UpdateOrderStatusButton = ({ order, getOrderDetail }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderStatusOptions, setOrderStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');

  const { orderStatus } = useOutletContext();

  const popover = usePopover();
  const confirm = useBoolean();

  const handleChangeOrderStatus = async () => {
    setIsSubmitting(true);
    try {
      await OrderService.updateStatus(order.id, selectedStatus);
      getOrderDetail?.();
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  }

  useEffect(() => {
    setOrderStatusOptions((preStatus) => {
      if (!order) {
        return orderStatus?.labels || [];
      }

      if (order.status === 'processing') {
        return orderStatus?.labels?.filter(status => !(['draft', 'pending', 'paid'].includes(status.value))) || [];
      }

      if (order.status === 'shipped') {
        return orderStatus?.labels?.filter(status => !(['draft', 'pending', 'paid', 'processing'].includes(status.value))) || [];
      }

      if (order.status === 'completed' || order.status === 'cancelled' || order.status === 'refunded') {
        return orderStatus?.labels?.filter(status => !(['draft', 'pending', 'paid', 'processing', 'shipped'].includes(status.value))) || [];
      }

      return orderStatus?.labels || [];
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderStatus]);

  return (
    <>
      <LoadingButton
        color="inherit"
        variant="outlined"
        endIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
        onClick={popover.onOpen}
        sx={{ textTransform: 'capitalize' }}
        loading={isSubmitting}
      >
        {order?.status_name || 'Draft'}
      </LoadingButton>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="top-right"
        sx={{ width: 140 }}
      >
        {orderStatusOptions
          .map(label => <MenuItem
            key={label.value}
            onClick={() => {
              setSelectedStatus(label.value);
              confirm.onTrue();
              popover.onClose();
            }}
          >
            <Label color={orderStatus?.colors?.find(color => color.value === label.value)?.label}>
              {label.label}
            </Label>
          </MenuItem>)}
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to update status to <Label color={orderStatus?.colors?.find(color => color.value === selectedStatus)?.label}>
              {selectedStatus}
            </Label>?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleChangeOrderStatus();
              confirm.onFalse();
            }}
          >
            Confirm
          </Button>
        }
      />
    </>
  )
}

export default UpdateOrderStatusButton
