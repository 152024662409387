import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { PROJECT_NAME } from 'src/config-global';
import { paths } from 'src/paths';
import ProductEditForm from 'src/sections/products/product-edit-form/product-edit-form';

const ProductCreate = () => {
  const settings = useSettingsContext();
  return (
    <>
      <Helmet>
        <title>Dashboard: Create New Product | {PROJECT_NAME}</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Create a new product"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'Products',
              href: paths.dashboard.products.root,
            },
            { name: 'New product' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <ProductEditForm />
      </Container>
    </>
  );
};

export default ProductCreate;
