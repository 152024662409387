import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import { Main, Sidebar } from './components';
import Header from './components/header';
import SerialNumberService from 'src/services/serial-number-service';
import OrderService from 'src/services/order-service';
import CategoryService from 'src/services/category-service';

const DashboardLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [serialNumberStatus, setSerialNumberStatus] = useState();
  const [orderStatus, setOrderStatus] = useState([]);
  const { categories } = CategoryService.useGetCategoryList({
    disabledPagination: true,
    onlyParent: true,
  });

  const getSerialNumberStatusList = useCallback(async () => {
    try {
      const res = await SerialNumberService.getStatusList();
      const status = res.data;
      setSerialNumberStatus({
        labels: Object.keys(status.labels).map((key) => ({
          value: key,
          label: status.labels[key],
        })),
        colors: Object.keys(status.colors).map((key) => ({
          value: key,
          label: status.colors[key],
        })),
      });
    } catch (error) {}
  }, []);

  useEffect(() => {
    getSerialNumberStatusList();
  }, [getSerialNumberStatusList]);

  const getOrderStatusList = useCallback(async () => {
    try {
      const res = await OrderService.getStatusList();
      const status = res.data;
      setOrderStatus({
        labels: Object.keys(status.labels).map((key) => ({
          value: key,
          label: status.labels[key],
        })),
        colors: Object.keys(status.colors).map((key) => ({
          value: key,
          label: status.colors[key],
        })),
      });
    } catch (error) {}
  }, []);

  useEffect(() => {
    getOrderStatusList();
  }, [getOrderStatusList]);

  return (
    <>
      <AuthGuard>
        <Header onOpenNav={() => setIsOpen(true)} />
        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Sidebar openNav={isOpen} onCloseNav={() => setIsOpen(false)} />
          <Main>
            <Outlet context={{ serialNumberStatus, orderStatus, categories }} />
          </Main>
        </Box>
      </AuthGuard>
    </>
  );
};

export default DashboardLayout;
