import { Card, Divider, Stack, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import EcommerceAnalytic from './components/ecommerce-analytic';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import OverviewService from 'src/services/overview-service';

const OverviewOrderSales = () => {
  const theme = useTheme();
  const [results, setResults] = useState([]);
  const orderStatus = [
    {
      value: 'total',
      label: 'Total',
      color: theme.palette.info.main,
      icon: 'solar:bill-list-bold-duotone',
    },
    {
      value: 'completed',
      label: 'Completed',
      color: theme.palette.success.main,
      icon: 'solar:file-check-bold-duotone',
    },
    {
      value: 'cancelled',
      label: 'Cancelled',
      color: theme.palette.error.main,
      icon: 'solar:bill-cross-bold-duotone',
    },
    {
      value: 'refunded',
      label: 'Refunded',
      color: theme.palette.warning.main,
      icon: 'solar:refresh-circle-bold-duotone',
    },
    {
      value: 'others',
      label: 'Others',
      color: theme.palette.secondary.main,
      icon: 'solar:file-corrupted-bold-duotone',
    },
  ];

  const getTotalOrdersSummary = useCallback(async () => {
    try {
      const res = await OverviewService.getTotalOrdersGroupByStatus();

      setResults(
        orderStatus?.map((status) => {
          if (status.value === 'total') {
            return {
              status: status.label,
              total_orders: res?.data?.total_orders || 0,
              total_sales: res?.data?.total_sales || 0,
              color: status.color,
              icon: status.icon,
            };
          }

          if (status.value === 'others') {
            let totalOrders = 0;
            let totalSales = 0;
            res?.data?.data.forEach((data) => {
              if (data.status !== 'completed') {
                totalOrders += parseInt(data.total_orders);
                totalSales += parseInt(data.total_sales);
              }
            });

            return {
              status: status.label,
              total_orders: totalOrders,
              total_sales: totalSales,
              color: status.color,
              icon: status.icon,
            };
          }

          return {
            status: status.label,
            total_orders:
              res?.data?.data.find((data) => data.status === status.value)?.total_orders || 0,
            total_sales:
              res?.data?.data.find((data) => data.status === status.value)?.total_sales || 0,
            color: status.color,
            icon: status.icon,
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTotalOrdersSummary();
  }, [getTotalOrdersSummary]);

  return (
    <>
      <Grid xs={12} md={12}>
        <Card
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
            sx={{ py: 2 }}
          >
            {results?.map((result) => (
              <EcommerceAnalytic
                key={result.status}
                title={result.status}
                total={result.total_orders}
                percent={100}
                price={result.total_sales}
                icon={result.icon}
                color={result.color}
              />
            ))}
          </Stack>
        </Card>
      </Grid>
    </>
  );
};

export default OverviewOrderSales;
