import { api } from 'src/paths';
import axios from 'src/utils/axios';

const uploadOne = (image) => {
  return axios.post(
    api.images.uploadOne,
    image,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
}

const uploadMany = (images) => {
  return axios.post(
    api.images.uploadMany,
    images,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
}

const getImage = (id) => {
  return axios.get(api.images.detail(id));
}

const ImageService = {
  uploadOne,
  uploadMany,
  getImage,
}

export default ImageService;
