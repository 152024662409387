import { Box, Button, Chip, Paper, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from 'src/components/iconify/iconify';

const ProductDataTableFiltersResult = ({
  filters,
  onFilters,
  //
  onResetFilters,
  //
  results,
  ...other
}) => {
  const handleRemoveStock = (inputValue) => {
    const newValue = filters.stock.filter((item) => item !== inputValue);
    onFilters('stock', newValue);
  };

  const handleRemovePublish = (inputValue) => {
    const newValue = filters.publish.filter((item) => item !== inputValue);
    onFilters('publish', newValue);
  };

  const handleRemoveSearchTerm = () => {
    onFilters('searchTerm', '');
  };

  const handleRemoveStatus = () => {
    onFilters('status', 'all');
  };

  return (
    <>
      <Stack spacing={1.5} {...other}>
        <Box sx={{ typography: 'body2' }}>
          <strong>{results}</strong>
          <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
            results found
          </Box>
        </Box>

        <Stack flexGrow={1} spacing={1} direction="row" flexWrap="wrap" alignItems="center">
          {filters.status && filters.status !== 'all' && (
            <Block label="Status:">
              <Chip size="small" label={filters.status} onDelete={handleRemoveStatus} />
            </Block>
          )}

          {!!filters.stock?.length && (
            <Block label="Stock:">
              {filters.stock.map((item) => (
                <Chip
                  key={item}
                  label={item}
                  size="small"
                  onDelete={() => handleRemoveStock(item)}
                />
              ))}
            </Block>
          )}

          {!!filters.publish?.length && (
            <Block label="Publish:">
              {filters.publish.map((item) => (
                <Chip
                  key={item}
                  label={item}
                  size="small"
                  onDelete={() => handleRemovePublish(item)}
                />
              ))}
            </Block>
          )}

          {filters.searchTerm && (
            <Block label="Search:">
              <Chip
                size="small"
                label={filters.searchTerm}
                onDelete={handleRemoveSearchTerm}
              ></Chip>
            </Block>
          )}

          <Button
            color="error"
            onClick={onResetFilters}
            startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
          >
            Clear
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

ProductDataTableFiltersResult.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
  onResetFilters: PropTypes.func,
  results: PropTypes.number,
};

export default ProductDataTableFiltersResult;

function Block({ label, children, sx, ...other }) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}

Block.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  sx: PropTypes.object,
};
