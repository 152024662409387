import { Add } from '@mui/icons-material';
import { Button, Container } from '@mui/material';
import React, { useCallback, useState } from 'react';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import RouterLink from 'src/components/router-link';
import { paths } from 'src/paths';
import SerialNumberForm from 'src/sections/serial-numbers/serial-number-form/serial-number-form';

const defaultSerialNumber = {
  product: '',
  serialNumbers: [],
  quantity: 0,
  note: '',
};

const AddStockIn = () => {
  const [serialNumbers, setSerialNumbers] = useState([defaultSerialNumber]);

  const onAddRow = useCallback(() => {
    setSerialNumbers((prev) => [...prev, defaultSerialNumber]);
  }, []);

  return (
    <>
      <Container maxWidth="lg">
        <CustomBreadcrumbs
          heading="Stock In"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Stock In', href: paths.dashboard.serialNumbers.root },
            { name: 'Add' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
          action={
            <Button onClick={onAddRow} variant="contained" startIcon={<Add />}>
              Add
            </Button>
          }
        />

        <SerialNumberForm serialNumbers={serialNumbers} />
      </Container>
    </>
  );
};

export default AddStockIn;
