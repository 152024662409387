import { Box, Card, Fab, Link, Stack } from '@mui/material';
import Iconify from 'src/components/iconify/iconify';
import Image from 'src/components/image/image';
import Label from 'src/components/label/label';
import RouterLink from 'src/components/router-link';
import { paths } from 'src/paths';
import { fCurrency } from 'src/utils/format-number';

const ProductItem = ({ product }) => {
  const {
    id,
    name,
    images,
    available,
    regular_price,
    sale_price,
    status,
    status_name,
    status_color,
  } = product;

  const renderLabels = status === 'out_of_stock' && (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ position: 'absolute', zIndex: 9, top: 16, right: 16 }}
    >
      {status_name && (
        <Label variant="filled" color={status_color}>
          {status_name}
        </Label>
      )}
    </Stack>
  );

  const renderImg = (
    <Box sx={{ position: 'relative', p: 1 }}>
      <Fab
        color="warning"
        size="medium"
        className="add-cart-btn"
        // onClick={handleAddCart}
        sx={{
          right: 16,
          bottom: 16,
          zIndex: 9,
          opacity: 0,
          position: 'absolute',
          transition: (theme) =>
            theme.transitions.create('all', {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
        }}
      >
        <Iconify icon="solar:cart-plus-bold" width={24} />
      </Fab>

      <Stack
        sx={{ width: 250, minHeight: 250 }}
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        <Image
          alt={name}
          src={images.find((img) => img.is_featured)?.url}
          sx={{ borderRadius: 1.5 }}
        />
      </Stack>
    </Box>
  );

  const renderContent = (
    <Stack spacing={2.5} sx={{ p: 3, pt: 2 }}>
      <Link
        component={RouterLink}
        href={paths.client.products.detail}
        color="inherit"
        variant="subtitle2"
        noWrap
      >
        {name}
      </Link>

      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Stack direction="row" spacing={0.5} sx={{ typography: 'subtitle1' }}>
          {sale_price < regular_price && (
            <Box component="span" sx={{ color: 'text.disabled', textDecoration: 'line-through' }}>
              {fCurrency(regular_price)}
            </Box>
          )}

          <Box component="span">{fCurrency(sale_price)}</Box>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <>
      <Card
        sx={{
          '&:hover .add-cart-btn': {
            opacity: 1,
          },
        }}
      >
        {renderLabels}

        {renderImg}

        {renderContent}
      </Card>
    </>
  );
};

export default ProductItem;
