import { Add } from '@mui/icons-material';
import { Button, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import RouterLink from 'src/components/router-link';
import { useTable } from 'src/components/table';
import { PROJECT_NAME } from 'src/config-global';
import { useBoolean } from 'src/hooks/use-boolean';
import { useDebounce } from 'src/hooks/use-debounce';
import { paths } from 'src/paths';
import UserService from 'src/services/user-service';

const defaultFilters = {
  search: '',
  roles: [],
  page: 0,
  perPage: 10,
};

const ROLE_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'admin', label: 'Admin' },
  { value: 'user', label: 'User' },
];

const UserDataTable = () => {
  const { users, userLoading, userTotal, refreshUserList, setUserQueryFilters } =
    UserService.useGetUserList();

  const table = useTable();
  const confirm = useBoolean();

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filters, setFilters] = useState(defaultFilters);
  const { enqueueSnackbar } = useSnackbar();

  const debouncedFilters = useDebounce(filters);

  useEffect(() => {
    setUserQueryFilters({ debouncedFilters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters]);

  return <>
  
  </>;
};

export default UserDataTable;
