import { Add } from '@mui/icons-material';
import { Button, Container, Stack } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import RouterLink from 'src/components/router-link';
import { PROJECT_NAME } from 'src/config-global';
import { useBoolean } from 'src/hooks/use-boolean';
import { paths } from 'src/paths';
import { ProductDataTable } from 'src/sections/products/product-data-table';
import ProductDataTableImportExcel from 'src/sections/products/product-data-table/product-data-table-import-excel';

const ProductList = () => {
  const openImportDialog = useBoolean();

  return (
    <>
      <Helmet>
        <title>Dashboard: Product List - {PROJECT_NAME}</title>
      </Helmet>

      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading="List"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Products', href: paths.dashboard.products.root },
            { name: 'List' },
          ]}
          action={
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                startIcon={<Iconify icon="solar:import-bold" />}
                onClick={openImportDialog.onTrue}
              >
                Import
              </Button>
              <Button
                component={RouterLink}
                href={paths.dashboard.products.create}
                variant="contained"
                startIcon={<Add />}
              >
                New Product
              </Button>
            </Stack>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <ProductDataTable />

        <ProductDataTableImportExcel
          open={openImportDialog.value}
          onClose={() => {
            openImportDialog.onFalse();
          }}
        />
      </Container>
    </>
  );
};

export default ProductList;
