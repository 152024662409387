import { Button, Container, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Iconify from 'src/components/iconify/iconify';
import Label from 'src/components/label/label';
import RouterLink from 'src/components/router-link';
import { useSettingsContext } from 'src/components/settings';
import { PROJECT_NAME } from 'src/config-global';
import { useParams, useRouter } from 'src/hooks/routes';
import { paths } from 'src/paths';
import OrderDetailView from 'src/sections/orders/order-view/order-detail-view';
import OrderService from 'src/services/order-service';
import UpdateOrderStatusButton from './components/update-order-status-button';

const OrderView = () => {
  const settings = useSettingsContext();
  const router = useRouter();

  const { id } = useParams();

  const [order, setOrder] = useState();
  const [isLoading, setIsLoading] = useState(false);


  const getOrderDetail = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await OrderService.getDetail(id);
      setOrder(res.data);
      setIsLoading(false);
    } catch (error) {
      if (error?.includes('No query results for model [App\\Models\\Order]')) {
        router.push(paths.dashboard.orders.root);
        enqueueSnackbar('Order not found!', { variant: 'error' });
      }
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getOrderDetail();
  }, [getOrderDetail]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Order Detail | {PROJECT_NAME}</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={
            order ? (
              <Stack spacing={1} direction="row" alignItems="center">
                Order #{order.order_code}
                <Label variant="soft" color={order.status_color}>
                  {order.status_name}
                </Label>
              </Stack>
            ) : (
              'Order Detail'
            )
          }
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'Orders',
              href: paths.dashboard.orders.root,
            },
            { name: 'Order Detail' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
          action={
            order && (
              <Stack direction="row" spacing={2}>
                <UpdateOrderStatusButton order={order} getOrderDetail={getOrderDetail} />
                <Button
                  component={RouterLink}
                  href={`${paths.dashboard.orders.root}/${order.id}/edit`}
                  variant="contained"
                  startIcon={<Iconify icon="solar:pen-bold" />}
                >
                  Edit
                </Button>
              </Stack>
            )
          }
        />

        <OrderDetailView order={order} isLoading={isLoading} />
      </Container>
    </>
  );
};

export default OrderView;
