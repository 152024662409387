import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
// components
import Chart, { useChart } from 'src/components/chart';

// ----------------------------------------------------------------------

export default function EcommerceMonthlySales({ title, subheader, chart, action, ...other }) {
  const { colors, categories, series, options } = chart;

  const chartOptions = useChart({
    colors,
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      categories,
    },
    ...options,
  });

  return (
    <>
      <Card {...other}>
        <CardHeader
          title={title}
          subheader={subheader}
          action={action}
        />

        {series.map((item) => (
          <Box key={item} sx={{ mt: 3, mx: 3 }}>
            <Chart dir="ltr" type="area" series={item.data} options={chartOptions} height={364} />
          </Box>
        ))}
      </Card>
    </>
  );
}

EcommerceMonthlySales.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.any,
  title: PropTypes.string,
};
