import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControlLabel,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import Editor from 'src/components/editor/editor';
import { fileNameByUrl } from 'src/components/file-thumbnail';
import { useRouter } from 'src/hooks/routes';
import { useDebounce } from 'src/hooks/use-debounce';
import { useResponsive } from 'src/hooks/use-responsive';
import { paths } from 'src/paths';
import SerialNumberDataTable from 'src/sections/serial-numbers/serial-number-data-table/serial-number-data-table';
import BrandService from 'src/services/brand-service';
import CategoryService from 'src/services/category-service';
import ProductService from 'src/services/product-service';
import SerialNumberService from 'src/services/serial-number-service';
import * as Yup from 'yup';

const defaultFilters = {
  serialNumber: '',
  status: [],
};

const ProductEditForm = ({ product, getProduct }) => {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const [files, setFiles] = useState([]);
  const [alert, setAlert] = useState();

  const [filters, setFilters] = useState(defaultFilters);
  const debouncedFilters = useDebounce(filters);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [defaultImages, setDefaultImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const { brands } = BrandService.useGetBrandList();

  const getCategoryList = useCallback(async () => {
    try {
      const res = await CategoryService.getList({ disabledPagination: true });
      setCategories(res?.data.map((item) => ({ value: item.id, label: item.name })) || []);
    } catch (error) {}
  }, []);

  useEffect(() => {
    getCategoryList();
  }, [getCategoryList]);

  const getSerialNumbersByProductId = useCallback(async () => {
    if (!product) return;

    const res = await SerialNumberService.getList({
      productId: product.id,
      page: page + 1,
      perPage: perPage,
      search: debouncedFilters.serialNumber,
      status: debouncedFilters.status,
    });
    setSerialNumbers(res.data);
    setTotal(res.meta.total);
  }, [page, perPage, product, debouncedFilters]);

  useEffect(() => {
    getSerialNumbersByProductId();
  }, [getSerialNumbersByProductId]);

  const handleFilters = useCallback((name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const formik = useFormik({
    initialValues: {
      name: product?.name || '',
      pid: product?.pid || '',
      sku: product?.sku || '',
      description: product?.description || '',
      quantity: product?.quantity || 0,
      published: !!product?.published_at || true,
      regularPrice: product?.regular_price || 0,
      salePrice: product?.sale_price || 0,
      categoryIds:
        categories?.filter((category) => product?.category_ids?.includes(category.value)) || [],
      brandIds: brands?.filter((brand) => product?.brand_ids?.includes(brand.value)) || [],
      serialNumbers: [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      // name: Yup.string().max(255).required(),
      pid: Yup.string().max(255).required(),
      regularPrice: Yup.number().min(0).required(),
      salePrice: Yup.number().min(0).required(),
      quantity: Yup.number().min(0),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        const data = {
          name: values.pid,
          sku: values.sku,
          quantity: values.quantity,
          allow_back_orders: true,
          pid: values.pid,
          regular_price: values.regularPrice,
          sale_price: values.salePrice,
          status: values.status,
          description: values.description,
          published: values.published,
          brand_ids: values.brandIds.map((brand) => brand.value),
          category_ids: values.categoryIds.map((category) => category.value),
          serial_numbers: values.serialNumbers,
        };
        if (product) {
          await ProductService.update(product.id, data);
          enqueueSnackbar('Update Successfully!', { variant: 'success' });
          formik.setFieldValue('serialNumbers', []);
          getSerialNumbersByProductId(product.id);
        } else {
          const res = await ProductService.create(data);
          router.push(`${paths.dashboard.products.root}/${res.data.id}`);
          enqueueSnackbar('Create Successfully!', { variant: 'success' });
        }
      } catch (error) {
        setAlert(error);
        enqueueSnackbar(`Failed to ${!product ? 'create' : 'update'}!`, { variant: 'error' });
      }
      setIsSubmitting(false);
    },
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFiles([
        ...files,
        ...acceptedFiles.map((newFile) =>
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          })
        ),
      ]);
    },
    [files]
  );

  const handleRemoveFile = (inputFile) => {
    const filesFiltered = files.filter((fileFiltered) => fileFiltered !== inputFile);
    const imagesFilted = defaultImages.filter(
      (image) => fileNameByUrl(image.url) !== fileNameByUrl(inputFile)
    );
    setDefaultImages(imagesFilted);
    setFiles(filesFiltered);
  };

  const handleRemoveAllFiles = () => {
    setDefaultImages([]);
    setFiles([]);
  };

  const getImages = useCallback(() => {
    setDefaultImages(product?.images || []);
  }, [product?.images]);

  useEffect(() => {
    getImages();
  }, [getImages]);

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Title, short description, image...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        {alert && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {alert}
          </Alert>
        )}

        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <CardContent>
            <Stack spacing={3}>
              <Grid container spacing={2}>
                {/* <Grid sm={12} md={6}>
                  <TextField
                    error={!!(formik.touched.name && formik.errors.name)}
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    label="Name"
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.name}
                  />
                </Grid> */}

                <Grid sm={12} md={12}>
                  <TextField
                    error={!!(formik.touched.pid && formik.errors.pid)}
                    fullWidth
                    helperText={formik.touched.pid && formik.errors.pid}
                    label="PID"
                    name="pid"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.pid}
                  />
                </Grid>
              </Grid>

              <Stack spacing={1.5}>
                <Typography variant="subtitle2">Description</Typography>
                <Editor
                  id="product-description"
                  simple
                  error={!!(formik.touched.description && formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  name="description"
                  onChange={(value) => formik.setFieldValue('description', value)}
                  value={formik.values.description}
                />
              </Stack>

              {/* <Stack spacing={1.5}>
                <Typography variant="subtitle2">Images</Typography>
                <Upload
                  multiple
                  thumbnail
                  files={[...files, ...defaultImages.map((image) => image.url)]}
                  name="images"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  onRemove={handleRemoveFile}
                  onRemoveAll={handleRemoveAllFiles}
                />
              </Stack> */}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </>
  );

  const renderPricing = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Pricing
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Price related inputs
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Properties" />}

          <CardContent>
            <Grid container spacing={2}>
              <Grid sm={12} md={6}>
                <TextField
                  fullWidth
                  error={!!(formik.touched.regularPrice && formik.errors.regularPrice)}
                  helperText={formik.touched.regularPrice && formik.errors.regularPrice}
                  label="Regular Price"
                  name="regularPrice"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.regularPrice}
                  placeholder="0.00"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box component="span" sx={{ color: 'text.disabled' }}>
                          $
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid sm={12} md={6}>
                <TextField
                  fullWidth
                  error={!!(formik.touched.salePrice && formik.errors.salePrice)}
                  helperText={formik.touched.salePrice && formik.errors.salePrice}
                  label="Sale Price"
                  name="salePrice"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.salePrice}
                  placeholder="0.00"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box component="span" sx={{ color: 'text.disabled' }}>
                          $
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );

  const renderProperties = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Properties
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Additional attributes
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Properties" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid sm={12} md={6}>
                <TextField
                  error={!!(formik.touched.sku && formik.errors.sku)}
                  fullWidth
                  helperText={formik.touched.sku && formik.errors.sku}
                  label="SKU"
                  name="sku"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.sku}
                />
              </Grid>

              <Grid sm={12} md={6}>
                <TextField
                  fullWidth
                  error={!!(formik.touched.quantity && formik.errors.quantity)}
                  helperText={formik.touched.quantity && formik.errors.quantity}
                  label="Quantity"
                  name="quantity"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.quantity}
                  type="number"
                />
              </Grid>
            </Grid>

            <Autocomplete
              fullWidth
              multiple
              options={brands.map((brand) => ({ value: brand.id, label: brand.name }))}
              disableCloseOnSelect
              limitTags={5}
              getOptionLabel={(option) => option.label}
              value={formik.values.brandIds}
              isOptionEqualToValue={(option, value) => option.value === value?.value}
              onChange={(event, value) => formik.setFieldValue('brandIds', value)}
              renderInput={(params) => <TextField {...params} label="Brands" />}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.label}>
                  <Checkbox
                    key={option.label}
                    size="small"
                    disableRipple
                    checked={selected}
                    {...props}
                  />
                  {option.label}
                </li>
              )}
              renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={`${option.label}-${index}`}
                    label={option.label}
                    size="small"
                    color="primary"
                  />
                ))
              }
            />

            <Autocomplete
              fullWidth
              multiple
              options={categories}
              disableCloseOnSelect
              limitTags={5}
              getOptionLabel={(option) => option.label}
              value={formik.values.categoryIds}
              isOptionEqualToValue={(option, value) => option.value === value?.value}
              onChange={(event, value) => formik.setFieldValue('categoryIds', value)}
              renderInput={(params) => <TextField {...params} label="Categories" />}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.label}>
                  <Checkbox
                    key={option.label}
                    size="small"
                    disableRipple
                    checked={selected}
                    {...props}
                  />
                  {option.label}
                </li>
              )}
              renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={`${option.label}-${index}`}
                    label={option.label}
                    size="small"
                    color="primary"
                  />
                ))
              }
            />

            <Autocomplete
              multiple
              id="serial-numbers"
              onChange={(event, value) => formik.setFieldValue('serialNumbers', value)}
              options={[]}
              value={formik.values.serialNumbers}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Serial Numbers" placeholder="Add Serial Number" />
              )}
              renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={`${option}-${index}`}
                    label={option}
                    size="small"
                    color="primary"
                  />
                ))
              }
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderSerialNumbers = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Serial Numbers
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Manage Serial Numbers
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Serial Numbers" />}
          <SerialNumberDataTable
            product={product}
            serialNumbers={serialNumbers}
            getSerialNumberList={getSerialNumbersByProductId}
            getProduct={getProduct}
            total={total}
            page={page}
            perPage={perPage}
            filters={filters}
            defaultFilters={defaultFilters}
            handleFilters={handleFilters}
            handleResetFilters={handleResetFilters}
            onChangePage={(event, page) => setPage(page)}
            onRowsPerPageChange={(event) => setPerPage(event.target.value)}
          />
        </Card>
      </Grid>
    </>
  );

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container rowSpacing={3} columnSpacing={1}>
          {renderDetails}

          {renderPricing}

          {renderProperties}

          {product && renderSerialNumbers}

          {mdUp && <Grid md={4} />}
          <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={<Switch checked={formik.values.published} />}
              label="Publish"
              value={formik.values.published}
              onChange={(event) => formik.setFieldValue('published', event.target.checked)}
              sx={{ flexGrow: 1, pl: 3 }}
            />

            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              {!product ? 'Create Product' : 'Save Changes'}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ProductEditForm;
