import { api } from 'src/paths';
import axios from 'src/utils/axios';
import { urlWithQueryString } from 'src/utils/string';

const getTotalSales = (query) => {
  return axios.get(urlWithQueryString(api.overview.totalSales, query));
};

const getTotalProductsSold = (query) => {
  return axios.get(urlWithQueryString(api.overview.totalProductsSold, query));
};

const getTotalOrders = (query) => {
  return axios.get(urlWithQueryString(api.overview.totalOrders, query));
};

const getTotalOrdersGroupByStatus = (query) => {
  return axios.get(urlWithQueryString(api.overview.totalOrdersGroupByStatus, query));
};

const getMonthlySales = (query) => {
  return axios.get(urlWithQueryString(api.overview.monthlySales, query));
};

const getTop10ProductsSold = (query) => {
  return axios.get(urlWithQueryString(api.overview.top10ProductsSold, query));
};

const OverviewService = {
  getTotalSales,
  getTotalProductsSold,
  getTotalOrders,
  getTotalOrdersGroupByStatus,
  getMonthlySales,
  getTop10ProductsSold,
};

export default OverviewService;
