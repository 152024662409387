import { Add } from '@mui/icons-material';
import { Button, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import RouterLink from 'src/components/router-link';
import { PROJECT_NAME } from 'src/config-global';
import { paths } from 'src/paths';
import { BrandDataTable } from 'src/sections/brands/brand-data-table';

const BrandList = () => {
  return (
    <>
      <Helmet>
        <title>Brands - {PROJECT_NAME}</title>
      </Helmet>

      <Container maxWidth="lg">
        <CustomBreadcrumbs
          heading="List"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Brands', href: paths.dashboard.brands.root },
            { name: 'List' },
          ]}
          action={
            <Button
              component={RouterLink}
              href={paths.dashboard.brands.create}
              variant="contained"
              startIcon={<Add />}
            >
              New Brand
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <BrandDataTable />
      </Container>
    </>
  );
};

export default BrandList;
