import {
  DeleteForeverRounded,
  EditRounded,
  KeyboardReturnRounded,
  MoreVertRounded,
} from '@mui/icons-material';
import {
  Button,
  Checkbox,
  IconButton,
  Link,
  ListItemText,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import moment from 'moment';
import ConfirmDialog from 'src/components/confirm-dialog';
import Dropdown from 'src/components/dropdown';
import useDropdown from 'src/components/dropdown/use-dropdown';
import Image from 'src/components/image';
import Label from 'src/components/label/label';
import { useRouter } from 'src/hooks/routes';
import { useBoolean } from 'src/hooks/use-boolean';
import { paths } from 'src/paths';
import { slice } from 'src/utils/string';
import CategoryQuickEditForm from './category-quick-edit-form';
import RouterLink from 'src/components/router-link';
import Iconify from 'src/components/iconify';

const CategoryDataTableRow = ({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  onRestoreRow,
  refreshCategoryList,
  table,
  isChildrenRow
}) => {
  const { id, name, images, published_at, deleted_at, updated_at, children } = row;

  const confirm = useBoolean();
  const confirmRestore = useBoolean();
  const quickEdit = useBoolean();
  const dropdown = useDropdown();
  const router = useRouter();
  const collapse = useBoolean();

  return (
    <>
      <TableRow
        hover
        selected={selected}
        sx={{ backgroundColor: isChildrenRow && 'hsl(0,0%,98.5%)' }}
      >
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell align="center" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {!!children?.length && (
            <IconButton
              color={collapse.value ? 'inherit' : 'default'}
              onClick={collapse.onToggle}
              sx={{
                ...(collapse.value && {
                  bgcolor: 'action.hover',
                }),
              }}
            >
              <Iconify
                icon={collapse.value ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
              />
            </IconButton>
          )}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
          {/*
        <Avatar
          alt={name}
          src={(images.find(image => !!image.is_featured))?.url}

          variant="rounded"
          sx={{ width: 50, height: 50, mr: 2 }}
        />*/}

          <Image
            alt={name}
            src={images?.find((image) => !!image.is_featured)?.url}
            sx={{
              width: 50,
              height: 50,
              borderRadius: 1,
              mr: 2,
            }}
            ratio="1/1"
          />

          <Link
            component={RouterLink}
            href={`${paths.dashboard.categories.root}/${id}`}
            noWrap
            color="inherit"
            variant="subtitle2"
            sx={{ cursor: 'pointer' }}
          >
            {slice(name)}
          </Link>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Label
            variant="soft"
            color={
              !!deleted_at
                ? 'error'
                : (!!published_at && moment().isSameOrAfter(published_at) && 'secondary') ||
                  (!published_at && 'primary') ||
                  'default'
            }
          >
            {!!deleted_at ? 'Trashed' : !published_at ? 'Draft' : 'Published'}
          </Label>
        </TableCell>
        <TableCell>
          <ListItemText
            primary={format(new Date(updated_at), 'dd MMM yyyy')}
            secondary={format(new Date(updated_at), 'p')}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {!deleted_at ? (
            <>
              <Tooltip title="Quick Edit" placement="top" arrow>
                <IconButton
                  color={quickEdit.value ? 'inherit' : 'default'}
                  onClick={quickEdit.onTrue}
                >
                  <EditRounded />
                </IconButton>
              </Tooltip>

              <Tooltip title="More Actions" placement="top" arrow>
                <IconButton color={dropdown.open ? 'inherit' : 'default'} onClick={dropdown.onOpen}>
                  <MoreVertRounded />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Restore" placement="top" arrow>
                <IconButton color="default" onClick={confirmRestore.onTrue}>
                  <KeyboardReturnRounded />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" placement="top" arrow>
                <IconButton color="error" onClick={confirm.onTrue}>
                  <DeleteForeverRounded />
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCell>
      </TableRow>
      {!!children?.length &&
        !!collapse.value &&
        children.map((childrenRow, index) => (
          <CategoryDataTableRow
            key={`${childrenRow.name}-${index}`}
            row={childrenRow}
            table={table}
            selected={table.selected.includes(childrenRow.id)}
            onSelectRow={() => table.onSelectRow(childrenRow.id)}
            onRestoreRow={() => onRestoreRow(childrenRow.id)}
            onDeleteRow={() => onDeleteRow(childrenRow.id)}
            refreshCategoryList={refreshCategoryList}
            isChildrenRow
          />
        ))}

      <CategoryQuickEditForm
        currentCategory={row}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
        refreshCategoryList={refreshCategoryList}
        onDeleteRow={() => onDeleteRow(row.id)}
      />

      <Dropdown
        open={dropdown.open}
        onClose={dropdown.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            router.push(`${paths.dashboard.categories.root}/${id}`);
            dropdown.onClose();
          }}
        >
          <EditRounded />
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            dropdown.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <DeleteForeverRounded />
          Delete
        </MenuItem>
      </Dropdown>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={() => onDeleteRow(row.id)}>
            Delete
          </Button>
        }
      />
      <ConfirmDialog
        open={confirmRestore.value}
        onClose={confirmRestore.onFalse}
        title="Restore"
        content="Are you sure want to restore?"
        action={
          <Button variant="contained" color="success" onClick={() => onRestoreRow(row.id)}>
            Restore
          </Button>
        }
      />
    </>
  );
};

export default CategoryDataTableRow;
