import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { TreeItem, TreeView } from '@mui/lab';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import Iconify from 'src/components/iconify/iconify';
import { normalCase } from 'src/utils/change-case';

const ProductTableToolbar = ({
  filters,
  onFilters,
  //
  stockOptions,
  publishOptions,
  categoryOptions,
}) => {
  const handleFilterSearchTerm = useCallback(
    (event) => {
      onFilters('searchTerm', event.target.value);
    },
    [onFilters]
  );

  const handleFilterStock = useCallback(
    (event) => {
      onFilters(
        'stock',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterPublish = useCallback(
    (event) => {
      onFilters(
        'publish',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
        }}
      >
        {/* {!!categoryOptions?.length && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>Categories</InputLabel>

            <Autocomplete
              multiple
              options={categoryOptions}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name || ''}
              renderOption={(props, option, { selected }) => (
                <div {...props}>
                  <Checkbox checked={selected} style={{ marginRight: 8 }} />
                  {option.name}
                  <TreeView
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                  >
                    {option.children.map((child) => (
                      <TreeItem
                        key={child.id}
                        nodeId={child.id.toString()}
                        label={
                          <div>
                            <Checkbox
                              // checked={selectedOptions.includes(child.id)}
                              // onChange={() => handleChildSelect(child.id)}
                              style={{ marginRight: 8 }}
                            />
                            {child.name}
                          </div>
                        }
                      />
                    ))}
                  </TreeView>
                </div>
              )}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
          </FormControl>
        )} */}

        {!!stockOptions?.length && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>Stock</InputLabel>

            <Select
              multiple
              value={filters.stock}
              onChange={handleFilterStock}
              input={<OutlinedInput label="Stock" />}
              renderValue={(selected) => selected?.map((value) => normalCase(value)).join(', ')}
              sx={{ textTransform: 'capitalize' }}
            >
              {stockOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.stock.includes(option.value)}
                  />
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {!!publishOptions?.length &&
          (!filters.status || (filters.status && filters.status !== 'trashed')) && (
            <FormControl
              sx={{
                flexShrink: 0,
                width: { xs: 1, md: 200 },
              }}
            >
              <InputLabel>Publish</InputLabel>

              <Select
                multiple
                value={filters.publish}
                onChange={handleFilterPublish}
                input={<OutlinedInput label="Publish" />}
                renderValue={(selected) => selected?.map((value) => value).join(', ')}
                sx={{ textTransform: 'capitalize' }}
              >
                {publishOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox
                      disableRipple
                      size="small"
                      checked={filters.publish.includes(option.value)}
                    />
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <TextField
            fullWidth
            value={filters.searchTerm}
            onChange={handleFilterSearchTerm}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};

ProductTableToolbar.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
  publishOptions: PropTypes.array,
  stockOptions: PropTypes.array,
};

export default ProductTableToolbar;
