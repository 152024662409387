import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { PROJECT_NAME } from 'src/config-global';
import { paths } from 'src/paths';
import { BrandEditForm } from 'src/sections/brands/brand-edit-form';

const BrandCreate = () => {
  const settings = useSettingsContext();
  return (
    <>
      <Helmet>
        <title>Dashboard: Create New Brand | {PROJECT_NAME}</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Create a new category"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'Brands',
              href: paths.dashboard.brands.root,
            },
            { name: 'New brand' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <BrandEditForm />
      </Container>
    </>
  );
};

export default BrandCreate;
