import Grid from '@mui/material/Unstable_Grid2/Grid2';
import React, { useCallback, useEffect, useState } from 'react';
import EcommerceOrdersCount from './components/ecommerce-orders-count';
import OverviewService from 'src/services/overview-service';

const OverviewOrdersCount = () => {
  const [chartData, setChartData] = useState([]);

  const getTotalOrdersSummary = useCallback(async () => {
    try {
      const res = await OverviewService.getTotalOrdersGroupByStatus();
      setChartData(
        res?.data?.data.map((data) => ({ label: data.status, value: data.total_orders }))
      );
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getTotalOrdersSummary();
  }, [getTotalOrdersSummary]);

  return (
    <>
      <Grid xs={12} md={4}>
        <EcommerceOrdersCount
          title="Total Orders"
          chart={{
            series: chartData,
          }}
        />
      </Grid>
    </>
  );
};

export default OverviewOrdersCount;
