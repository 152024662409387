import { LoadingButton } from '@mui/lab';
import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import Editor from 'src/components/editor/editor';
import { fileNameByUrl } from 'src/components/file-thumbnail';
import { Upload } from 'src/components/upload';
import { useRouter } from 'src/hooks/routes';
import { useResponsive } from 'src/hooks/use-responsive';
import { paths } from 'src/paths';
import CategoryService from 'src/services/category-service';
import ImageService from 'src/services/image-service';
import * as Yup from 'yup';

const CategoryEditForm = ({ category }) => {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [defaultImages, setDefaultImages] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: category?.name || '',
      description: category?.description || '',
      published: !!category?.published_at,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().max(255).required(),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);

      try {
        let dataImages = [...defaultImages];
        if (files.length > 0) {
          const formData = new FormData();
          files.forEach((file) => formData.append('images[]', file));

          const response = await ImageService.uploadMany(formData);
          dataImages = [...dataImages, ...response.data];
        }

        const data = { ...values, image_ids: dataImages.map((image) => image.id) };
        if (category) {
          await CategoryService.update(category.id, data);
          enqueueSnackbar('Update Successfully!', { variant: 'success' });
        } else {
          const res = await CategoryService.create(data);

          router.push(`${paths.dashboard.categories.root}/${res.data.id}`);
          enqueueSnackbar('Create Successfully!', { variant: 'success' });
        }
      } catch (err) {
        console.log(err);
        enqueueSnackbar(`Failed to ${!category ? 'create' : 'update'}!`, { variant: 'error' });
      }

      setIsSubmitting(false);
    },
  });

  const getImages = useCallback(() => {
    setDefaultImages(category?.images || []);
  }, [category?.images]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFiles([
        ...files,
        ...acceptedFiles.map((newFile) =>
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          })
        ),
      ]);
    },
    [files]
  );

  const handleRemoveFile = (inputFile) => {
    const filesFiltered = files.filter((fileFiltered) => fileFiltered !== inputFile);
    const imagesFilted = defaultImages.filter(
      (image) => fileNameByUrl(image.url) !== fileNameByUrl(inputFile)
    );
    setDefaultImages(imagesFilted);
    setFiles(filesFiltered);
  };

  const handleRemoveAllFiles = () => {
    setDefaultImages([]);
    setFiles([]);
  };

  useEffect(() => {
    getImages();
  }, [getImages]);

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        {mdUp && (
          <Grid md={4}>
            <Typography variant="h6" sx={{ mb: 0.5 }}>
              Details
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Title, short description, image...
            </Typography>
          </Grid>
        )}

        <Grid xs={12} md={8}>
          <Card>
            {!mdUp && <CardHeader title="Details" />}

            <CardContent>
              <Stack spacing={3}>
                <Typography variant="subtitle2">Name</Typography>
                <TextField
                  error={!!(formik.touched.name && formik.errors.name)}
                  fullWidth
                  helperText={formik.touched.name && formik.errors.name}
                  label="Name"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.name}
                />

                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">Description</Typography>
                  <Editor
                    id="category-description"
                    simple
                    error={!!(formik.touched.description && formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                    name="description"
                    onChange={(value) => formik.setFieldValue('description', value)}
                    value={formik.values.description}
                  />
                </Stack>

                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">Images</Typography>
                  <Upload
                    multiple
                    thumbnail
                    files={[...files, ...defaultImages.map((image) => image.url)]}
                    name="images"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    onRemove={handleRemoveFile}
                    onRemoveAll={handleRemoveAllFiles}
                  />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {mdUp && <Grid md={4} />}
        <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            control={<Switch defaultChecked />}
            label="Publish"
            value={formik.values.published}
            onChange={(event) => formik.setFieldValue('published', event.target.checked)}
            sx={{ flexGrow: 1, pl: 3 }}
          />

          <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
            {!category ? 'Create Category' : 'Save Changes'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

CategoryEditForm.propTypes = {
  category: PropTypes.object,
};

export default CategoryEditForm;
