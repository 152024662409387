import { DeleteForeverRounded, EditRounded, MoreVertRounded } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  IconButton,
  ListItemText,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import ConfirmDialog from 'src/components/confirm-dialog';
import Dropdown from 'src/components/dropdown';
import useDropdown from 'src/components/dropdown/use-dropdown';
import Image from 'src/components/image';
import { useRouter } from 'src/hooks/routes';
import { useBoolean } from 'src/hooks/use-boolean';
import { paths } from 'src/paths';
import { slice } from 'src/utils/string';
import BrandDataTableQuickEditForm from './brand-quick-edit-form';

const BrandDataTableRow = ({ row, selected, onSelectRow, onDeleteRow, getBrandList }) => {
  const { id, name, images, slug, updated_at } = row;

  const confirm = useBoolean();
  const quickEdit = useBoolean();
  const dropdown = useDropdown();
  const router = useRouter();

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
          <Image
            alt={name}
            src={images?.find((image) => !!image.is_featured)?.url}
            sx={{
              width: 50,
              height: 50,
              borderRadius: 1,
              mr: 2,
            }}
            ratio="1/1"
          />
          {slice(name || '')}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{slice(slug || '')}</TableCell>
        <TableCell>
          <ListItemText
            primary={format(new Date(updated_at), 'dd MMM yyyy')}
            secondary={format(new Date(updated_at), 'p')}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>
        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
              <EditRounded />
            </IconButton>
          </Tooltip>

          <IconButton color={dropdown.open ? 'inherit' : 'default'} onClick={dropdown.onOpen}>
            <MoreVertRounded />
          </IconButton>
        </TableCell>
      </TableRow>

      <BrandDataTableQuickEditForm
        currentBrand={row}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
        getBrandList={getBrandList}
        onDeleteRow={onDeleteRow}
      />

      <Dropdown
        open={dropdown.open}
        onClose={dropdown.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            router.push(`${paths.dashboard.brands.root}/${id}`);
            dropdown.onClose();
          }}
        >
          <EditRounded />
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            dropdown.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <DeleteForeverRounded />
          Delete
        </MenuItem>
      </Dropdown>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
};

export default BrandDataTableRow;
