import Grid from '@mui/material/Unstable_Grid2/Grid2';
import React, { useCallback, useEffect, useState } from 'react';
import EcommerceWidgetSummary from './components/ecommerce-widget-summary';
import { useTheme } from '@mui/material';
import OverviewService from 'src/services/overview-service';

const TotalsOrdersSummary = () => {
  const theme = useTheme();
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalOrdersInMonth, setTotalOrdersInMonth] = useState([]);
  const [percentChange, setPercentChange] = useState(0);

  const getTotalOrdersSummary = useCallback(async () => {
    try {
      const res = await OverviewService.getTotalOrders();
      setTotalOrders(res?.data?.total_orders || 0);
      setTotalOrdersInMonth(
        res?.data?.total_orders_in_month?.map((sale) => sale.total_orders) || []
      );
      setPercentChange(res?.data?.percent_change || 0);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getTotalOrdersSummary();
  }, [getTotalOrdersSummary]);

  return (
    <>
      <Grid xs={12} md={4}>
        <EcommerceWidgetSummary
          title="Total Orders"
          percent={percentChange}
          total={totalOrders}
          chart={{
            colors: [theme.palette.primary.light, theme.palette.primary.main],
            series: totalOrdersInMonth,
          }}
        />
      </Grid>
    </>
  );
};

export default TotalsOrdersSummary;
