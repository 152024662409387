import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  TextField,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import ProductService from 'src/services/product-service';
import { fCurrency } from 'src/utils/format-number';
import OrderEditFormDetailItem from './order-edit-form-detail-item';

const OrderEditFormDetails = ({ order, formik, isLoading }) => {
  const [products, setProducts] = useState([]);
  const [orderItems, setOrderItems] = useState([]);

  const getProductList = useCallback(async () => {
    const res = await ProductService.getList({ disabledPagination: true });
    setProducts(res.data);
  }, []);

  useEffect(() => {
    getProductList();
  }, [getProductList]);

  const defaultOrderItem = {
    product_id: '',
    product_name: '',
    product_pid: '',
    product_sku: '',
    serial_numbers: [],
    quantity: 0,
    unit_price: 0,
    total_price: 0,
  };

  const handleAddOrderItems = () => {
    setOrderItems([...orderItems, defaultOrderItem]);
  };

  const handleChangeOrderItems = useCallback((index, values) => {
    setOrderItems((prevData) => {
      if (index === -1) {
        return prevData;
      }

      // Create a new array with the updated object
      const updatedData = [...prevData];
      updatedData[index] = values;
      return updatedData;
    });
  }, []);

  const handleRemoveOrderItem = (index) => {
    setOrderItems((prevData) => {
      if (index === -1) {
        return prevData;
      }

      // Create a new array with the updated object
      const updatedData = [...prevData];
      updatedData.splice(index, 1);

      if (!updatedData.length) {
        formik.setFieldValue('shipping_price', 0);
        formik.setFieldValue('discount_price', 0);
        return [defaultOrderItem]
      }

      return updatedData;
    });
  };

  useEffect(() => {
    setOrderItems(order?.order_items || [defaultOrderItem]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    formik.setFieldValue('order_items', orderItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItems]);

  const renderTotal = (
    <Stack
      spacing={2}
      alignItems="flex-end"
      sx={{ mt: 3, textAlign: 'right', typography: 'body2' }}
    >
      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Subtotal</Box>
        <Box sx={{ width: 160, typography: 'subtitle2' }}>
          {fCurrency(formik.values.subtotal_price) || '-'}
        </Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Shipping</Box>
        <Box
          sx={{
            width: 160,
            color: 'error.main',
          }}
        >
          {!!formik.values.shipping_price && '-'}
          {fCurrency(formik.values.shipping_price) || '-'}
        </Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Discount</Box>
        <Box
          sx={{
            width: 160,
            color: 'error.main',
          }}
        >
          {!!formik.values.discount_price && '-'}
          {fCurrency(formik.values.discount_price) || '-'}
        </Box>
      </Stack>

      <Stack direction="row" sx={{ typography: 'subtitle1' }}>
        <Box>Total</Box>
        <Box sx={{ width: 160 }}>{fCurrency(formik.values.total_price) || '-'}</Box>
      </Stack>
    </Stack>
  );

  return (
    <Card>
      <CardHeader
        title="Details"
        action={
          <IconButton onClick={handleAddOrderItems}>
            <Iconify icon="fa-solid:plus" />
          </IconButton>
        }
      />

      <CardContent>
        <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
          {isLoading
            ? [...Array(3)].map((i, index) => (
              <Stack spacing={3} direction="row" alignItems="center" key={index}>
                <Skeleton sx={{ borderRadius: 1.5, width: 48, height: 48, flexShrink: 0 }} />
                <Skeleton sx={{ width: 1, height: 12 }} />
                <Skeleton sx={{ width: 180, height: 12 }} />
                <Skeleton sx={{ width: 160, height: 12 }} />
              </Stack>
            ))
            : orderItems.map((item, index) => (
              <OrderEditFormDetailItem
                key={`order-item-${index}`}
                index={index}
                item={item}
                orderItems={orderItems}
                products={products}
                onChangeOrderItems={handleChangeOrderItems}
                onRemoveOrderItem={handleRemoveOrderItem}
              />
            ))}
        </Stack>

        <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

        <Stack
          spacing={3}
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'flex-end', md: 'center' }}
        >
          <Stack
            spacing={2}
            justifyContent="flex-end"
            direction={{ xs: 'column', md: 'row' }}
            sx={{ width: 1 }}
          >
            <TextField
              error={formik.touched.shipping_price && formik.errors.shipping_price}
              helperText={formik.touched.shipping_price && formik.errors.shipping_price}
              label="Shipping"
              name="shipping_price"
              placeholder="Add Shipping Price"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.shipping_price}
              type="number"
              InputLabelProps={{ shrink: true }}
              sx={{ width: { md: 120 } }}
            />
            <TextField
              error={formik.touched.discount_price && formik.errors.discount_price}
              helperText={formik.touched.discount_price && formik.errors.discount_price}
              label="Discount"
              name="discount_price"
              placeholder="Add Discount Price"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.discount_price}
              type="number"
              InputLabelProps={{ shrink: true }}
              sx={{ width: { md: 120 } }}
            />
            <TextField
              error={formik.touched.total_price && formik.errors.total_price}
              helperText={formik.touched.total_price && formik.errors.total_price}
              label="Total"
              name="total_price"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.total_price}
              type="number"
              InputLabelProps={{ shrink: true }}
              sx={{ width: { md: 120 } }}
            />
          </Stack>
        </Stack>
        {renderTotal}
      </CardContent>
    </Card>
  );
};

export default OrderEditFormDetails;
