import PropTypes from 'prop-types';
import { Box, Stack, ListItemText, Typography, Link } from "@mui/material";
import { forwardRef } from 'react';
import { ASSETS_URL } from '../config-global';
import RouterLink from './router-link';

const Logo = forwardRef(({ link = '/', sx, noText = false, ...other }, ref) => {
  const logo = (
    <Box
      component="img"
      src={`${ASSETS_URL}/logo/logo.png`}
      sx={{ width: 30, cursor: 'pointer', ...sx }}
      {...other}
    />
  );

  return (
    <Link component={RouterLink} href={link} sx={{
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none'
      }
    }}>
      <Stack direction="row" spacing={1} alignItems="center">
        {logo}

        {!noText && <ListItemText
          primary={
            <Stack direction="row" spacing={0.5}>
              <Typography variant="h6" color="secondary">
                IT
              </Typography>
              <Typography variant="h6">
                Hardware Broker
              </Typography>
              <Typography variant="caption">
                Pty Ltd
              </Typography>
            </Stack>
          }
        />}
      </Stack>
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
}

export default Logo;
