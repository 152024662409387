import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import OrderDetailInfo from './order-detail-info';
import OrderDetailItems from './order-detail-items';

const OrderDetailView = ({ order, isLoading = false }) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={8}>
          <Stack spacing={3} direction={{ xs: 'column-reverse', md: 'column' }}>
            <OrderDetailItems isLoading={isLoading} order={order} />
          </Stack>
        </Grid>
        <Grid xs={12} md={4}>
          <OrderDetailInfo order={order} />
        </Grid>
      </Grid>
    </>
  );
};

export default OrderDetailView;
