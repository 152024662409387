import Grid from '@mui/material/Unstable_Grid2/Grid2'
import React, { useCallback, useEffect, useState } from 'react'
import EcommerceWidgetSummary from './components/ecommerce-widget-summary'
import { useTheme } from '@mui/material'
import OverviewService from 'src/services/overview-service'
import { fDate } from 'src/utils/format-time'

const SalesSummary = ({ startDate, endDate }) => {
  const theme = useTheme();
  const [totalSales, setTotalSales] = useState(0);
  const [totalSalesInMonth, setTotalSalesInMonth] = useState([]);
  const [percentChange, setPercentChange] = useState(0);

  const getTotalSalesSummary = useCallback(
    async () => {
      try {
        const res = await OverviewService.getTotalSales({ startDate: fDate(startDate), endDate: fDate(endDate) });
        setTotalSales(res?.data?.total_sales || 0);
        setTotalSalesInMonth(res?.data?.total_sales_in_month?.map(sale => sale.total_sales) || []);
        setPercentChange(res?.data?.percent_change || 0);
      } catch (error) {
        console.error(error);
      }
    },
    [startDate, endDate],
  );

  useEffect(() => {
    getTotalSalesSummary();
  }, [getTotalSalesSummary])

  return (
    <>
      <Grid xs={12} md={4}>
        <EcommerceWidgetSummary
          title="Total Sales"
          percent={percentChange}
          total={totalSales}
          chart={{
            colors: [theme.palette.info.light, theme.palette.info.main],
            series: totalSalesInMonth,
          }}
        />
      </Grid>
    </>
  )
}

export default SalesSummary
