import { Check, Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  ListItem,
  ListItemText,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Image from 'src/components/image/image';
import SerialNumberService from 'src/services/serial-number-service';
import * as Yup from 'yup';

const SerialNumberFormRow = ({ row, products = [] }) => {
  const { product, serialNumbers, quantity, note } = row;
  const [error, setError] = useState('');

  const formik = useFormik({
    initialValues: {
      product: products?.find((p) => p.id === product?.id) || null,
      serialNumbers: serialNumbers || [],
      quantity: quantity || 0,
      note: note || '',
    },
    validationSchema: Yup.object({
      quantity: Yup.number().min(0),
      product: Yup.object()
        .nullable()
        .test('required', 'Product is required!', (value) => {
          if (!!formik.values.serialNumbers.length && !value) {
            return false;
          }

          return true;
        }),
      serialNumbers: Yup.array().test('required', 'Serial numbers are required!', (value) => {
        if (formik.values.product && value.length <= 0) {
          return false;
        }
        return true;
      }),
    }),
    onSubmit: async (values) => {
      if (!values.product || !values.serialNumbers.length) {
        return;
      }

      try {
        const data = {
          product_id: values.product?.id,
          serial_numbers: values.serialNumbers,
          quantity: values.quantity,
          note: values.note,
        };

        await SerialNumberService.createMany(data);
        enqueueSnackbar('Successfully create!', { variant: 'success', autoHideDuration: false });
        setError('');
      } catch (error) {
        setError(error);
        enqueueSnackbar('Failed to add stock!', { variant: 'error', autoHideDuration: false });
        console.error(error);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue('quantity', formik.values.serialNumbers.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.serialNumbers]);

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Autocomplete
            fullWidth
            options={products}
            getOptionLabel={(option) => option.pid || ''}
            value={formik.values.product}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onChange={(event, value) => formik.setFieldValue('product', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Product"
                error={!!error || (formik.touched.product && formik.errors.product)}
                helperText={formik.touched.product && formik.errors.product}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <ListItem {...props} key={option.name}>
                <Image
                  alt={option.name}
                  src={option.images.find((image) => !!image.is_featured)?.url}
                  sx={{
                    width: 64,
                    height: 64,
                    borderRadius: 2,
                    mr: 2,
                  }}
                  variant="rounded"
                  ratio="1/1"
                />

                <ListItemText
                  disableTypography
                  primary={option.name}
                  secondary={
                    <Box component="div" sx={{ typography: 'body2', color: 'text.disabled' }}>
                      {option.pid}
                    </Box>
                  }
                />
              </ListItem>
            )}
          />
        </TableCell>
        <TableCell>
          <Autocomplete
            multiple
            id="serial-numbers"
            onChange={(event, value) => formik.setFieldValue('serialNumbers', value)}
            options={[]}
            value={formik.values.serialNumbers}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label="Serial Numbers"
                placeholder="Add Serial Number"
                error={!!error || (formik.touched.serialNumbers && formik.errors.serialNumbers)}
                helperText={(formik.touched.serialNumbers && formik.errors.serialNumbers) || error}
              />
            )}
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={`${option}-${index}`}
                  label={option}
                  size="small"
                  color="primary"
                />
              ))
            }
          />
        </TableCell>
        <TableCell>
          <TextField
            fullWidth
            error={!!error}
            helperText={formik.touched.quantity && formik.errors.quantity}
            label="Quantity"
            name="quantity"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.quantity}
            type="number"
          />
        </TableCell>
        <TableCell>
          <TextField
            fullWidth
            multiline
            error={!!error}
            helperText={formik.touched.note && formik.errors.note}
            label="Note"
            name="note"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.note}
            type="number"
          />
        </TableCell>
        <TableCell>
          <>
            <IconButton color="default" onClick={formik.handleSubmit} size="small">
              <Check size="small" />
            </IconButton>

            <IconButton color="error" size="small">
              <Close size="small" />
            </IconButton>
          </>
        </TableCell>
      </TableRow>
    </>
  );
};

export default SerialNumberFormRow;
