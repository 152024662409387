import { Box, Card, Container, Link, Stack, Typography } from '@mui/material';
import { m } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { MotionViewport, varFade } from 'src/components/animate';
import Image from 'src/components/image/image';
import RouterLink from 'src/components/router-link';
import BrandService from 'src/services/brand-service';

const HomeOffer = () => {
  const [brands, setBrands] = useState([]);

  const getBrandList = useCallback(async () => {
    const res = await BrandService.getList();
    setBrands(res.data);
  }, []);

  useEffect(() => {
    getBrandList();
  }, [getBrandList]);

  return (
    <>
      <Container
        component={MotionViewport}
        sx={{
          py: { xs: 10, md: 15 },
        }}
      >
        <Stack
          spacing={3}
          sx={{
            textAlign: 'center',
            mb: { xs: 5, md: 10 },
          }}
        >
          <m.div variants={varFade().inDown}>
            <Typography variant="h2">What do we offer?</Typography>
          </m.div>

          <Box
            gap={{ xs: 3, lg: 10 }}
            display="grid"
            alignItems="center"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              md: 'repeat(4, 1fr)',
            }}
          >
            {brands.map((brand, index) => (
              <m.div variants={varFade().inUp} key={`${brand.name}-${index}`}>
                <Link component={RouterLink} href="/">
                  <Card
                    sx={{
                      textAlign: 'center',
                      bgcolor: 'background.default',
                      p: (theme) => theme.spacing(5, 5),
                      '&:hover': {
                        color: 'text.secondary',
                      },
                    }}
                  >
                    <Image
                      src={brand.images[0]?.url}
                      alt={brand.name}
                      sx={{ mx: 'auto', width: 100, borderRadius: 1.5 }}
                      ratio="1/1"
                    />
                    {/* <Box
                    component="img"
                    src={brand.images[0]?.url}
                    alt={brand.name}
                    sx={{ mx: 'auto', width: 48, height: 48 }}
                  /> */}

                    <Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
                      {brand.name}
                    </Typography>

                    {/* <Typography sx={{ color: 'text.secondary' }}>{brand.description}</Typography> */}
                  </Card>
                </Link>
              </m.div>
            ))}
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default HomeOffer;
