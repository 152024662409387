import { Check, Close, DeleteForeverRounded, EditRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedAction,
  getComparator,
  useTable,
} from 'src/components/table';
import { Upload } from 'src/components/upload';
import { useBoolean } from 'src/hooks/use-boolean';
import { fDateTime } from 'src/utils/format-time';
import { slice } from 'src/utils/string';
import * as XLSX from 'xlsx';
import CategoryDataTableFiltersResult from './category-data-table-filters-result';
import CategoryDataTableToolbar from './category-data-table-toolbar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ConfirmDialog from 'src/components/confirm-dialog';
import { enqueueSnackbar } from 'notistack';
import CategoryService from 'src/services/category-service';

const defaultFilters = {
  name: '',
};

const TABLE_COLUMNS = [
  { id: 'expand', width: 20 },
  { id: 'name', label: 'Name' },
  { id: 'published', label: 'Published' },
  { id: 'modified', label: 'Modified' },
  { id: '', width: 88 },
];

const CategoryDataTableImportExcel = ({
  open,
  onClose,
  refreshCategoryList,
  refreshCategoryStatusCount,
}) => {
  const [importedCategories, setImportedCategories] = useState([]);
  const [selectAllRowsData, setSelectAllRowsData] = useState([]);
  const [total, setTotal] = useState(0);
  const isUploading = useBoolean();
  const confirm = useBoolean();

  const table = useTable();
  const [filters, setFilters] = useState(defaultFilters);

  const applyFilter = ({ inputData, comparator, filters }) => {
    const { name } = filters;

    const stabilizedThis = inputData.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (name) {
      inputData = inputData.filter((category) => {
        if (
          !!category.children?.find(
            (item) => item.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
          )
        ) {
          return true;
        }
        return category.name.toLowerCase().indexOf(name.toLowerCase()) !== -1;
      });
    }

    return inputData;
  };

  const dataFiltered = applyFilter({
    inputData: importedCategories,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  const canReset = !isEqual(defaultFilters, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleFileUpload = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      // Assuming you want to read the first sheet (index 0) in the Excel file
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Parse the sheet data into a JSON object
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const filteredData = excelData.filter((row, index) => index !== 0 && row && !!row.length);

      // Now you have the Excel data in excelData
      const groupedData = [];

      filteredData.forEach((item, index) => {
        const groupItem = groupedData.find((group) => group.name === item[0]);

        const name = item[1]?.trim() || '';
        if (groupItem) {
          if (
            name &&
            !groupItem.children.filter((childrenItem) => childrenItem.name === name).length
          ) {
            groupItem.children.push({
              name: name,
              published: item[2] || 'Published',
              updated_at: fDateTime(new Date()),
            });
          }
        } else {
          const parent = {
            name: item[0],
            published: 'Published',
            updated_at: fDateTime(new Date()),
          };
          if (name) {
            const children = {
              name: name,
              published: item[2] || 'Published',
              updated_at: fDateTime(new Date()),
            };
            groupedData.push({ ...parent, children: [children] });
          } else {
            groupedData.push({ ...parent, children: [] });
          }
        }
      });

      const items = [];
      groupedData.forEach((row) => {
        items.push(row.name);
        if (!!row.children?.length) {
          row.children?.forEach((item) => {
            items.push(item.name);
          });
        }
      });

      setSelectAllRowsData(items);
      setTotal(items.length);

      setImportedCategories(groupedData);
    };

    reader.readAsBinaryString(file);
  }, []);

  const handleDeleteRows = useCallback(() => {
    setImportedCategories((prevData) => {
      // Create a new array with the updated object
      let updatedData = [];
      importedCategories.forEach((row) => {
        if (!table.selected.includes(row.name)) {
          if (!!row.children.length) {
            let children = [];

            row.children.forEach((childRow) => {
              if (!table.selected.includes(childRow.name)) {
                children.push(childRow);
              }
            });

            row.children = children;
          }
          updatedData.push(row);
        }
      });
      importedCategories.filter((row) => !table.selected.includes(row.name));

      return updatedData;
    });

    table.onUpdatePageDeleteRows({
      totalRows: importedCategories.length,
      totalRowsInPage: dataFiltered.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [dataFiltered.length, table, importedCategories]);

  const handleDeleteRow = useCallback(
    (parentIndex, childrenIndex, isDeletingChildren) => {
      setImportedCategories((prevData) => {
        // Create a new array with the updated object
        const updatedData = [...dataFiltered];
        if (!isDeletingChildren) {
          updatedData.splice(parentIndex, 1);
        } else {
          if (childrenIndex >= 0 && !!updatedData[parentIndex].children.length) {
            updatedData[parentIndex].children.splice(childrenIndex, 1);
          }
        }

        return updatedData;
      });
    },
    [dataFiltered]
  );

  const handleChangeCategories = useCallback(
    (parentIndex, values, childrenIndex, isUpdatingChildren) => {
      setImportedCategories((prevData) => {
        // Create a new array with the updated object
        const updatedData = [...dataFiltered];
        if (!isUpdatingChildren) {
          updatedData[parentIndex] = { ...updatedData[parentIndex], ...values };
        } else {
          if (childrenIndex >= 0 && !!updatedData[parentIndex].children.length) {
            updatedData[parentIndex].children[childrenIndex] = {
              ...updatedData[parentIndex].children[childrenIndex],
              ...values,
            };
          }
        }

        return updatedData;
      });
    },
    [dataFiltered]
  );

  const handleImportCategories = async () => {
    try {
      await CategoryService.importCategories(importedCategories);
      enqueueSnackbar('Imported Successfully! Please reload page after a few munites', {
        variant: 'success',
      });
      onClose();
    } catch (error) {
      enqueueSnackbar('Failed to import!', { variant: 'error' });
      console.error(error);
    }
  };

  const renderTable = (
    <>
      <CategoryDataTableToolbar filters={filters} onFilters={handleFilters} />

      {canReset && (
        <CategoryDataTableFiltersResult
          filters={filters}
          onFilters={handleFilters}
          //
          onResetFilters={handleResetFilters}
          //
          results={dataFiltered.length}
          sx={{ p: 2.5, pt: 0 }}
        />
      )}

      <TableContainer sx={{ maxHeight: 440, position: 'relative', overflow: 'unset' }}>
        <TableSelectedAction
          numSelected={table.selected.length}
          rowCount={total}
          onSelectAllRows={(checked) => table.onSelectAllRows(checked, selectAllRowsData)}
          action={
            <Tooltip title="Delete">
              <IconButton color="primary" onClick={handleDeleteRows}>
                <DeleteForeverRounded />
              </IconButton>
            </Tooltip>
          }
        />
        <Table size={table.dense ? 'small' : 'medium'} stickyHeader aria-label="sticky table">
          <TableHeadCustom
            order={table.order}
            orderBy={table.orderBy}
            headLabel={TABLE_COLUMNS}
            rowCount={total}
            numSelected={table.selected.length}
            onSort={table.onSort}
            onSelectAllRows={(checked) => table.onSelectAllRows(checked, selectAllRowsData)}
          />
          <TableBody>
            {dataFiltered.map((row, index) => (
              <CategoryTableRow
                key={row.name}
                parentIndex={index}
                row={row}
                table={table}
                selected={table.selected.includes(row.name)}
                onSelectRow={() => table.onSelectRow(row.name)}
                onUpdateRow={handleChangeCategories}
                onDeleteRow={handleDeleteRow}
              />
            ))}

            <TableNoData notFound={notFound} />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return (
    <>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { py: 2 },
        }}
        fullWidth
      >
        <DialogTitle>Import Categories</DialogTitle>

        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {!importedCategories.length ? <Upload onDrop={handleFileUpload} /> : renderTable}
          </Box>
        </DialogContent>

        <DialogActions sx={{ px: 3 }}>
          <Stack direction="row" spacing={2} width="100%" justifyContent="space-between">
            <Button variant="contained" color="info" onClick={() => setImportedCategories([])}>
              Reset
            </Button>
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={onClose}>
                Close
              </Button>

              <LoadingButton
                variant="contained"
                loading={isUploading.value}
                onClick={confirm.onTrue}
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to import <strong> {selectAllRowsData.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handleImportCategories();
              confirm.onFalse();
            }}
          >
            Import
          </Button>
        }
      />
    </>
  );
};

export default CategoryDataTableImportExcel;

const CategoryTableRow = ({
  row,
  selected,
  onSelectRow,
  onUpdateRow,
  onDeleteRow,
  table,
  isChildrenRow = false,
  parentIndex,
  childrenIndex,
}) => {
  const { name, published, children, updated_at } = row;

  const quickEdit = useBoolean();
  const collapse = useBoolean();

  const formik = useFormik({
    initialValues: {
      name: name || '',
      published: published || 'Published',
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      name: Yup.string().required(),
    }),
  });

  const handleChangeRow = () => {
    onUpdateRow(parentIndex, formik.values, childrenIndex, isChildrenRow);
    quickEdit.onFalse();
  };

  return (
    <>
      <TableRow
        hover
        selected={selected}
        sx={{ backgroundColor: isChildrenRow && 'hsl(0,0%,98.5%)' }}
      >
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell align="center" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {!!children?.length && (
            <IconButton
              color={collapse.value ? 'inherit' : 'default'}
              onClick={collapse.onToggle}
              sx={{
                ...(collapse.value && {
                  bgcolor: 'action.hover',
                }),
              }}
            >
              <Iconify
                icon={collapse.value ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
              />
            </IconButton>
          )}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {!quickEdit.value ? (
            slice(name || '')
          ) : (
            <TextField
              fullWidth
              error={formik.touched.name && formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              label="Name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              type="text"
              InputLabelProps={{ shrink: true }}
            />
          )}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Label
            variant="soft"
            color={
              published === 'Published' ? 'secondary' : published === 'draft' ? 'primary' : 'error'
            }
          >
            {published}
          </Label>
        </TableCell>

        <TableCell>
          <ListItemText
            primary={format(new Date(updated_at), 'dd MMM yyyy')}
            secondary={format(new Date(updated_at), 'p')}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {!quickEdit.value ? (
            <>
              <Tooltip title="Quick Edit" placement="top" arrow>
                <IconButton
                  color={quickEdit.value ? 'inherit' : 'default'}
                  onClick={quickEdit.onTrue}
                >
                  <EditRounded />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" placement="top" arrow>
                <IconButton
                  color="error"
                  onClick={() => onDeleteRow(parentIndex, childrenIndex, isChildrenRow)}
                >
                  <DeleteForeverRounded />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Confirm" placement="top" arrow>
                <IconButton
                  color={quickEdit.value ? 'inherit' : 'default'}
                  onClick={handleChangeRow}
                >
                  <Check size="small" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" placement="top" arrow>
                <IconButton color="error" onClick={quickEdit.onFalse}>
                  <Close size="small" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCell>
      </TableRow>

      {!!children?.length &&
        !!collapse.value &&
        children.map((childrenRow, index) => (
          <CategoryTableRow
            key={`${childrenRow.name}-${index}`}
            row={childrenRow}
            selected={table.selected.includes(childrenRow.name)}
            onSelectRow={() => table.onSelectRow(childrenRow.name)}
            onUpdateRow={onUpdateRow}
            onDeleteRow={onDeleteRow}
            isChildrenRow
            parentIndex={parentIndex}
            childrenIndex={index}
          />
        ))}
    </>
  );
};
