import { Helmet } from 'react-helmet-async';
import { PROJECT_NAME } from 'src/config-global';
import { ProductShopView } from 'src/sections/products/product-shop-view';

const Shop = () => {
  return (
    <>
      <Helmet>
        <title> Product: Shop | {PROJECT_NAME}</title>
      </Helmet>

      <ProductShopView />
    </>
  );
};

export default Shop;
