import { Container } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { PROJECT_NAME } from 'src/config-global';
import { useParams } from 'src/hooks/routes';
import { paths } from 'src/paths';
import ProductEditForm from 'src/sections/products/product-edit-form/product-edit-form';
import ProductService from 'src/services/product-service';

const ProductEdit = () => {
  const settings = useSettingsContext();

  const { id } = useParams();

  const [product, setProduct] = useState();

  const getProductDetail = useCallback(async () => {
    try {
      const res = await ProductService.getDetail(id);
      setProduct(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  useEffect(() => {
    getProductDetail();
  }, [getProductDetail]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Edit Product | {PROJECT_NAME}</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Edit Product"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'Products',
              href: paths.dashboard.products.root,
            },
            { name: 'Edit product' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <ProductEditForm product={product} getProduct={getProductDetail}/>
      </Container>
    </>
  );
};

export default ProductEdit;
