import { api } from 'src/paths';
import axios from 'src/utils/axios';
import { replaceId, urlWithQueryString } from 'src/utils/string';

const getList = (queryFilters) => {
  return axios.get(urlWithQueryString(api.orders.list, queryFilters));
};

const getDetail = (id) => {
  return axios.get(api.orders.detail(id));
};

const create = (data) => {
  return axios.post(api.orders.create, data);
};

const update = (id, data) => {
  return axios.put(api.orders.update(id), data);
};

const putOneToTrash = (id) => {
  return axios.delete(api.orders.trashOne(id));
};

const putManyToTrash = (ids) => {
  return axios.post(api.orders.trashMany, { order_ids: ids });
};

const deleteOne = (id) => {
  return axios.delete(replaceId(api.orders.deleteMany, { order_ids: [id] }));
};

const deleteMany = (ids) => {
  return axios.post(api.orders.deleteMany, { order_ids: ids });
};

const getStatusList = (query) => {
  return axios.get(urlWithQueryString(api.orders.status, query));
};

const updateStatus = (id, status) => {
  return axios.put(api.orders.updateStatus(id), { status: status });
}

const OrderService = {
  getList,
  getDetail,
  create,
  update,
  putOneToTrash,
  putManyToTrash,
  deleteOne,
  deleteMany,
  getStatusList,
  updateStatus,
};

export default OrderService;
