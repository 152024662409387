import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { PROJECT_NAME } from 'src/config-global';
import { useParams } from 'src/hooks/routes';
import { paths } from 'src/paths';
import CategoryEditForm from 'src/sections/categories/category-edit-form/category-edit-form';
import CategoryService from 'src/services/category-service';

const CategoryEdit = () => {
  const settings = useSettingsContext();

  const { id } = useParams();

  const { category } = CategoryService.useGetCategoryDetail(id);

  return (
    <>
      <Helmet>
        <title> Dashboard: Category Edit | {PROJECT_NAME}</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Edit"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            {
              name: 'Categories',
              href: paths.dashboard.categories.root,
            },
            { name: category?.name },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <CategoryEditForm category={category} />
      </Container>
    </>
  );
};

export default CategoryEdit;
