import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Stack,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Upload } from 'src/components/upload';
import BrandService from 'src/services/brand-service';
import ImageService from 'src/services/image-service';
import * as Yup from 'yup';

const BrandQuickEditForm = ({ currentBrand, open, onClose, onDeleteRow, getBrandList }) => {
  const { id, name, images, description } = currentBrand;
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(images?.[0] || null);

  const formik = useFormik({
    initialValues: {
      name: name,
      description: description,
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required(),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        let data = { ...values, image_ids: image ? [image.id] : [] };
        if (file) {
          const formData = new FormData();
          formData.append('image', file);

          const response = await ImageService.uploadOne(formData);
          data.image_ids = [response.data.id];
        }

        await BrandService.update(id, data);
        enqueueSnackbar('Update Successfully!', { variant: 'success' });
        getBrandList();
        onClose();
      } catch (err) {
        console.log(err);
        enqueueSnackbar('Failed to update!', { variant: 'error' });
      }

      setIsSubmitting(false);
    },
  });

  const handleDropSingleFile = useCallback((acceptedFiles) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFile(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    }
  }, []);

  return (
    <>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { py: 2 },
        }}
        fullWidth
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <DialogTitle>Quick Update</DialogTitle>

          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <FormGroup>
                <TextField
                  error={!!(formik.touched.name && formik.errors.name)}
                  fullWidth
                  helperText={formik.touched.name && formik.errors.name}
                  label="Name"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.name}
                />
              </FormGroup>

              <Box sx={{ my: 2 }}>
                <Card>
                  <CardContent>
                    <Upload
                      file={file || image?.url}
                      onDrop={handleDropSingleFile}
                      onDelete={() => {
                        setFile(null);
                        setImage(null);
                      }}
                    />
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </DialogContent>

          <DialogActions sx={{ px: 3 }}>
            <Stack direction="row" spacing={2} width="100%" justifyContent="space-between">
              <Button variant="contained" color="error" onClick={onDeleteRow}>
                Delete
              </Button>
              <Stack direction="row" spacing={2}>
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

BrandQuickEditForm.propTypes = {
  currentBrand: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  getBrandList: PropTypes.func,
};

export default BrandQuickEditForm;
