import { Add } from '@mui/icons-material';
import { Button, Container } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import RouterLink from 'src/components/router-link';
import { PROJECT_NAME } from 'src/config-global';
import { paths } from 'src/paths';
import OrderDataTable from 'src/sections/orders/order-data-table';

const OrderList = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard: Order List - {PROJECT_NAME}</title>
      </Helmet>

      <Container maxWidth="lg">
        <CustomBreadcrumbs
          heading="Order List"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Orders', href: paths.dashboard.orders.root },
            { name: 'List' },
          ]}
          action={
            <Button
              component={RouterLink}
              href={paths.dashboard.orders.create}
              variant="contained"
              startIcon={<Add />}
            >
              New Order
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <OrderDataTable />
      </Container>
    </>
  );
};

export default OrderList;
