import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Divider,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { countries } from 'src/assets/data';
import Iconify from 'src/components/iconify';

const OrderEditFormInfo = ({ order, formik }) => {
  const renderCustomer = (
    <>
      <CardHeader
        title="Customer Info"
      // action={
      //   <IconButton>
      //     <Iconify icon="solar:pen-bold" />
      //   </IconButton>
      // }
      />
      <Stack sx={{ p: 3 }} spacing={2}>
        <TextField
          fullWidth
          error={formik.touched.customer_name && formik.errors.customer_name}
          helperText={formik.touched.customer_name && formik.errors.customer_name}
          label="Name"
          name="customer_name"
          placeholder="Add Customer Name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.customer_name}
          type="text"
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          fullWidth
          error={formik.touched.customer_phone && formik.errors.customer_phone}
          helperText={formik.touched.customer_phone && formik.errors.customer_phone}
          label="Phone"
          name="customer_phone"
          placeholder="Add Customer Contact"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.customer_phone}
          type="text"
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          fullWidth
          error={formik.touched.customer_email && formik.errors.customer_email}
          helperText={formik.touched.customer_email && formik.errors.customer_email}
          label="Email"
          name="customer_email"
          placeholder="Add Customer Email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.customer_email}
          type="text"
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          fullWidth
          error={formik.touched.company_name && formik.errors.company_name}
          helperText={formik.touched.company_name && formik.errors.company_name}
          label="Company"
          name="company_name"
          placeholder="Add Company"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.company_name}
          type="text"
          InputLabelProps={{ shrink: true }}
        />
      </Stack>
    </>
  );

  const renderDelivery = (
    <>
      <CardHeader
        title="Delivery"
      // action={
      //   <IconButton>
      //     <Iconify icon="solar:pen-bold" />
      //   </IconButton>
      // }
      />

      <Stack sx={{ p: 3 }} spacing={2}>
        <Select
          error={!!(formik.touched.shipping_method && formik.errors.shipping_method)}
          fullWidth
          name="shipping_method"
          placeholder="Add Shipping Method"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.shipping_method}
        >
          <MenuItem value={'delivery'}>Delivery</MenuItem>
          <MenuItem value={'pickup'}>Pick Up</MenuItem>
        </Select>

        {formik.values.shipping_method === 'delivery' && (
          <>
            <TextField
              fullWidth
              error={formik.touched.shipping_name && formik.errors.shipping_name}
              helperText={formik.touched.shipping_name && formik.errors.shipping_name}
              label="Ship By"
              name="shipping_name"
              placeholder="Ship By"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.shipping_name}
              type="text"
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              fullWidth
              error={formik.touched.shipping_tracking_number && formik.errors.shipping_tracking_number}
              helperText={
                formik.touched.shipping_tracking_number && formik.errors.shipping_tracking_number
              }
              label="Tracking Number"
              name="shipping_tracking_number"
              placeholder="Add Tracking Number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.shipping_tracking_number}
              type="text"
              InputLabelProps={{ shrink: true }}
            />
          </>
        )}
      </Stack>
    </>
  );

  const renderPostage = (
    <>
      <CardHeader
        title="Postage"
      // action={
      //   <IconButton>
      //     <Iconify icon="solar:pen-bold" />
      //   </IconButton>
      // }
      />

      <Grid container rowSpacing={2} columnSpacing={1} sx={{ p: 3 }}>
        <Grid xs={12} md={12}>
          <Autocomplete
            name="country"
            label="Country"
            placeholder="Add country"
            onChange={(event, value) => formik.setFieldValue('country', value)}
            value={formik.values.country}
            options={countries.map((country) => country.label)}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                error={formik.touched.country && formik.errors.country}
                helperText={formik.touched.country && formik.errors.country}
              />
            )}
            renderOption={(props, option) => {
              const { code, label, phone } = countries.filter(
                (country) => country.label === option
              )[0];

              if (!label) {
                return <></>;
              }

              return (
                <li {...props} key={label}>
                  <Iconify
                    key={label}
                    icon={`circle-flags:${code.toLowerCase()}`}
                    width={28}
                    sx={{ mr: 1 }}
                  />
                  {label} ({code}) +{phone}
                </li>
              );
            }}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <TextField
            fullWidth
            error={formik.touched.address_line_1 && formik.errors.address_line_1}
            helperText={formik.touched.address_line_1 && formik.errors.address_line_1}
            label="Address Line 1"
            name="address_line_1"
            placeholder="Add Address Line 1"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.address_line_1}
            type="text"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <TextField
            fullWidth
            error={formik.touched.address_line_2 && formik.errors.address_line_2}
            helperText={formik.touched.address_line_2 && formik.errors.address_line_2}
            label="Address Line 2"
            name="address_line_2"
            placeholder="Add Address Line 2"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.address_line_2}
            type="text"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <TextField
            fullWidth
            error={formik.touched.city && formik.errors.city}
            helperText={formik.touched.city && formik.errors.city}
            label="City"
            name="city"
            placeholder="Add City"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.city}
            type="text"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <TextField
            fullWidth
            error={formik.touched.state && formik.errors.state}
            helperText={formik.touched.state && formik.errors.state}
            label="State"
            name="state"
            placeholder="Add state"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.state}
            type="text"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <TextField
            fullWidth
            error={formik.touched.postcode && formik.errors.postcode}
            helperText={formik.touched.postcode && formik.errors.postcode}
            label="Postcode"
            name="postcode"
            placeholder="Add postcode"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.postcode}
            type="text"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </>
  );

  const renderNote = (
    <>
      <CardHeader
        title="Note"
      />

      <CardContent sx={{ py: 1.5 }}>
        <TextField
          fullWidth
          name="note"
          placeholder="Note..."
          multiline
          rows={5}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.note}
          type="text"
          InputLabelProps={{ shrink: true }}
        />
      </CardContent>
    </>
  )

  return (
    <Card>
      {renderCustomer}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {renderDelivery}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {renderPostage}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {renderNote}
    </Card>
  );
};

export default OrderEditFormInfo;
