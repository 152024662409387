import { DeleteForeverRounded, EditRounded, MoreVertRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  ListItemText,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import ConfirmDialog from 'src/components/confirm-dialog';
import Dropdown from 'src/components/dropdown';
import useDropdown from 'src/components/dropdown/use-dropdown';
import Image from 'src/components/image/image';
import Label from 'src/components/label/label';
import RouterLink from 'src/components/router-link';
import { useRouter } from 'src/hooks/routes';
import { useBoolean } from 'src/hooks/use-boolean';
import { paths } from 'src/paths';
import { fCurrency } from 'src/utils/format-number';
import { slice } from 'src/utils/string';
import ProductQuickEditForm from './product-quick-edit-form';
import CategoryService from 'src/services/category-service';

const ProductDataTableRow = ({ row, selected, onSelectRow, onDeleteRow, getProductList }) => {
  const {
    id,
    name,
    pid,
    images = [],
    quantity = 0,
    sku,
    regular_price = 0,
    sale_price = 0,
    status,
    status_name,
    condition,
    updated_at,
  } = row;

  const confirm = useBoolean();
  const quickEdit = useBoolean();
  const dropdown = useDropdown();
  const router = useRouter();
  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
          <Image
            alt={name}
            src={images.find((image) => !!image.is_featured)?.url}
            sx={{
              width: 64,
              height: 64,
              borderRadius: 2,
              mr: 2,
            }}
            variant="rounded"
            ratio="1/1"
          />

          <ListItemText
            disableTypography
            primary={
              <Link
                component={RouterLink}
                href={`${paths.dashboard.products.root}/${id}`}
                noWrap
                color="inherit"
                variant="subtitle2"
                sx={{ cursor: 'pointer' }}
              >
                {slice(pid || '', 50)}
              </Link>
            }
            secondary={
              <Box component="div" sx={{ typography: 'body2', color: 'text.disabled' }}>
                {slice(pid || '', 50)}
              </Box>
            }
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{slice(sku || '', 50)}</TableCell>

        <TableCell sx={{ typography: 'caption', color: 'text.secondary' }}>
          <LinearProgress
            value={quantity}
            variant="determinate"
            color={(quantity <= 0 && 'error') || 'success'}
            sx={{ mb: 1, height: 6, maxWidth: 80 }}
          />
          {!!quantity && quantity} {quantity <= 0 ? 'Out of Stock' : 'In Stock'}
        </TableCell>

        {/* <TableCell>
          <ListItemText
            primary={fCurrency(sale_price > 0 ? sale_price : regular_price)}
            secondary={
              sale_price > 0 ? (
                <Typography sx={{ textDecoration: 'line-through' }}>
                  {fCurrency(regular_price)}
                </Typography>
              ) : (
                <></>
              )
            }
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell> */}

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Label
            variant="soft"
            color={(condition && condition !== 'Used' && 'secondary') || 'primary'}
          >
            {condition || 'Used'}
          </Label>
        </TableCell>

        <TableCell>
          <ListItemText
            primary={format(new Date(updated_at), 'dd MMM yyyy')}
            secondary={format(new Date(updated_at), 'p')}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
              <EditRounded />
            </IconButton>
          </Tooltip>

          <IconButton color={dropdown.open ? 'inherit' : 'default'} onClick={dropdown.onOpen}>
            <MoreVertRounded />
          </IconButton>
        </TableCell>
      </TableRow>

      <ProductQuickEditForm
        currentProduct={row}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
        getProductList={getProductList}
        onDeleteRow={onDeleteRow}
      />

      <Dropdown
        open={dropdown.open}
        onClose={dropdown.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            router.push(`${paths.dashboard.products.root}/${id}`);
            dropdown.onClose();
          }}
        >
          <EditRounded />
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            dropdown.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <DeleteForeverRounded />
          Delete
        </MenuItem>
      </Dropdown>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
};

export default ProductDataTableRow;
