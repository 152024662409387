import Grid from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import SalesSummary from './sales-summary';
import ProductsSoldSummary from './products-sold-summary';
import TotalsOrdersSummary from './total-orders-summary';
import OverviewMonthlySales from './overview-monthly-sales';
import OverviewOrdersCount from './overview-orders-count';
import OverviewOrderSales from './overview-order-sales';
import OverviewTop10Products from './overview-top-10-products';
import OrderDataTable from '../orders/order-data-table';

const Overview = () => {
  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={4}>
        <ProductsSoldSummary />

        <TotalsOrdersSummary />

        <SalesSummary />

        <OverviewOrdersCount />

        <OverviewMonthlySales />

        <OverviewOrderSales />

        <Grid xs={12} md={8}>
          <OrderDataTable />
        </Grid>

        <OverviewTop10Products />
      </Grid>
    </>
  );
};

export default Overview;
