import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import ListItemText from '@mui/material/ListItemText';
// utils
import { fCurrency } from 'src/utils/format-number';
//
import Scrollbar from 'src/components/scrollbar';
import Image from 'src/components/image';

// ----------------------------------------------------------------------

export default function EcommerceTop10Products({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Scrollbar sx={{ maxHeight: 700 }}>
        <Stack spacing={3} sx={{ p: 3, minWidth: 360 }}>
          {list.map((product) => (
            <ProductItem key={product.id} product={product} />
          ))}
        </Stack>
      </Scrollbar>
    </Card>
  );
}

EcommerceTop10Products.propTypes = {
  list: PropTypes.array,
  subheader: PropTypes.string,
  title: PropTypes.string,
};

// ----------------------------------------------------------------------

function ProductItem({ product }) {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Image
        alt={product.name}
        src={''}
        sx={{ width: 65, height: 65, flexShrink: 0, borderRadius: 1 }}
      />

      <ListItemText
        primary={<Link sx={{ color: 'text.primary', typography: 'subtitle2' }}>{product.pid}</Link>}
        secondary={
          <>
            <Box component="span" sx={{ color: 'text.secondary' }}>
              {fCurrency(product.total_price)}
            </Box>
          </>
        }
        primaryTypographyProps={{
          noWrap: true,
        }}
        secondaryTypographyProps={{
          mt: 0.5,
        }}
      />

      <Box component="span" sx={{ color: 'text.secondary' }}>
        x{product.quantity}
      </Box>
    </Stack>
  );
}

ProductItem.propTypes = {
  product: PropTypes.object,
};
