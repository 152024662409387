import { Button, Card, IconButton, Table, TableBody, TableContainer, Tooltip } from '@mui/material';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  getComparator,
  useTable,
} from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import { useDebounce } from 'src/hooks/use-debounce';
import BrandService from 'src/services/brand-service';
import BrandDataTableToolbar from './brand-data-table-toolbar';
import BrandDataTableFiltersResult from './brand-data-table-filters-result';
import { DeleteForeverRounded } from '@mui/icons-material';
import Scrollbar from 'src/components/scrollbar/scrollbar';
import BrandDataTableRow from './brand-data-table-row';
import ConfirmDialog from 'src/components/confirm-dialog';

const defaultFilters = {
  name: '',
};

const BrandDataTable = () => {
  const table = useTable();
  const confirm = useBoolean();
  const [filters, setFilters] = useState(defaultFilters);
  const debouncedFilters = useDebounce(filters);
  // const [brands, setBrands] = useState([]);

  const { brands, brandLoading, setBrandQueryFilters, refreshBrandList } =
    BrandService.useGetBrandList();

  const { enqueueSnackbar } = useSnackbar();

  const applyFilter = ({ inputData = [], comparator }) => {
    const stabilizedThis = inputData.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);
    return inputData;
  };

  const dataFiltered = applyFilter({
    inputData: brands,
    comparator: getComparator(table.order, table.orderBy),
  });

  const canReset = !isEqual(defaultFilters, filters);
  const notFound = (dataFiltered.length <= 0 && canReset) || dataFiltered.length <= 0;

  const handleFilters = useCallback(
    (name, value) => {
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    []
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleDeleteRow = useCallback(
    async (id) => {
      try {
        await BrandService.deleteOne(id);
        enqueueSnackbar('Delete Successfully!', { variant: 'success' });
        table.setSelected([]);
        refreshBrandList?.onTrue();
      } catch (error) {
        enqueueSnackbar('Failed to delete!', { variant: 'error' });
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, enqueueSnackbar]
  );

  const handleDeleteRows = useCallback(async () => {
    try {
      await BrandService.deleteMany(table.selected);
      enqueueSnackbar('Delete Successfully!', { variant: 'success' });
      table.setSelected([]);
      refreshBrandList.onTrue();
    } catch (error) {
      enqueueSnackbar('Failed to delete!', { variant: 'error' });
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, enqueueSnackbar]);

  const columns = useMemo(
    () => [
      { id: 'name', label: 'Name' },
      { id: 'slug', label: 'Slug', width: 180 },
      { id: 'modified', label: 'Modified' },
      { id: '', width: 88 },
    ],
    []
  );

  useEffect(() => {
    let params = { search: debouncedFilters.name };
    setBrandQueryFilters?.(params);
    refreshBrandList?.onTrue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters]);

  return (
    <>
      <Card>
        <BrandDataTableToolbar filters={filters} onFilters={handleFilters} />

        {canReset && (
          <BrandDataTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={dataFiltered.length}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            numSelected={table.selected.length}
            rowCount={brands?.length || 0}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                brands.map((row) => row.id)
              )
            }
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <DeleteForeverRounded />
                </IconButton>
              </Tooltip>
            }
          />

          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={columns}
                rowCount={brands?.length || 0}
                numSelected={table.selected.length}
                onSort={table.onSort}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    brands.map((row) => row.id)
                  )
                }
              />
              <TableBody>
                {brandLoading
                  ? [...Array(table.rowsPerPage)].map((i, index) => <TableSkeleton key={index} />)
                  : dataFiltered
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row) => (
                      <BrandDataTableRow
                        key={`${row.slug}-${row.id}`}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                      // getBrandList={getBrandList}
                      />
                    ))}

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={brands?.length || 0}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
        />
      </Card>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
};

export default BrandDataTable;
