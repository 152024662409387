import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
import { api } from 'src/paths';
import axios from 'src/utils/axios';
import { replaceId, urlWithQueryString } from 'src/utils/string';

const useGetCategoryList = (queryFilters) => {
  const [categories, setCategories] = useState([]);
  const [categoriesTotal, setCategoriesTotal] = useState(0);
  const [categoryErrors, setCategoryErrors] = useState(null);
  const [categoryQueryFilters, setCategoryQueryFilters] = useState(queryFilters || {});
  const categoryLoading = useBoolean(false);
  const refreshCategoryList = useBoolean(true);

  const getCategoryList = useCallback(async () => {
    if (!refreshCategoryList.value) {
      return;
    }
    categoryLoading.onTrue();

    try {
      const res = await axios.get(urlWithQueryString(api.categories.list, categoryQueryFilters));
      setCategories(res?.data || []);
      setCategoriesTotal(res?.meta?.total || 0);
    } catch (error) {
      setCategoryErrors(error);
    }

    refreshCategoryList.onFalse();
    categoryLoading.onFalse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryQueryFilters, refreshCategoryList.value]);

  useEffect(() => {
    getCategoryList();
  }, [getCategoryList]);

  const memorizedValues = useMemo(
    () => ({
      categories,
      categoriesTotal,
      categoryLoading: categoryLoading.value,
      categoryErrors,
      refreshCategoryList,
      setCategoryQueryFilters,
    }),
    [categories, categoriesTotal, categoryLoading.value, categoryErrors, refreshCategoryList]
  );

  return memorizedValues;
};

const useGetCategoryDetail = (id) => {
  const [category, setCategory] = useState();
  const [categoryErrors, setCategoryErrors] = useState(null);
  const categoryLoading = useBoolean(false);
  const refreshCategoryDetail = useBoolean(true);

  const getCategoryDetail = useCallback(async () => {
    if (!refreshCategoryDetail.value) {
      return;
    }
    categoryLoading.onTrue();

    try {
      const res = await axios.get(api.categories.detail(id));
      setCategory(res?.data);
    } catch (error) {
      setCategoryErrors(error);
    }

    refreshCategoryDetail.onFalse();
    categoryLoading.onFalse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refreshCategoryDetail.value]);

  useEffect(() => {
    getCategoryDetail();
  }, [getCategoryDetail]);

  const memorizedValues = useMemo(
    () => ({
      category,
      categoryLoading: categoryLoading.value,
      categoryErrors,
      refreshCategoryDetail,
    }),
    [category, categoryLoading.value, categoryErrors, refreshCategoryDetail]
  );

  return memorizedValues;
};

const create = (data) => {
  return axios.post(api.categories.create, data);
};

const update = (id, data) => {
  return axios.put(api.categories.update(id), data);
};

const trashOne = (id) => {
  return axios.delete(replaceId(api.categories.trashOne, id));
};

const trashMany = (ids) => {
  return axios.post(api.categories.trashMany, { category_ids: ids });
};

const putOneToTrash = (id) => {
  return axios.delete(replaceId(api.categories.trashOne, id));
};

const putManyToTrash = (ids) => {
  return axios.post(api.categories.trashMany, { category_ids: ids });
};

const deleteOne = (id) => {
  return axios.delete(replaceId(api.categories.deleteMany, { category_ids: [id] }));
};

const deleteMany = (ids) => {
  return axios.post(api.categories.deleteMany, { category_ids: ids });
};

const restoreMany = (ids) => {
  return axios.post(api.categories.restoreMany, { category_ids: ids });
};

const useGetCategoryStatusCount = () => {
  const defaultStatusCount = {
    total: 0,
    draft_count: 0,
    published_count: 0,
    trashed_count: 0,
  };

  const [categoriesStatusCount, setCategoriesStatusCount] = useState(defaultStatusCount);
  const categoryStatusCountLoading = useBoolean(false);
  const refreshCategoryStatusCount = useBoolean(true);

  const getCategoryStatusCount = useCallback(async () => {
    if (!refreshCategoryStatusCount.value) {
      return;
    }

    categoryStatusCountLoading.onTrue();

    try {
      const res = await axios.get(api.categories.statusCount);
      setCategoriesStatusCount(res?.data || defaultStatusCount);
    } catch (error) {}

    refreshCategoryStatusCount.onFalse();
    categoryStatusCountLoading.onFalse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCategoryStatusCount.value]);

  useEffect(() => {
    getCategoryStatusCount();
  }, [getCategoryStatusCount]);

  const memorizedValues = useMemo(
    () => ({
      categoriesStatusCount,
      categoriesStatusCountLoading: categoryStatusCountLoading.value,
      refreshCategoryStatusCount,
    }),
    [categoriesStatusCount, categoryStatusCountLoading.value, refreshCategoryStatusCount]
  );

  return memorizedValues;
};

const importCategories = (categories) => {
  return axios.post(api.categories.importCategories, { categories });
};

const CategoryService = {
  useGetCategoryList,
  useGetCategoryStatusCount,
  useGetCategoryDetail,
  create,
  update,
  trashOne,
  trashMany,
  putOneToTrash,
  putManyToTrash,
  deleteOne,
  deleteMany,
  restoreMany,
  importCategories,
};

export default CategoryService;
