import { Card, Table, TableBody, TableContainer } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Scrollbar from 'src/components/scrollbar/scrollbar';
import { TableHeadCustom, useTable } from 'src/components/table';
import SerialNumberFormRow from './serial-number-form-row';
import ProductService from 'src/services/product-service';

const SerialNumberForm = ({ serialNumbers = [] }) => {
  const [products, setProducts] = useState([]);

  const getProductList = useCallback(async () => {
    try {
      const res = await ProductService.getList({ disabledPagination: true });
      setProducts(res.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getProductList();
  }, [getProductList]);

  const table = useTable();
  const columns = useMemo(
    () => [
      { id: 'product', label: 'Product', width: 200 },
      { id: 'serial_number', label: 'Serial Number', width: 220 },
      { id: 'quantity', label: 'Quantity', width: 100 },
      { id: 'note', label: 'Note', width: 100 },
      { id: '', label: '', width: 50 },
    ],
    []
  );

  return (
    <>
      <Card>
        <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
          <Scrollbar sx={{ maxHeight: '500px' }}>
            <Table size={table.dense ? 'small' : 'medium'}>
              <TableHeadCustom headLabel={columns} />
              <TableBody>
                {serialNumbers.map((row, index) => (
                  <SerialNumberFormRow
                    key={`new-serial-number-row-${index}`}
                    row={row}
                    products={products}
                  />
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </Card>
    </>
  );
};

export default SerialNumberForm;
