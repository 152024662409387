import Grid from '@mui/material/Unstable_Grid2/Grid2';
import React, { useCallback, useEffect, useState } from 'react';
import EcommerceTop10Products from './components/ecommerce-top-10-products';
import OverviewService from 'src/services/overview-service';

const OverviewTop10Products = () => {
  const [results, setResults] = useState([]);

  const getTop10ProductsSold = useCallback(async () => {
    try {
      const res = await OverviewService.getTop10ProductsSold();
      setResults(res.data);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTop10ProductsSold();
  }, [getTop10ProductsSold]);
  return (
    <>
      <Grid xs={12} md={4}>
        <EcommerceTop10Products title="Top 10 Products Sold" list={results} />
      </Grid>
    </>
  );
};

export default OverviewTop10Products;
