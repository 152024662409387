import { Container } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { PROJECT_NAME } from 'src/config-global';
import { paths } from 'src/paths';
import OrderEditForm from 'src/sections/orders/order-edit-form/order-edit-form';

const OrderCreate = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard: Order Create | {PROJECT_NAME}</title>
      </Helmet>

      <Container maxWidth={'xl'}>
        <CustomBreadcrumbs
          heading="Create New Order"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'Orders',
              href: paths.dashboard.orders.root,
            },
            { name: 'Create New Order' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <OrderEditForm />
      </Container>
    </>
  );
};

export default OrderCreate;
