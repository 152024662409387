import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { PROJECT_NAME } from 'src/config-global';
import { paths } from 'src/paths';
import CategoryEditForm from 'src/sections/categories/category-edit-form/category-edit-form';

const CategoryCreate = () => {
  const settings = useSettingsContext();

  return (
    <>
      <Helmet>
        <title>Dashboard: Create New Category | {PROJECT_NAME}</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Create a new category"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'Categories',
              href: paths.dashboard.categories.root,
            },
            { name: 'New category' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <CategoryEditForm />
      </Container>
    </>
  );
};

export default CategoryCreate;
