import { Add } from '@mui/icons-material';
import { Button, Card, Container } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import RouterLink from 'src/components/router-link';
import { useDebounce } from 'src/hooks/use-debounce';
import { paths } from 'src/paths';
import SerialNumberDataTable from 'src/sections/serial-numbers/serial-number-data-table/serial-number-data-table';
import SerialNumberService from 'src/services/serial-number-service';
import { fDate } from 'src/utils/format-time';

const defaultFilters = {
  serialNumber: '',
  status: [],
  startDate: null,
  endDate: null,
};

const StockInList = () => {
  const [serialNumbers, setSerialNumbers] = useState([]);

  const [filters, setFilters] = useState(defaultFilters);
  const debouncedFilters = useDebounce(filters);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const getSerialNumberList = useCallback(async () => {
    const res = await SerialNumberService.getList({
      page: page + 1,
      perPage: perPage,
      search: debouncedFilters.serialNumber,
      status: debouncedFilters.status,
      startDate: fDate(debouncedFilters.startDate),
      endDate: fDate(debouncedFilters.endDate),
    });
    setSerialNumbers(res.data);
    setTotal(res.meta.total);
  }, [page, perPage, debouncedFilters]);

  useEffect(() => {
    getSerialNumberList();
  }, [getSerialNumberList]);

  const handleFilters = useCallback((name, value) => {
    setPage(0);
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <>
      <Container maxWidth="lg">
        <CustomBreadcrumbs
          heading="Stock In"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Stock In', href: paths.dashboard.serialNumbers.root },
          ]}
          action={
            <Button
              component={RouterLink}
              href={paths.dashboard.serialNumbers.create}
              variant="contained"
              startIcon={<Add />}
            >
              Add
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <SerialNumberDataTable
            serialNumbers={serialNumbers}
            getSerialNumberList={getSerialNumberList}
            total={total}
            page={page}
            perPage={perPage}
            filters={filters}
            defaultFilters={defaultFilters}
            handleFilters={handleFilters}
            handleResetFilters={handleResetFilters}
            onChangePage={(event, page) => setPage(page)}
            onRowsPerPageChange={(event) => setPerPage(event.target.value)}
          />
        </Card>
      </Container>
    </>
  );
};

export default StockInList;
