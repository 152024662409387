import { Box, Button, Card, CardContent, Container, Link, Stack, Typography, alpha, styled, useTheme } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useScroll, m } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { MotionContainer, varFade } from "src/components/animate";
import Iconify from "src/components/iconify/iconify";
import Logo from "src/components/logo";
import RouterLink from "src/components/router-link";
import { useResponsive } from "src/hooks/use-responsive";
import { HEADER } from "src/layouts/config-layout";
import { paths } from "src/paths";
import { bgBlur, bgGradient, textGradient } from "src/theme/css";

const StyledRoot = styled('div')(({ theme }) => ({
  ...bgGradient({
    color: alpha(theme.palette.background.default, theme.palette.mode === 'light' ? 0.8 : 0.94),
    imgUrl: '/assets/background/overlay_6.jpg',
  }),
  width: '100%',
  height: '100vh',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    position: 'fixed',
  },
}));

const StyledWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    marginTop: HEADER.H_DESKTOP_OFFSET,
  },
}));

const StyledTextGradient = styled(m.h1)(({ theme }) => ({
  ...textGradient(
    `300deg, ${theme.palette.primary.dark} 0%, ${theme.palette.warning.dark} 25%, ${theme.palette.primary.dark} 50%, ${theme.palette.warning.dark} 75%, ${theme.palette.primary.dark} 100%`
  ),
  padding: 0,
  marginTop: 8,
  lineHeight: 1,
  marginBottom: 24,
  letterSpacing: 6,
  textAlign: 'center',
  backgroundSize: '400%',
  fontSize: `${64 / 16}rem`,
  fontFamily: "'Barlow', sans-serif",
  [theme.breakpoints.up('md')]: {
    fontSize: `${96 / 49}rem`,
  },
}));

const StyledEllipseTop = styled('div')(({ theme }) => ({
  top: -80,
  width: 480,
  right: -80,
  height: 480,
  borderRadius: '50%',
  position: 'absolute',
  filter: 'blur(100px)',
  WebkitFilter: 'blur(100px)',
  backgroundColor: alpha(theme.palette.primary.darker, 0.12),
}));

const StyledEllipseBottom = styled('div')(({ theme }) => ({
  height: 400,
  bottom: -200,
  left: '10%',
  right: '10%',
  borderRadius: '50%',
  position: 'absolute',
  filter: 'blur(100px)',
  WebkitFilter: 'blur(100px)',
  backgroundColor: alpha(theme.palette.primary.darker, 0.12),
}));

const StyledPolygon = styled('div')(({ opacity = 1, anchor = 'left', theme }) => ({
  ...bgBlur({
    opacity,
    color: theme.palette.background.default,
  }),
  zIndex: 9,
  bottom: 0,
  height: 80,
  width: '50%',
  position: 'absolute',
  clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
  ...(anchor === 'left' && {
    left: 0,
  }),
  ...(anchor === 'right' && {
    right: 0,
    transform: 'scaleX(-1)',
  }),
}));


const HomeHero = () => {
  const mdUp = useResponsive('up', 'md');

  const theme = useTheme();

  const heroRef = useRef(null);

  const { scrollY } = useScroll();

  const [percent, setPercent] = useState(0);

  const isLight = theme.palette.mode === 'light';

  const getScroll = useCallback(() => {
    let heroHeight = 0;

    if (heroRef.current) {
      heroHeight = heroRef.current.offsetHeight;
    }

    scrollY.on('change', (scrollHeight) => {
      const scrollPercent = (scrollHeight * 100) / heroHeight;

      setPercent(Math.floor(scrollPercent));
    });
  }, [scrollY]);

  const transition = {
    repeatType: 'loop',
    ease: 'linear',
    duration: 10,
    repeat: Infinity,
  };

  const opacity = 1 - percent / 100;

  const hide = percent > 120;

  useEffect(() => {
    getScroll();
  }, [getScroll]);

  const renderDescription = (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: 1,
        mx: 'auto',
        maxWidth: 480,
        opacity: opacity > 0 ? opacity : 0,
        mt: {
          md: `-${HEADER.H_DESKTOP + percent * 2.5}px`,
        },
      }}
    >
      <m.div variants={varFade().in}>
        {/* <Typography
          variant="h2"
          sx={{
            textAlign: 'center',
          }}
        >
          Start a <br />
          New Project with
        </Typography> */}
        <Logo noText sx={{ width: 60 }} />

      </m.div>

      <m.div variants={varFade().in}>
        <StyledTextGradient
          animate={{ backgroundPosition: '200% center' }}
          transition={{
            repeatType: 'reverse',
            ease: 'linear',
            duration: 20,
            repeat: Infinity,
          }}
        >
          IT HARDWARE BROKER
        </StyledTextGradient>

        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mt: -3, mb: 3 }}>
          <Typography variant="body1">
            THE TECHNOLOGY SOLUTIONS COMPANY
          </Typography>
          <Typography variant="body1">
            Pty Ltd
          </Typography>
        </Stack>
      </m.div>

      <m.div variants={varFade().in}>
        <Stack spacing={1.5} direction={{ xs: 'column-reverse', sm: 'row' }} sx={{ mb: 5 }}>
          <Stack alignItems="center" spacing={2}>
            <Button
              component={RouterLink}
              href={paths.dashboard.root}
              color="inherit"
              size="large"
              variant="contained"
              startIcon={<Iconify icon="mdi:cart" width={24} />}
            >
              Shop now
            </Button>
          </Stack>
        </Stack>
      </m.div>
    </Stack>
  );

  // const renderSlides = (
  //   <Stack
  //     direction="row"
  //     alignItems="center"
  //     sx={{
  //       height: '100%',
  //       // position: 'absolute',
  //       // opacity: opacity > 0 ? opacity : 0,
  //       // transform: `skew(${-16 - percent / 24}deg, ${4 - percent / 16}deg)`,
  //     }}
  //   >

  //     <Box
  //       component={m.img}
  //       animate={{ y: ['100%', '0%'] }}
  //       // transition={transition}
  //       alt={isLight ? 'light_2' : 'dark_2'}
  //       src="/assets/background/home-hero.gif"
  //       sx={{}}
  //     />
  //     {/* <Stack
  //       component={m.div}
  //       variants={varFade().in}
  //       sx={{ width: 720, position: 'relative', mt: 10 }}
  //     >
  //       <Box
  //         component={m.img}
  //         animate={{ y: ['100%', '0%'] }}
  //         // transition={transition}
  //         alt={isLight ? 'light_2' : 'dark_2'}
  //         src="/assets/background/home-hero.gif"
  //         sx={{ position: 'absolute', mt: -5 }}
  //       />
  //     </Stack> */}
  //   </Stack>
  // );

  const renderSlides = (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: 1,
        mx: 'auto',
        maxWidth: 480,
        opacity: opacity > 0 ? opacity : 0,
        mt: {
          md: `-${HEADER.H_DESKTOP + percent * 2.5}px`,
        },
      }}
    >
      <Box
        component={m.img}
        animate={{ y: ['100%', '0%'] }}
        // transition={transition}
        alt={isLight ? 'light_2' : 'dark_2'}
        src="/assets/background/home-hero.gif"
        sx={{ width: 700 }}
      />
      {/* <Stack
        component={m.div}
        variants={varFade().in}
        sx={{ width: 720, position: 'relative', mt: 10 }}
      >
        <Box
          component={m.img}
          animate={{ y: ['100%', '0%'] }}
          // transition={transition}
          alt={isLight ? 'light_2' : 'dark_2'}
          src="/assets/background/home-hero.gif"
          sx={{ position: 'absolute', mt: -5 }}
        />
      </Stack> */}
    </Stack>
  );

  const renderPolygons = (
    <>
      <StyledPolygon />
      <StyledPolygon anchor="right" opacity={0.48} />
      <StyledPolygon anchor="right" opacity={0.48} sx={{ height: 48, zIndex: 10 }} />
      <StyledPolygon anchor="right" sx={{ zIndex: 11, height: 24 }} />
    </>
  );

  const renderEllipses = (
    <>
      {mdUp && <StyledEllipseTop />}
      <StyledEllipseBottom />
    </>
  );

  return <>
    <StyledRoot
      ref={heroRef}
      sx={{
        ...(hide && {
          opacity: 0,
        }),
      }}
    >
      <StyledWrapper>
        <Container component={MotionContainer} sx={{ height: 1 }}>
          <Grid container columnSpacing={{ md: 10 }} sx={{ height: 1 }}>
            <Grid xs={12} md={7}>
              {renderDescription}
            </Grid>

            {mdUp && <Grid md={5}>{renderSlides}</Grid>}
          </Grid>
        </Container>

        {renderEllipses}
      </StyledWrapper>
    </StyledRoot>

    {mdUp && renderPolygons}

    <Box sx={{ height: { md: '100vh' } }} />
  </>
}

export default HomeHero
