import { DeleteForeverRounded, EditRounded } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  Link,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import Dropdown from 'src/components/dropdown';
import useDropdown from 'src/components/dropdown/use-dropdown';
import Iconify from 'src/components/iconify/iconify';
import Image from 'src/components/image/image';
import Label from 'src/components/label/label';
import RouterLink from 'src/components/router-link';
import { useRouter } from 'src/hooks/routes';
import { useBoolean } from 'src/hooks/use-boolean';
import { paths } from 'src/paths';
import { fCurrency } from 'src/utils/format-number';
import OrderDetailItems from '../order-view/order-detail-items';
import ConfirmDialog from 'src/components/confirm-dialog';

const OrderDataTableRow = ({ row, selected, onSelectRow, onDeleteRow, getOrderList }) => {
  const {
    id,
    created_at,
    customer_email,
    customer_name,
    customer_phone,
    discount_price,
    note,
    order_billing_address,
    order_code,
    order_items,
    order_shipping_address,
    shipping_method,
    shipping_name,
    shipping_price,
    shipping_tracking_number,
    status,
    status_color,
    status_name,
    subtotal_price,
    order_items_count,
    total_price,
    updated_at,
  } = row;

  const confirm = useBoolean();
  const quickEdit = useBoolean();
  const dropdown = useDropdown();
  const collapse = useBoolean();
  const router = useRouter();

  const renderPrimary = (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell>
        <Link component={RouterLink} href={`${paths.dashboard.orders.root}/${id}`}>
          #{order_code}
        </Link>
      </TableCell>

      <TableCell>
        <ListItemText
          primary={order_shipping_address ? order_shipping_address.name : customer_name}
          secondary={order_shipping_address ? order_shipping_address.phone : customer_phone}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
        />
      </TableCell>

      <TableCell>
        {shipping_method === 'delivery' ? (
          <ListItemText
            primary={shipping_name}
            secondary={shipping_tracking_number}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        ) : (
          'Pick up'
        )}
      </TableCell>

      <TableCell align="center">{order_items_count}</TableCell>

      <TableCell> {fCurrency(total_price)} </TableCell>

      <TableCell>
        <Label variant="soft" color={status_color}>
          {status_name}
        </Label>
      </TableCell>

      <TableCell>
        <ListItemText
          primary={format(new Date(created_at), 'dd MMM yyyy')}
          secondary={format(new Date(created_at), 'p')}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>

      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <IconButton
          color={collapse.value ? 'inherit' : 'default'}
          onClick={collapse.onToggle}
          sx={{
            ...(collapse.value && {
              bgcolor: 'action.hover',
            }),
          }}
        >
          <Iconify icon="eva:arrow-ios-downward-fill" />
        </IconButton>

        <IconButton color={dropdown.open ? 'inherit' : 'default'} onClick={dropdown.onOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  const renderSecondary = (
    <TableRow>
      <TableCell sx={{ p: 0, border: 'none' }} colSpan={9}>
        <Collapse
          in={collapse.value}
          timeout="auto"
          unmountOnExit
          sx={{ bgcolor: 'background.neutral' }}
        >
          <Stack component={Paper} sx={{ m: 1.5 }}>
            {order_items.map((item) => (
              <Stack
                key={`${item.product_pid}-${item.id}`}
                direction="row"
                alignItems="center"
                sx={{
                  p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
                  '&:not(:last-of-type)': {
                    borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}`,
                  },
                }}
              >
                <Image src={''} variant="rounded" sx={{ width: 48, height: 48, mr: 2 }} />

                <ListItemText
                  primary={item.product ? item.product.name : item.product_pid}
                  secondary={item.product ? item.product.sku : item.product_sku}
                  primaryTypographyProps={{
                    typography: 'body2',
                  }}
                  secondaryTypographyProps={{
                    component: 'span',
                    color: 'text.disabled',
                    mt: 0.5,
                  }}
                />

                <Box>x{item.quantity}</Box>

                <Box sx={{ width: 100, textAlign: 'right', typography: 'body2' }}>
                  {fCurrency(item.unit_price)}
                </Box>

                <Box sx={{ width: 110, textAlign: 'right' }}>
                  <ListItemText
                    primary={fCurrency(item.total_price)}
                    secondary={
                      item.discount_price > 0 ? (
                        <Typography
                          variant="body2"
                          color="primary"
                          sx={{ textDecoration: 'line-through' }}
                        >
                          {fCurrency(item.discount_price)}
                        </Typography>
                      ) : (
                        <></>
                      )
                    }
                    primaryTypographyProps={{ typography: 'subtitle2', noWrap: true }}
                    secondaryTypographyProps={{
                      mt: 0.5,
                    }}
                  />
                </Box>
              </Stack>
            ))}
            <Stack direction="row" justifyContent="end">
              <Table size="small" sx={{ width: 300, textAlign: 'right' }}>
                <TableBody>
                  <TableRow key={'sub-total-price'}>
                    <TableCell sx={{ textAlign: 'right' }}>Sub Total:</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{fCurrency(subtotal_price)}</TableCell>
                  </TableRow>
                  <TableRow key={'shipping-price'}>
                    <TableCell sx={{ textAlign: 'right' }}>Shipping:</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{fCurrency(shipping_price)}</TableCell>
                  </TableRow>
                  <TableRow key={'discount-price'}>
                    <TableCell sx={{ textAlign: 'right' }}>Discount:</TableCell>
                    <TableCell sx={{ textAlign: 'right', color: 'error.main' }}>
                      {!!discount_price && '-'}
                      {fCurrency(discount_price)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={'total-price'}>
                    <TableCell sx={{ textAlign: 'right' }}>Total:</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{fCurrency(total_price)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {renderPrimary}

      {renderSecondary}

      <Dropdown
        open={dropdown.open}
        onClose={dropdown.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            router.push(`${paths.dashboard.orders.root}/${id}/edit`);
            dropdown.onClose();
          }}
        >
          <EditRounded />
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            dropdown.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <DeleteForeverRounded />
          Delete
        </MenuItem>
      </Dropdown>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
};

export default OrderDataTableRow;
