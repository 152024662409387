import { Add } from '@mui/icons-material';
import { Button, Card, Container, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import RouterLink from 'src/components/router-link';
import { PROJECT_NAME } from 'src/config-global';
import { useBoolean } from 'src/hooks/use-boolean';
import { paths } from 'src/paths';
import { CategoryDataTable } from 'src/sections/categories/category-data-table';
import CategoryDataTableImportExcel from 'src/sections/categories/category-data-table/category-data-table-import-excel';

const CategoryList = () => {
  const openImportDialog = useBoolean();

  return (
    <>
      <Helmet>
        <title>Dashboard: Category List - {PROJECT_NAME}</title>
      </Helmet>

      <Container maxWidth="lg">
        <CustomBreadcrumbs
          heading="List"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Categories', href: paths.dashboard.categories.root },
            { name: 'List' },
          ]}
          action={
            <Stack direction="row" spacing={2}>
              {/* <Button
                variant="outlined"
                startIcon={<Iconify icon="solar:import-bold" />}
                onClick={openImportDialog.onTrue}
              >
                Import
              </Button> */}
              <Button
                component={RouterLink}
                href={paths.dashboard.categories.create}
                variant="contained"
                startIcon={<Add />}
              >
                New Category
              </Button>
            </Stack>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <CategoryDataTable />
        </Card>
      </Container>

      <CategoryDataTableImportExcel
        open={openImportDialog.value}
        onClose={() => {
          openImportDialog.onFalse();
        }}
      />
    </>
  );
};

CategoryList.proTypes = {
  children: PropTypes.node,
};

export default CategoryList;
