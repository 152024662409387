import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useState } from 'react';
import Editor from 'src/components/editor/editor';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import ProductService from 'src/services/product-service';

const ProductQuickEditForm = ({ currentProduct, open, onClose, getProductList, onDeleteRow }) => {
  const {
    id,
    name,
    description,
    pid,
    sku,
    status,
    quantity,
    regular_price,
    sale_price,
    allow_back_orders,
    published_at,
    deleted_at,
  } = currentProduct;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      name: name || '',
      description: description || '',
      pid: pid || '',
      sku: sku || '',
      quantity: quantity || 0,
      regular_price: regular_price || 0,
      sale_price: sale_price || 0,
      published: !!published_at,
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required(),
      regular_price: Yup.number().min(0).required(),
      sale_price: Yup.number().min(0),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);

      try {
        await ProductService.update(id, values);
        enqueueSnackbar('Update Successfully!', { variant: 'success' });
        getProductList();
        onClose();
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Failed to update!', { variant: 'error' });
      }

      setIsSubmitting(false);
    },
  });

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={onClose}
        PaperProps={{
          py: 2,
        }}
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <DialogTitle>Quick Update</DialogTitle>

          <DialogContent>
            <Stack sx={{ my: 2 }} spacing={2}>
              <Grid container spacing={2}>
                {/* <Grid sm={12} md={6}>
                  <FormGroup>
                    <TextField
                      error={!!(formik.touched.name && formik.errors.name)}
                      fullWidth
                      helperText={formik.touched.name && formik.errors.name}
                      label="Name"
                      name="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.name}
                    />
                  </FormGroup>
                </Grid> */}

                <Grid sm={12}>
                  <FormGroup>
                    <TextField
                      error={!!(formik.touched.pid && formik.errors.pid)}
                      fullWidth
                      helperText={formik.touched.pid && formik.errors.pid}
                      label="PID"
                      name="pid"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.pid}
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <FormGroup>
                <Editor
                  id="product-description"
                  simple
                  error={!!(formik.touched.description && formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  name="description"
                  onChange={(value) => formik.setFieldValue('description', value)}
                  value={formik.values.description}
                />
              </FormGroup>

              <Grid container spacing={2}>
                <Grid sm={12} md={6}>
                  <FormGroup>
                    <TextField
                      error={!!(formik.touched.quantity && formik.errors.quantity)}
                      fullWidth
                      helperText={formik.touched.quantity && formik.errors.quantity}
                      label="Quantity"
                      name="quantity"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="number"
                      value={formik.values.quantity}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormGroup>
                </Grid>

                <Grid sm={12} md={6}>
                  <FormGroup>
                    <TextField
                      error={!!(formik.touched.sku && formik.errors.sku)}
                      fullWidth
                      helperText={formik.touched.sku && formik.errors.sku}
                      label="SKU"
                      name="sku"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.sku}
                    />
                  </FormGroup>
                </Grid>
                <Grid sm={12} md={6}>
                  <FormGroup>
                    <TextField
                      error={!!(formik.touched.regular_price && formik.errors.regular_price)}
                      fullWidth
                      helperText={formik.touched.regular_price && formik.errors.regular_price}
                      label="Regular Price"
                      name="regular_price"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="number"
                      value={formik.values.regular_price}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box component="span" sx={{ color: 'text.disabled' }}>
                              $
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid sm={12} md={6}>
                  <FormGroup>
                    <TextField
                      error={!!(formik.touched.sale_price && formik.errors.sale_price)}
                      fullWidth
                      helperText={formik.touched.sale_price && formik.errors.sale_price}
                      label="Sale Price"
                      name="sale_price"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="number"
                      value={formik.values.sale_price}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box component="span" sx={{ color: 'text.disabled' }}>
                              $
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormGroup>
                </Grid>

                <Grid sm={12} md={6}>
                  <FormControlLabel
                    control={<Switch />}
                    label="Publish"
                    labelPlacement="start"
                    checked={formik.values.published}
                    onChange={(event) => formik.setFieldValue('published', event.target.checked)}
                    sx={{ flexGrow: 1 }}
                  />
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>

          <DialogActions sx={{ px: 3 }}>
            <Stack direction="row" spacing={2} width="100%" justifyContent="space-between">
              {!deleted_at && (
                <Button variant="contained" color="error" onClick={onDeleteRow}>
                  Delete
                </Button>
              )}
              <Stack direction="row" spacing={2}>
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ProductQuickEditForm;

ProductQuickEditForm.propsType = {
  currentProduct: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  getProductList: PropTypes.func,
  onDeleteRow: PropTypes.func,
};
