import { Check, Close, DeleteForeverRounded, EditRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import Label from 'src/components/label';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  getComparator,
  useTable,
} from 'src/components/table';
import { Upload } from 'src/components/upload';
import { useBoolean } from 'src/hooks/use-boolean';
import { slice } from 'src/utils/string';
import * as XLSX from 'xlsx';
import * as Yup from 'yup';
import ProductDataTableFiltersResult from './product-data-table-filter-results';
import ProductTableToolbar from './product-data-table-toolbar';
import ConfirmDialog from 'src/components/confirm-dialog';
import ProductService from 'src/services/product-service';
import { enqueueSnackbar } from 'notistack';

const defaultFilters = {
  searchTerm: '',
};

const TABLE_COLUMNS = [
  { id: 'pid', label: 'Product', width: 200 },
  { id: 'subcategory', label: 'Category', width: 200 },
  { id: 'sku', label: 'SKU', width: 200 },
  { id: 'quantity', label: 'Stock', width: 120 },
  { id: 'condition', label: 'Condition', width: 100 },
  { id: '', width: 88 },
];

const ProductDataTableImportExcel = ({ open, onClose }) => {
  const [products, setProducts] = useState([]);
  const isUploading = useBoolean();
  const table = useTable({ defaultRowsPerPage: 10 });
  const confirm = useBoolean();

  const [filters, setFilters] = useState(defaultFilters);

  const applyFilter = ({ inputData, comparator, filters }) => {
    const { searchTerm } = filters;

    const stabilizedThis = inputData.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (searchTerm) {
      inputData = inputData.filter((product) => {
        return product.pid.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
      });
    }

    return inputData;
  };

  const dataFiltered = applyFilter({
    inputData: products,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const canReset = !isEqual(defaultFilters, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleFileUpload = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      // Assuming you want to read the first sheet (index 0) in the Excel file
      const sheetNames = workbook.SheetNames;

      let importedData = [];

      sheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];

        // Parse the sheet data into a JSON object
        const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        if (!excelData.length) {
          return;
        }

        const columns = excelData[0];
        excelData.shift();

        const filteredData = excelData
          .filter((row) => row && !!row.length)
          .map((row, index) => {
            let item = {
              id: `${sheetName}-${index}`,
              category: sheetName,
            };
            columns.forEach((column, columnIndex) => {
              const col = column.trim().toLowerCase();
              switch (col) {
                case 'category':
                case 'categories':
                  item = { ...item, subcategory: String(row[columnIndex] || '').trim() };
                  break;

                case 'quantity':
                case 'qty':
                  item = { ...item, quantity: parseInt(row[columnIndex] || 0) };
                  break;

                case 'ebay':
                case 'sku':
                  item = { ...item, sku: String(row[columnIndex] || '').trim() };
                  break;

                case 'condition':
                  item = { ...item, condition: String(row[columnIndex] || '').trim() };
                  break;

                case 'pid':
                case 'part number':
                  item = { ...item, pid: String(row[columnIndex] || '').trim() };
                  break;

                default:
                  item = { ...item, [col]: String(row[columnIndex] || '').trim() };
                  break;
              }
            });
            return item;
          });

        importedData = [...importedData, ...filteredData];
      });

      console.log(importedData);
      // Now you have the Excel data in excelData
      setProducts(importedData);
    };

    reader.readAsBinaryString(file);
  }, []);

  const handleDeleteRow = useCallback(
    (id) => {
      setProducts(products.filter((row) => row.id !== id));
      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [products, table, dataInPage]
  );

  const handleDeleteRows = useCallback(() => {
    setProducts(products.filter((row) => !table.selected.includes(row.id)));
    table.setSelected([]);

    table.onUpdatePageDeleteRows({
      totalRows: products.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [table, dataFiltered, dataInPage, products]);

  const handleChangeProducts = useCallback(
    (id, values) => {
      const updatedData = [...products];
      const index = updatedData.findIndex((row) => row.id === id);

      if (index !== -1) {
        updatedData[index] = { ...updatedData[index], ...values };
        setProducts(updatedData);
      }
    },
    [products]
  );

  const handleImportProducts = async () => {
    isUploading.onTrue();
    try {
      await ProductService.importProducts(products);
      enqueueSnackbar('Imported Successfully! Please reload page after a few munites', {
        variant: 'success',
      });
      onClose();
    } catch (error) {
      enqueueSnackbar('Failed to import!', { variant: 'error' });
      console.error(error);
    }

    isUploading.onFalse();
  };

  const renderTable = (
    <>
      <ProductTableToolbar filters={filters} onFilters={handleFilters} />

      {canReset && (
        <ProductDataTableFiltersResult
          filters={filters}
          onFilters={handleFilters}
          //
          onResetFilters={handleResetFilters}
          //
          results={dataFiltered.length}
          sx={{ p: 2.5, pt: 0 }}
        />
      )}

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <TableSelectedAction
          numSelected={table.selected.length}
          rowCount={dataFiltered.length}
          onSelectAllRows={(checked) =>
            table.onSelectAllRows(
              checked,
              products.map((row, index) => index)
            )
          }
          action={
            <Tooltip title="Delete">
              <IconButton color="primary" onClick={handleDeleteRows}>
                <DeleteForeverRounded />
              </IconButton>
            </Tooltip>
          }
        />

        <Table size={table.dense ? 'small' : 'medium'} stickyHeader>
          <TableHeadCustom
            order={table.order}
            orderBy={table.orderBy}
            headLabel={TABLE_COLUMNS}
            rowCount={dataFiltered.length}
            numSelected={table.selected.length}
            onSort={table.onSort}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                products.map((row, index) => index)
              )
            }
          />
          <TableBody>
            {dataInPage.map((row) => (
              <ProductTableRow
                key={`${row.pid}-${row.id}`}
                row={row}
                selected={table.selected.includes(row.id)}
                onSelectRow={() => table.onSelectRow(row.id)}
                onUpdateRow={handleChangeProducts}
                onDeleteRow={() => handleDeleteRow(row.id)}
              />
            ))}
            <TableNoData notFound={notFound} />
          </TableBody>
        </Table>
      </TableContainer>

      <TablePaginationCustom
        count={dataFiltered.length}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        //
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </>
  );

  return (
    <>
      <Dialog
        maxWidth={!!products.length ? 'xl' : 'md'}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { py: 2 },
        }}
        fullWidth
      >
        <DialogTitle>Import Products</DialogTitle>

        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {!products.length ? <Upload onDrop={handleFileUpload} /> : renderTable}
          </Box>
        </DialogContent>

        <DialogActions sx={{ px: 3 }}>
          <Stack direction="row" spacing={2} width="100%" justifyContent="space-between">
            <Button variant="contained" color="info" onClick={() => setProducts([])}>
              Reset
            </Button>
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={onClose}>
                Close
              </Button>

              <LoadingButton
                variant="contained"
                loading={isUploading.value}
                onClick={confirm.onTrue}
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to import <strong> {products.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handleImportProducts();
              confirm.onFalse();
            }}
          >
            Import
          </Button>
        }
      />
    </>
  );
};

export default ProductDataTableImportExcel;

const ProductTableRow = ({ row, selected, onSelectRow, onUpdateRow, onDeleteRow }) => {
  const { id, pid, category, subcategory, quantity = 1, sku, condition } = row;

  const quickEdit = useBoolean();

  const formik = useFormik({
    initialValues: {
      pid: pid || '',
      sku: sku || '',
      subcategory: subcategory || '',
      quantity: quantity || 0,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      pid: Yup.string().required(),
      sku: Yup.string().optional(),
      quantity: Yup.number().min(0).required(),
    }),
  });

  const handleChangeRow = () => {
    onUpdateRow(id, formik.values);
    quickEdit.onFalse();
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {!quickEdit.value ? (
            <ListItemText
              disableTypography
              primary={
                <Box component="div" sx={{ typography: 'subtitle1' }}>
                  {pid}
                </Box>
              }
              secondary={
                <Box component="div" sx={{ typography: 'body2', color: 'text.disabled' }}>
                  {slice(category || '', 50)}
                </Box>
              }
            />
          ) : (
            <TextField
              fullWidth
              error={formik.touched.pid && formik.errors.pid}
              helperText={formik.touched.pid && formik.errors.pid}
              label="PID"
              name="pid"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.pid}
              type="text"
              InputLabelProps={{ shrink: true }}
            />
          )}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {!quickEdit.value ? (
            <ListItemText
              disableTypography
              primary={
                <Box component="div" sx={{ typography: 'subtitle1' }}>
                  {slice(subcategory || '', 50)}
                </Box>
              }
              secondary={
                <Box component="div" sx={{ typography: 'body2', color: 'text.disabled' }}>
                  {slice(category || '', 50)}
                </Box>
              }
            />
          ) : (
            <TextField
              fullWidth
              error={formik.touched.subcategory && formik.errors.subcategory}
              helperText={formik.touched.subcategory && formik.errors.subcategory}
              label="Subcategory"
              name="subcategory"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.subcategory}
              type="text"
              InputLabelProps={{ shrink: true }}
            />
          )}
        </TableCell>

        <TableCell>
          {!quickEdit.value ? (
            slice(sku || '')
          ) : (
            <TextField
              fullWidth
              error={formik.touched.sku && formik.errors.sku}
              helperText={formik.touched.sku && formik.errors.sku}
              label="SKU"
              name="sku"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.sku}
              type="text"
              InputLabelProps={{ shrink: true }}
            />
          )}
        </TableCell>
        <TableCell>
          {!quickEdit.value ? (
            <>
              <LinearProgress
                value={quantity}
                variant="determinate"
                color={(!!quantity && 'success') || 'error'}
                sx={{ mb: 1, height: 6, maxWidth: 80 }}
              />
              {!!quantity && quantity} {(!!quantity && 'In Stock') || 'Out of Stock'}
            </>
          ) : (
            <TextField
              fullWidth
              error={formik.touched.quantity && formik.errors.quantity}
              helperText={formik.touched.quantity && formik.errors.quantity}
              label="quantity"
              name="quantity"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.quantity}
              type="number"
              InputLabelProps={{ shrink: true }}
            />
          )}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Label variant="soft" color={condition === 'Used' ? 'secondary' : 'primary'}>
            {condition}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {!quickEdit.value ? (
            <>
              <Tooltip title="Quick Edit" placement="top" arrow>
                <IconButton
                  color={quickEdit.value ? 'inherit' : 'default'}
                  onClick={quickEdit.onTrue}
                >
                  <EditRounded />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" placement="top" arrow>
                <IconButton color="error" onClick={onDeleteRow}>
                  <DeleteForeverRounded />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Confirm" placement="top" arrow>
                <IconButton
                  color={quickEdit.value ? 'inherit' : 'default'}
                  onClick={handleChangeRow}
                >
                  <Check size="small" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" placement="top" arrow>
                <IconButton color="error" onClick={quickEdit.onFalse}>
                  <Close size="small" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
