const ROOT = '/api/v1';

const api = {
  auth: {
    me: `${ROOT}/auth/me`,
    login: `${ROOT}/auth/login`,
    register: `${ROOT}/auth/register`,
  },
  categories: {
    list: `${ROOT}/categories`,
    detail: (id) => `${ROOT}/categories/${id}`,
    create: `${ROOT}/categories`,
    update: (id) => `${ROOT}/categories/${id}`,
    trashOne: `${ROOT}/categories/{{id}}`,
    trashMany: `${ROOT}/categories/delete-many`,
    deleteMany: `${ROOT}/categories/permanent-delete-many`,
    restoreMany: `${ROOT}/categories/restore-many`,
    statusCount: `${ROOT}/categories/status-count`,
    importCategories: `${ROOT}/categories/import`,
  },
  brands: {
    list: `${ROOT}/brands`,
    detail: (id) => `${ROOT}/brands/${id}`,
    create: `${ROOT}/brands`,
    update: (id) => `${ROOT}/brands/${id}`,
    deleteOne: (id) => `${ROOT}/brands/${id}`,
    deleteMany: `${ROOT}/brands/delete-many`,
  },
  products: {
    list: `${ROOT}/products`,
    detail: (id) => `${ROOT}/products/${id}`,
    create: `${ROOT}/products`,
    update: (id) => `${ROOT}/products/${id}`,
    trashOne: (id) => `${ROOT}/products/${id}`,
    trashMany: `${ROOT}/products/delete-many`,
    deleteMany: `${ROOT}/products/permanent-delete-many`,
    statusCount: `${ROOT}/products/status-count`,
    importProducts: `${ROOT}/products/import`,
  },
  serialNumbers: {
    status: `${ROOT}/product-serial-numbers/status`,
    list: `${ROOT}/product-serial-numbers`,
    detail: (id) => `${ROOT}/product-serial-numbers/${id}`,
    create: `${ROOT}/product-serial-numbers`,
    createMany: `${ROOT}/product-serial-numbers/create-many`,
    update: (id) => `${ROOT}/product-serial-numbers/${id}`,
    trashOne: (id) => `${ROOT}/product-serial-numbers/${id}`,
    trashMany: `${ROOT}/product-serial-numbers/delete-many`,
    deleteMany: `${ROOT}/product-serial-numbers/permanent-delete-many`,
    statusCount: `${ROOT}/product-serial-numbers/status-count`,
  },
  orders: {
    list: `${ROOT}/orders`,
    detail: (id) => `${ROOT}/orders/${id}`,
    create: `${ROOT}/orders`,
    update: (id) => `${ROOT}/orders/${id}`,
    trashOne: (id) => `${ROOT}/orders/${id}`,
    trashMany: `${ROOT}/orders/delete-many`,
    deleteMany: `${ROOT}/orders/permanent-delete-many`,
    status: `${ROOT}/orders/status`,
    updateStatus: (id) => `${ROOT}/orders/${id}/status`,
  },
  images: {
    detail: (id) => `${ROOT}/images/${id}`,
    uploadOne: `${ROOT}/images`,
    uploadMany: `${ROOT}/images/upload-many`,
  },
  overview: {
    totalSales: `${ROOT}/overview/total-sales`,
    totalProductsSold: `${ROOT}/overview/total-products-sold`,
    totalOrders: `${ROOT}/overview/total-orders`,
    totalOrdersGroupByStatus: `${ROOT}/overview/total-orders-group-by-status`,
    monthlySales: `${ROOT}/overview/monthly-sales`,
    top10ProductsSold: `${ROOT}/overview/top-10-products-sold`,
  },
  users: {
    list: `${ROOT}/users`,
    detail: (id) => `${ROOT}/users/${id}`,
    create: `${ROOT}/users`,
    update: (id) => `${ROOT}/users/${id}`,
    deleteOne: (id) => `${ROOT}/users/${id}`,
    deleteMany: `${ROOT}/users/delete-many`,
  },
};

export default api;
