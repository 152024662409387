import { Box, Button, Chip, Paper, Stack } from '@mui/material';

import PropTypes from 'prop-types';
import Iconify from 'src/components/iconify';
import { fDate } from 'src/utils/format-time';

const SerialNumberDataTableFiltersResult = ({
  filters,
  onFilters,
  //
  onResetFilters,
  //
  results,
  ...other
}) => {
  const handleRemoveSerialNumber = () => {
    onFilters('serialNumber', '');
  };

  const handleRemoveStatus = (inputValue) => {
    const newValue = filters.status.filter((item) => item !== inputValue);
    onFilters('status', newValue);
  };

  const handleRemoveStartDate = () => {
    onFilters('startDate', null);
  };

  const handleRemoveEndDate = () => {
    onFilters('endDate', null);
  };

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
          results found
        </Box>
      </Box>

      <Stack flexGrow={1} spacing={1} direction="row" flexWrap="wrap" alignItems="center">
        {!!filters.status.length && (
          <Block label="Status:">
            {filters.status.map((item) => (
              <Chip
                key={item}
                label={item}
                size="small"
                onDelete={() => handleRemoveStatus(item)}
              />
            ))}
          </Block>
        )}

        {filters.serialNumber && (
          <Block label="Serial Number:">
            <Chip
              size="small"
              label={filters.serialNumber}
              onDelete={handleRemoveSerialNumber}
            ></Chip>
          </Block>
        )}

        {filters.startDate && (
          <Block label="Start Date:">
            <Chip
              size="small"
              label={fDate(filters.startDate, 'dd MMM yy')}
              onDelete={handleRemoveStartDate}
            />
          </Block>
        )}

        {filters.endDate && (
          <Block label="End Date:">
            <Chip
              size="small"
              label={fDate(filters.endDate, 'dd MMM yy')}
              onDelete={handleRemoveEndDate}
            />
          </Block>
        )}

        <Button
          color="error"
          onClick={onResetFilters}
          startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        >
          Clear
        </Button>
      </Stack>
    </Stack>
  );
};

SerialNumberDataTableFiltersResult.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
  onResetFilters: PropTypes.func,
  results: PropTypes.number,
};

export default SerialNumberDataTableFiltersResult;

function Block({ label, children, sx, ...other }) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}

Block.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  sx: PropTypes.object,
};
