import { DeleteForeverRounded } from '@mui/icons-material';
import { Button, Card, IconButton, Table, TableBody, TableContainer, Tooltip } from '@mui/material';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ConfirmDialog from 'src/components/confirm-dialog';
import Scrollbar from 'src/components/scrollbar/scrollbar';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  useTable,
} from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import { useDebounce } from 'src/hooks/use-debounce';
import OrderService from 'src/services/order-service';
import OrderDataTableFiltersResult from './order-data-table-filters-result';
import OrderDataTableRow from './order-data-table-row';
import OrderDataTableToolbar from './order-data-table-toolbar';

const defaultFilters = {
  search: '',
  status: [],
  startDate: null,
  endDate: null,
};

const OrderDataTable = () => {
  const table = useTable();
  const confirm = useBoolean();
  const [filters, setFilters] = useState(defaultFilters);
  const { enqueueSnackbar } = useSnackbar();

  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const debouncedFilters = useDebounce(filters);

  const getOrderList = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await OrderService.getList({
        page: page + 1,
        perPage,
        search: debouncedFilters.search,
        status: debouncedFilters.status,
      });
      setOrders(res.data);
      setTotal(res.meta.total);
      setIsLoading(false);
    } catch (error) {
      console.error();
      setIsLoading(false);
    }
  }, [page, perPage, debouncedFilters]);

  useEffect(() => {
    getOrderList();
  }, [getOrderList]);

  const canReset = !isEqual(defaultFilters, filters);

  const handleFilters = useCallback((name, value) => {
    setPage(0);
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleDeleteRow = useCallback(
    async (id) => {
      try {
        await OrderService.putOneToTrash(id);
        enqueueSnackbar('Delete Successfully!', { variant: 'success' });
        getOrderList();

        table.setSelected([]);
      } catch (error) {
        enqueueSnackbar('Failed to delete!', { variant: 'error' });
        console.error(error);
      }
    },
    [table, enqueueSnackbar, getOrderList]
  );

  const handleDeleteRows = useCallback(async () => {
    try {
      await OrderService.putManyToTrash(table.selected);
      enqueueSnackbar('Delete Successfully!', { variant: 'success' });
      getOrderList();

      table.setSelected([]);
    } catch (error) {
      enqueueSnackbar('Failed to delete!', { variant: 'error' });
      console.error(error);
    }
  }, [table, enqueueSnackbar, getOrderList]);

  const columns = useMemo(
    () => [
      { id: 'order_code', label: 'Order', width: 116 },
      { id: 'customer', label: 'Customer', width: 240 },
      { id: 'shipping', label: 'Shipping' },
      { id: 'product_count', label: 'Items', width: 120, align: 'center' },
      { id: 'total_price', label: 'Price', width: 140 },
      { id: 'status', label: 'Status', width: 110 },
      { id: 'created_at', label: 'Date', width: 140 },
      { id: '', width: 88 },
    ],
    []
  );

  return (
    <>
      <Card>
        <OrderDataTableToolbar filters={filters} onFilters={handleFilters} />

        {canReset && (
          <OrderDataTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={total}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            numSelected={table.selected.length}
            rowCount={orders?.length || 0}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                orders.map((row) => row.id)
              )
            }
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <DeleteForeverRounded />
                </IconButton>
              </Tooltip>
            }
          />

          <Scrollbar sx={{ maxHeight: '600px' }}>
            <Table size={table.dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={columns}
                rowCount={orders?.length || 0}
                numSelected={table.selected.length}
                onSort={table.onSort}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    orders.map((row) => row.id)
                  )
                }
              />
              <TableBody>
                {isLoading
                  ? [...Array(table.rowsPerPage)].map((i, index) => <TableSkeleton key={index} />)
                  : orders.map((row) => (
                      <OrderDataTableRow
                        key={`${row.slug}-${row.id}`}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        getOrderList={getOrderList}
                      />
                    ))}

                {total <= 0 && <TableNoData notFound={total <= 0} />}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={total}
          page={page}
          rowsPerPage={perPage}
          onPageChange={(event, page) => setPage(page)}
          onRowsPerPageChange={(event) => setPerPage(event.target.value)}
        />
      </Card>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
};

export default OrderDataTable;
