import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PROJECT_NAME } from 'src/config-global';
import Container from '@mui/material/Container';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { paths } from 'src/paths';
import { Button } from '@mui/material';
import RouterLink from 'src/components/router-link';
import { Add } from '@mui/icons-material';
import UserDataTable from 'src/sections/users/user-data-table/user-data-table';

const UserList = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard: User List - {PROJECT_NAME}</title>
      </Helmet>

      <Container maxWidth="lg">
        <CustomBreadcrumbs
          heading="User List"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Users', href: paths.dashboard.users.root },
            { name: 'List' },
          ]}
          action={
            <Button
              component={RouterLink}
              href={paths.dashboard.users.create}
              variant="contained"
              startIcon={<Add />}
            >
              New User
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <UserDataTable />
      </Container>
    </>
  );
};

export default UserList;
