import { useScroll } from "framer-motion";
import ScrollProgress from "src/components/scroll-progress";
import HomeHero from "./home-hero";
import { Box } from "@mui/material";
import HomeOffer from "./home-offer";
import HomeHero2 from "./home-hero2";

const HomeView = () => {
  const { scrollYProgress } = useScroll();

  return <>
    <ScrollProgress scrollYProgress={scrollYProgress} />

    <HomeHero />

    <Box
      sx={{
        overflow: 'hidden',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <HomeHero2 />
      <HomeOffer />
    </Box>

  </>
}

export default HomeView;
