import { Box, Pagination } from '@mui/material';
import { ProductItemSkeleton } from './product-skeleton';
import { paginationClasses } from '@mui/material/Pagination';
import ProductItem from './product-item';

const ProductList = ({ products, total, onPageChange, loading, ...other }) => {
  const renderSkeleton = (
    <>
      {[...Array(16)].map((_, index) => (
        <ProductItemSkeleton key={index} />
      ))}
    </>
  );

  const renderList = (
    <>
      {products.map((product) => (
        <ProductItem key={`${product.pid}-${product.id}`} product={product} />
      ))}
    </>
  );

  return (
    <>
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
        {...other}
      >
        {loading ? renderSkeleton : renderList}
      </Box>

      <Pagination
        count={Math.ceil(total / 20)}
        sx={{
          mt: 8,
          [`& .${paginationClasses.ul}`]: {
            justifyContent: 'center',
          },
        }}
        onChange={onPageChange}
      />
    </>
  );
};

export default ProductList;
