import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Stack,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { fileNameByUrl } from 'src/components/file-thumbnail';
import { Upload } from 'src/components/upload';
import CategoryService from 'src/services/category-service';
import ImageService from 'src/services/image-service';
import * as Yup from 'yup';

const CategoryQuickEditForm = ({
  currentCategory,
  open,
  onClose,
  onDeleteRow,
  refreshCategoryList,
}) => {
  const { id, name, images, description, parent_id, published_at, deleted_at } = currentCategory;
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [files, setFiles] = useState([]);
  const [defaultImages, setDefaultImages] = useState(images);

  const formik = useFormik({
    initialValues: {
      name: name || '',
      description: description || '',
      published_at: published_at || '',
      parent_id: parent_id,
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required(),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);

      try {
        let dataImages = !!defaultImages?.length ? [...defaultImages] : [];
        if (files.length > 0) {
          const formData = new FormData();
          files.forEach((file) => formData.append('images[]', file));

          const response = await ImageService.uploadMany(formData);
          dataImages = [...dataImages, ...response.data];
        }

        const data = { ...values, image_ids: dataImages.map((image) => image.id) };
        await CategoryService.update(id, data);
        enqueueSnackbar('Update Successfully!', { variant: 'success' });
        refreshCategoryList?.onTrue();
        onClose();
      } catch (err) {
        console.log(err);
        enqueueSnackbar('Failed to update!', { variant: 'error' });
      }

      setIsSubmitting(false);
    },
  });

  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      setFiles([
        ...files,
        ...acceptedFiles.map((newFile) =>
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          })
        ),
      ]);
    },
    [files]
  );

  const handleRemoveFile = (inputFile) => {
    const filesFiltered = files.filter((fileFiltered) => fileFiltered !== inputFile);
    const imagesFilted = defaultImages?.filter(
      (image) => fileNameByUrl(image.url) !== fileNameByUrl(inputFile)
    );
    setDefaultImages(imagesFilted);
    setFiles(filesFiltered);
  };

  const handleRemoveAllFiles = () => {
    setDefaultImages([]);
    setFiles([]);
  };

  const handlePublish = () => {
    formik.setFieldValue('published_at', moment());
    formik.handleSubmit();
  };

  return (
    <>
      <Dialog
        maxWidth={false}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { maxWidth: 720, py: 2 },
        }}
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <DialogTitle>Quick Update</DialogTitle>

          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <FormGroup>
                <TextField
                  error={!!(formik.touched.name && formik.errors.name)}
                  fullWidth
                  helperText={formik.touched.name && formik.errors.name}
                  label="Name"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.name}
                />
              </FormGroup>

              <FormGroup sx={{ my: 2 }}>
                <Card>
                  <CardContent>
                    <Upload
                      multiple
                      thumbnail={true}
                      files={[
                        ...files,
                        defaultImages ? [...defaultImages].map((image) => image.url) : [],
                      ]}
                      onDrop={handleDropMultiFile}
                      onRemove={handleRemoveFile}
                      onRemoveAll={handleRemoveAllFiles}
                    />
                  </CardContent>
                </Card>
              </FormGroup>
            </Box>
          </DialogContent>
          <DialogActions sx={{ px: 3 }}>
            <Stack direction="row" spacing={2} width="100%" justifyContent="space-between">
              {!deleted_at && (
                <Button variant="contained" color="error" onClick={onDeleteRow}>
                  Delete
                </Button>
              )}
              <Stack direction="row" spacing={2}>
                {!published_at && (
                  <Button type="submit" variant="contained" color="primary" onClick={handlePublish}>
                    Publish
                  </Button>
                )}

                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

CategoryQuickEditForm.propTypes = {
  currentCategory: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  refreshCategoryList: PropTypes.any,
};

export default CategoryQuickEditForm;
