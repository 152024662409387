import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'src/hooks/routes';
import { useResponsive } from 'src/hooks/use-responsive';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ImageService from 'src/services/image-service';
import BrandService from 'src/services/brand-service';
import { paths } from 'src/paths';
import Grid from '@mui/material/Unstable_Grid2';
import { Card, CardHeader, Stack, TextField, Typography } from '@mui/material';
import Editor from 'src/components/editor/editor';
import { Upload } from 'src/components/upload';
import { LoadingButton } from '@mui/lab';

const BrandEditForm = ({ brand }) => {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(brand?.images?.[0] || null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: brand?.name || '',
      description: brand?.description || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().max(255).required(),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        let data = { ...values, image_ids: brand?.images?.map((image) => image.id) || [] };

        if (file) {
          const formData = new FormData();
          formData.append('image', file);

          const response = await ImageService.uploadOne(formData);
          data.image_ids = [response.data.id];
        }

        if (brand) {
          await BrandService.update(brand.id, data);
          enqueueSnackbar('Update Successfully!', { variant: 'success' });
        } else {
          const res = await BrandService.create(data);

          router.push(`${paths.dashboard.brands.root}/${res.data.id}`);
          enqueueSnackbar('Create Successfully!', { variant: 'success' });
        }
      } catch (err) {
        console.log(err);
        enqueueSnackbar(`Failed to ${!brand ? 'create' : 'update'}!`, { variant: 'error' });
      }

      setIsSubmitting(false);
    },
  });

  const handleDropSingleFile = useCallback((acceptedFiles) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFile(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    }
  }, []);

  const getImage = useCallback(() => {
    setImage(brand?.images?.[0] || null);
  }, [brand]);

  useEffect(() => {
    getImage();
  }, [getImage]);

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {mdUp && (
            <Grid md={4}>
              <Typography variant="h6" sx={{ mb: 0.5 }}>
                Details
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Title, short description, image...
              </Typography>
            </Grid>
          )}

          <Grid xs={12} md={8}>
            <Card>
              {!mdUp && <CardHeader title="Details" />}

              <Stack spacing={3} sx={{ p: 3 }}>
                <Typography variant="subtitle2">Name</Typography>
                <TextField
                  error={!!(formik.touched.name && formik.errors.name)}
                  fullWidth
                  helperText={formik.touched.name && formik.errors.name}
                  label="Name"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.name}
                />

                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">Description</Typography>
                  <Editor
                    id="brand-description"
                    simple
                    error={!!(formik.touched.description && formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                    name="description"
                    onChange={(value) => formik.setFieldValue('description', value)}
                    value={formik.values.description}
                  />
                </Stack>

                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">Images</Typography>
                  <Upload
                    file={file || image?.url}
                    onDrop={handleDropSingleFile}
                    onDelete={() => {
                      setFile(null);
                      setImage(null);
                    }}
                  />
                </Stack>
              </Stack>
            </Card>
          </Grid>

          {mdUp && <Grid md={4} />}
          <Grid xs={12} md={8} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              {!brand ? 'Create Brand' : 'Save Changes'}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

BrandEditForm.propTypes = {
  brand: PropTypes.object,
};

export default BrandEditForm;
