import { Check, Close, DeleteForeverOutlined, EditRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Link,
  ListItemText,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useOutletContext } from 'react-router-dom';
import ConfirmDialog from 'src/components/confirm-dialog';
import Label from 'src/components/label/label';
import RouterLink from 'src/components/router-link';
import { useBoolean } from 'src/hooks/use-boolean';
import { paths } from 'src/paths';
import SerialNumberService from 'src/services/serial-number-service';
import * as Yup from 'yup';

const SerialNumberDataTableRow = ({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  getSerialNumberList,
  getProduct,
}) => {
  const { id, serial_number, product, status, status_color, status_name, note, updated_at } = row;

  const { serialNumberStatus } = useOutletContext();
  const confirm = useBoolean();
  const quickEdit = useBoolean();

  const formik = useFormik({
    initialValues: {
      serial_number: serial_number || '',
      status: status || '',
      note: note || '',
      product_id: product?.id,
    },
    validationSchema: Yup.object({
      serial_number: Yup.string().max(255).required(),
      status: Yup.string().max(255).required(),
    }),
    onSubmit: async (values) => {
      try {
        await SerialNumberService.update(id, values);
        quickEdit.onFalse();
        getSerialNumberList?.();
        getProduct?.();
      } catch (error) {
        enqueueSnackbar(`Failed to update!`, { variant: 'error' });
      }
    },
  });

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell>
          {!quickEdit.value ? (
            <ListItemText
              disableTypography
              primary={
                product ? (
                  <Link
                    component={RouterLink}
                    href={`${paths.dashboard.products.root}/${product?.id}`}
                    noWrap
                    color="inherit"
                    variant="subtitle2"
                    sx={{ cursor: 'pointer' }}
                  >
                    {serial_number}
                  </Link>
                ) : (
                  serial_number
                )
              }
              secondary={
                product && (
                  <Box component="div" sx={{ typography: 'body2', color: 'text.disabled' }}>
                    {product.pid}
                  </Box>
                )
              }
            />
          ) : (
            <TextField
              error={!!(formik.touched.serial_number && formik.errors.serial_number)}
              fullWidth
              helperText={formik.touched.serial_number && formik.errors.serial_number}
              name="serial_number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.serial_number}
            />
          )}
        </TableCell>

        <TableCell>
          {!quickEdit.value ? (
            <Label variant="soft" color={status_color}>
              {status_name}
            </Label>
          ) : (
            <Select
              error={!!(formik.touched.status && formik.errors.status)}
              fullWidth
              name="status"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.status}
            >
              {serialNumberStatus?.labels
                .filter(statusOption => {
                  if (status === 'ok' || status === 'faulty' || status === 'sold') {
                    return statusOption.value !== 'pending' && statusOption.value !== 'untest'
                  }
                  return (status !== 'ok' && (statusOption.value !== 'pending' || statusOption.value !== 'untest'))
                })
                .map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Label
                      variant="soft"
                      color={
                        serialNumberStatus?.colors.find((color) => color.value === option.value)
                          ?.label
                      }
                    >
                      {option.label}
                    </Label>
                  </MenuItem>
                ))}
            </Select>
          )}
        </TableCell>
        <TableCell>
          {!quickEdit.value ? (
            note
          ) : (
            <TextField
              error={!!(formik.touched.note && formik.errors.note)}
              fullWidth
              helperText={formik.touched.note && formik.errors.note}
              name="note"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.note}
            />
          )}
        </TableCell>

        <TableCell>
          <ListItemText
            primary={format(new Date(updated_at), 'dd MMM yyyy')}
            secondary={format(new Date(updated_at), 'p')}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {!quickEdit.value ? (
            <>
              <Tooltip title="Quick Edit" placement="top" arrow>
                <IconButton
                  size="small"
                  color={quickEdit.value ? 'inherit' : 'default'}
                  onClick={quickEdit.onTrue}
                >
                  <EditRounded size="small" />
                </IconButton>
              </Tooltip>

              <IconButton size="small" color="error" onClick={confirm.onTrue}>
                <DeleteForeverOutlined size="small" />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                color={quickEdit.value ? 'inherit' : 'default'}
                onClick={formik.handleSubmit}
                size="small"
              >
                <Check size="small" />
              </IconButton>

              <IconButton color="error" size="small" onClick={quickEdit.onFalse}>
                <Close size="small" />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
};

export default SerialNumberDataTableRow;
