import {
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import Iconify from 'src/components/icons/iconify';
import Label from 'src/components/label/label';

const SerialNumberDataTableToolbar = ({ filters, onFilters }) => {
  const { serialNumberStatus } = useOutletContext();

  const handleFilterSerialNumber = useCallback(
    (event) => {
      onFilters('serialNumber', event.target.value);
    },
    [onFilters]
  );

  const handleFilterStatus = useCallback(
    (event) => {
      onFilters(
        'status',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterStartDate = useCallback(
    (newValue) => {
      onFilters('startDate', newValue);
    },
    [onFilters]
  );

  const handleFilterEndDate = useCallback(
    (newValue) => {
      onFilters('endDate', newValue);
    },
    [onFilters]
  );

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1, p: 2.5 }}>
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>Status</InputLabel>

          <Select
            multiple
            value={filters.status}
            onChange={handleFilterStatus}
            input={<OutlinedInput label="Status" />}
            renderValue={(selected) => selected.map((value) => value).join(', ')}
            sx={{ textTransform: 'capitalize' }}
          >
            {serialNumberStatus?.labels.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filters.status?.includes(option.value)}
                />

                <Label
                  variant="soft"
                  color={
                    serialNumberStatus?.colors.find((color) => color.value === option.value)?.label
                  }
                >
                  {option.label}
                </Label>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <DatePicker
          label="Start date"
          value={filters.startDate}
          onChange={handleFilterStartDate}
          slotProps={{
            textField: {
              fullWidth: true,
            },
          }}
          sx={{
            maxWidth: { md: 200 },
          }}
        />

        <DatePicker
          label="End date"
          value={filters.endDate}
          onChange={handleFilterEndDate}
          slotProps={{ textField: { fullWidth: true } }}
          sx={{
            maxWidth: { md: 200 },
          }}
        />

        <TextField
          fullWidth
          value={filters.name}
          onChange={handleFilterSerialNumber}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </>
  );
};

export default SerialNumberDataTableToolbar;
